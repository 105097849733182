import { type TagColor } from 'components/basic/Tag';
import { useState } from 'react';
import api from 'services/index';
import JobDetailItemStructure from 'structures/jobDetailItem';
import { firstLetterTransform } from 'utils/utils';

const { jobsApi } = api;

export interface JobStatus {
  type: string;
  color: TagColor;
  job_title: string;
}

const useJobInfo = () => {
  const [jobInfo, setJobInfo] = useState<JobDetailItemStructure>();
  const [jobStatus, setJobStatus] = useState<JobStatus>();
  const [isLoading, setIsLoading] = useState(false);

  const getJobInfo = async (id: number) => {
    setIsLoading(true);
    const result = await jobsApi.getJobInfo({
      id,
    });
    const { code, message, data } = result || {};
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({ content: message });
      return;
    }

    setJobInfo(
      new JobDetailItemStructure({
        apply_info: data.apply_info, // 是否应募工作
        is_favorite: data.is_favorite,
        is_valid: data.is_valid, // 工作是否有效 valid：有效
        language: data.language,
        id: data.id,
        thumbnail_path: `${process.env.REACT_APP_FILE_URL}${data.thumbnail_path}`,
        title: data.title,
        valid_from: data.valid_from, // 仕事公開の時間
        valid_to: data.valid_to, // 仕事締切の時間
        created_at: data.created_at,
        baseInfo: {
          professions: data.professions,
          work_location: data.work_location,
          salary_types: data.salary_types,
          working_hour_detail: data.working_hour_detail,
          employment_statuses: data.employment_statuses,
          visas: data.visas,
          valid_to: data.valid_to, // 仕事締切の時間
        },
        recruitmentInfo: {
          work_detail: data.work_detail,
          salary_detail: data.salary_detail,
          working_period_detail: data.working_period_detail,
          station_detail: data.station_detail,
          experience_detail: data.experience_detail,
        },
      }),
    );
    setIsLoading(false);
    setJobStatus(undefined);
    if (
      ['pending', 'contacting', 'scheduled'].includes(
        data.apply_info.apply_status,
      )
    ) {
      setJobStatus({
        type: firstLetterTransform(data.apply_info.apply_status, 'upper'),
        color: 'orange',
        job_title: data.title,
      });
    } else if (
      ['registered', 'adopted'].includes(data.apply_info.apply_status)
    ) {
      setJobStatus({
        type: firstLetterTransform(data.apply_info.apply_status, 'upper'),
        color: 'green',
        job_title: data.title,
      });
    } else if (
      ['rejected', 'declined'].includes(data.apply_info.apply_status)
    ) {
      setJobStatus({
        type: firstLetterTransform(data.apply_info.apply_status, 'upper'),
        color: 'pink',
        job_title: data.title,
      });
    }
  };
  return [{ isLoading, jobInfo, jobStatus }, { getJobInfo }] as const;
};

export default useJobInfo;
