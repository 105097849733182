import axiosInstance from 'services/fetch';
import { type IHttpResponse } from 'types/common';
import { type AnyObj } from 'types/index';
import { addPhoneNumberPrefix } from 'utils/help';

interface ISendCodeArgs {
  type: 'phoneNumber' | 'email';
  value: string;
}
interface ICheckCodeArgs {
  type: 'phoneNumber' | 'email';
  value: string;
  code: string;
}
export default {
  getUserData: () => {
    return axiosInstance<any, { data: AnyObj } & IHttpResponse, any>({
      url: `/users/getUserByAccessToken`,
      method: 'get',
      params: {},
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  sendCode: (args: ISendCodeArgs) => {
    args = addPhoneNumberPrefix(args) as ISendCodeArgs;
    return axiosInstance<any, IHttpResponse, ISendCodeArgs>({
      url: `/auth/verify/sendCode`,
      method: 'post',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  checkCode: (args: ICheckCodeArgs) => {
    args = addPhoneNumberPrefix(args) as ICheckCodeArgs;
    return axiosInstance<
      any,
      { data: { match: boolean } } & IHttpResponse,
      ICheckCodeArgs
    >({
      url: '/auth/verify/checkCode',
      method: 'post',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
