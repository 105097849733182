export interface IFaqDetailItemInfo {
  id: number;
  language: string;
  question?: string;
  answer?: string;
}

export interface IFaqDetailData {
  id: number;
  language: string;
  question?: string;
  answer?: string;
}

class FaqDetailItemStructure {
  info: IFaqDetailItemInfo[] = [
    {
      question: '',
      answer: '',
      id: 0,
      language: '',
    },
  ];

  constructor(faqDetailDate: IFaqDetailData) {
    this.initialize(faqDetailDate);
  }

  initialize(faqDetailDate: IFaqDetailData) {
    this.info[0].question = faqDetailDate.question;
    this.info[0].answer = faqDetailDate.answer;
    this.info[0].id = faqDetailDate.id;
    this.info[0].language = faqDetailDate.language;
  }
}
export default FaqDetailItemStructure;
