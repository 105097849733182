import loginUser from 'data/users';
import { type IHttpResponse } from 'types/common';

import axiosInstance from './fetch';

export interface IUserInquiresArgs {
  user_id?: number | string; // optional
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber?: number; // optional
  message: string;
}

export default {
  userInquires: (args: IUserInquiresArgs) => {
    loginUser?.id && (args.user_id = loginUser.id);
    return axiosInstance<any, IHttpResponse, IUserInquiresArgs>({
      url: '/inquires/inquires',
      method: 'post',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
