import classNames from 'classnames';
import React, { useEffect } from 'react';

// import { useTranslation } from 'react-i18next';

export interface ErrorPageProps {
  className?: string;
  errorType?: 404;
}
const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const { className, errorType = 404 } = props;

  // const { t } = useTranslation();

  useEffect(() => {
    console.log('error page');
  }, []);

  return <div className={classNames('error-page', className)}>{errorType}</div>;
};
export default ErrorPage;
