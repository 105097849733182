import _loginUser from 'data/users';
import { useEffect, useRef, useState } from 'react';
import LoginUser from 'structures/user';

export const useLoginUser = () => {
  const [loginUser, setLoginUser] = useState(_loginUser);

  const loginuserchangeHandle = useRef(() => {});
  useEffect(() => {
    loginuserchangeHandle.current = () => {
      setLoginUser(new LoginUser());
    };
  });

  useEffect(() => {
    window.addEventListener('loginuserchange', () => {
      loginuserchangeHandle.current?.();
    });
  }, []);

  return [loginUser];
};
