import IconClock from 'assets/images/icon-clock.svg';
import IconEmployment from 'assets/images/icon-employment.svg';
import IconLocation from 'assets/images/icon-location.svg';
import IconMoney from 'assets/images/icon-money.svg';
import IconUser from 'assets/images/icon-user.svg';
import { type IResponseJobItem } from 'services/jobs';

interface IJobItemData {
  collected: boolean;
  expired: boolean;
  finished: boolean;
  applyStatus?:
    | 'pending'
    | 'contacting'
    | 'scheduled'
    | 'registered'
    | 'adopted'
    | 'rejected'
    | 'declined';
  image?: string;
  title: string;
  id: number;
  workLocation: string;
  occupation: string;
  salaryGive: string;
  workingHours: string;
  employmentStatus: string;
}
export const getJobItemData = (jobData: IResponseJobItem) => {
  return {
    collected: !!jobData.is_favorite,
    expired: !jobData.valid_to
      ? false
      : new Date(jobData.valid_to).getTime() <= Date.now(),
    finished: jobData.is_valid !== 'valid',
    applyStatus: jobData.apply_info?.apply_status,
    id: jobData.id,
    image: jobData.thumbnail_path
      ? `${process.env.REACT_APP_FILE_URL}${jobData.thumbnail_path}`
      : undefined,
    title: jobData.title,
    workLocation: `${
      jobData.prefectures?.[0]?.prefecture_name
        ? `${jobData.prefectures?.[0]?.prefecture_name} `
        : ''
    }${jobData.work_location}`,
    occupation: jobData.professions.map((o) => o.profession_name).join(','),
    salaryGive: jobData.salary_detail,
    workingHours: jobData.working_hour_detail,
    employmentStatus: jobData.employment_statuses
      .map((e) => e.employment_status_name)
      .join(','),
  };
};

export interface IJobItemInfo {
  key:
    | 'workLocation'
    | 'occupation'
    | 'salaryGive'
    | 'workingHours'
    | 'employmentStatus';
  text: string;
  value: string;
  icon: string;
}

class JobItemStructure {
  collected: boolean = false;
  expired: boolean = false;
  finished: boolean = false;
  applyStatus?:
    | 'pending'
    | 'contacting'
    | 'scheduled'
    | 'registered'
    | 'adopted'
    | 'rejected'
    | 'declined';

  id: number = 0;
  image: string = '';
  title: string = '';
  infos: IJobItemInfo[] = [
    {
      key: 'workLocation',
      text: 'workLocationText',
      value: '',
      icon: IconLocation,
    },
    {
      key: 'occupation',
      text: 'occupationText',
      value: '',
      icon: IconUser,
    },
    {
      key: 'salaryGive',
      text: 'salaryGiveText',
      value: '',
      icon: IconMoney,
    },
    {
      key: 'workingHours',
      text: 'workingHoursText',
      value: '',
      icon: IconClock,
    },
    {
      key: 'employmentStatus',
      text: 'employmentStatusText',
      value: '',
      icon: IconEmployment,
    },
  ];

  constructor(jobItemData: IJobItemData) {
    this.collected = !!jobItemData.collected;
    this.expired = !!jobItemData.expired;
    this.finished = !!jobItemData.finished;
    this.applyStatus = jobItemData.applyStatus;
    this.id = jobItemData.id;
    this.image = jobItemData.image || '';
    this.title = jobItemData.title || '';
    this.infos.forEach((info) => {
      info.value =
        (jobItemData[info.key as keyof typeof jobItemData] as string) || '';
    });
  }

  onClick() {}
}

export default JobItemStructure;
