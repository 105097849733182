import IconArrowLeft from 'assets/images/icon-arrow-left.svg';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  title?: string;
  className?: string;
  goBack?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title = '',
  className,
  goBack = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        'header-basic wd-full bg-cyan flex-center',
        className,
      )}
    >
      <div className="text-white font-xl font-weight-700">
        <div className="header-basic wd-full bg-cyan flex-center">
          {goBack && (
            <span
              className="faq-detail-back cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconArrowLeft />
            </span>
          )}

          <div className="text-white font-xl font-weight-700">
            <span>{t(title)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
