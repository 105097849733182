import { Popover, Space } from 'antd-mobile';
import IconLogin from 'assets/images/icon-login.svg';
import IconLogoutBlack from 'assets/images/icon-logout-black.svg';
import IconMenu from 'assets/images/icon-menu.svg';
import WowLogo from 'assets/images/wow-logo.svg';
import classNames from 'classnames';
import Avatars from 'components/basic/Avatars';
import Button from 'components/basic/Button';
import { useCustomNavigate, useGlobal, useMediaQuery } from 'hooks/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { firebaseSignOut } from 'services/third-party/firebase';
// import _loginUser from 'data/users';
// import LoginUser from 'structures/user';
import { getRunEnviroment, installAppLink } from 'utils/help';

import Menu from './Menu';
import Translation from './Translation';

export interface NavigatorProps {
  className?: string;
}
const Navigator: React.FC<NavigatorProps> = (props) => {
  const { className } = props;

  const navigate = useCustomNavigate();

  const { setPopup, loginUser } = useGlobal();
  const isMobile = useMediaQuery();

  const { t } = useTranslation();

  useEffect(() => {
    console.log('loginUser change');
  }, [loginUser]);

  return (
    <div className={classNames('navigator-compound', className)}>
      <div className="bg-white flex-align-center flex-justify-between pt-10 pr-5-x pb-10 pl-5-x overflow-hidden">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate('/index');
          }}
        >
          <span className="vertical-align logo">
            <WowLogo />
          </span>
          <span
            className={`${
              getRunEnviroment() !== 'PC' || isMobile
                ? 'font-sm display-block'
                : 'font-md ml-8-x'
            } navigator-left-text text-cyan vertical-align`}
          >
            {t('public.jobWOW')}
          </span>
        </div>
        {isMobile ? (
          <div
            className="navigator-menu pt-10"
            onClick={() => {
              setPopup?.({
                show: true,
                title: t('public.menu'),
                renderComponent: <Menu />,
              });
            }}
          >
            <IconMenu />
          </div>
        ) : (
          <div className="navigator-menu-pc navigator-menu-btn">
            <Space>
              <Translation flag={0} />

              {getRunEnviroment() !== 'PC' && (
                <a target="_blank" href={installAppLink()} rel="noreferrer">
                  <Button
                    background="cyan"
                    className="menu-cyan ht-full pl-14 pr-14 font-sm ml-10"
                    size="large"
                    shape="rectangular"
                  >
                    <span>{t('button.installApplication')}</span>
                  </Button>
                </a>
              )}
              {!loginUser?.isLogin() ? (
                <Button
                  background="primary"
                  className="text-orange-dark ht-full pl-14 pr-14 font-sm ml-10"
                  shape="rectangular"
                  size="large"
                  fill="outline"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <IconLogin />
                  <span>{t('button.login')}</span>
                </Button>
              ) : (
                <Popover.Menu
                  actions={[
                    {
                      key: '1',
                      icon: <IconLogoutBlack />,
                      text: <span>{t('button.logout')}</span>,
                    },
                  ]}
                  onAction={(node) => {
                    firebaseSignOut()
                      .then(() => {
                        loginUser?.clearUserInfo();
                        window.location.href = `${window.location.origin}${window.location.search}`;
                      })
                      .catch(() => {});
                  }}
                  placement="bottom-start"
                  trigger="click"
                >
                  <Avatars
                    avatarUrl={loginUser?.avatar}
                    avatarClass="menu-avatar cursor-pointer"
                    className="font-sm  pl-2-x cursor-pointer"
                    name={loginUser?.name ?? ''}
                  />
                </Popover.Menu>
              )}
            </Space>
          </div>
        )}
      </div>
    </div>
  );
};
export default Navigator;
