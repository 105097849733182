import IconFilter from 'assets/images/icon-filter.svg';
import classNames from 'classnames';
import Button from 'components/basic/Button';
import JobList from 'components/compound/JobList';
import SearchFilter from 'components/compound/SearchFilter';
import { useGlobal, useMediaQuery } from 'hooks/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isInApp } from 'utils/help';

export interface IndexPageProps {
  className?: string;
}
const IndexPage: React.FC<IndexPageProps> = () => {
  const { t } = useTranslation();

  const ctx = useGlobal();
  const isMobile = useMediaQuery();

  return (
    <div
      className={classNames(
        'index-page flex-column flex-align-center',
        { 'is-mobile': isMobile },
        { 'is-pc': !isMobile },
      )}
    >
      {isMobile ? (
        isInApp() ? (
          <></>
        ) : (
          <Button
            className="search-filter-button mt-10"
            size="small"
            background="primary"
            onClick={() => {
              ctx.setPopup?.({
                show: true,
                title: t('searchFilter.searchFilterTitle'),
                renderComponent: <SearchFilter />,
              });
            }}
          >
            <IconFilter />
            <span>{t('searchFilter.searchFilterTitle')}</span>
          </Button>
        )
      ) : (
        <SearchFilter />
      )}
      <JobList />
    </div>
  );
};
export default IndexPage;
