import RichTextRender from 'components/basic/RichTextRender';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TermService: React.FC = () => {
  const { t } = useTranslation();

  const content1 = t('terms.content1');
  const content2 = t('terms.content2');
  const content3 = t('terms.content3');
  const content4 = t('terms.content4');
  const content5 = t('terms.content5');
  const content6 = t('terms.content6');
  const content7 = t('terms.content7');
  const content8 = t('terms.content8');
  const content9 = t('terms.content9');
  const content10 = t('terms.content10');
  const content11 = t('terms.content11');
  const content12 = t('terms.content12');
  const content13 = t('terms.content13');
  const content14 = t('terms.content14');
  const content15 = t('terms.content15');
  const content16 = t('terms.content16');
  const content17 = t('terms.content17');
  const content18 = t('terms.content18');
  return (
    <div>
      <div className="p-5-x terms-content page-content-width">
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content1}
        />
        <div className="font-weight-700  font-md mt-5-x mb-5 letter-spacing-xxxl">
          <div className="terms-left-icon"></div>
          <div className="terms-right-text">{content2}</div>
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content3}
        />
        <div className="font-weight-700  font-md letter-spacing-xxl mb-5 mt-5-x">
          <div className="terms-left-icon"></div>
          <div className="terms-right-text">{content4}</div>
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content5}
        />
        <div className="font-weight-700  font-md mt-5-x mb-5 letter-spacing-xxxl">
          <div className="terms-left-icon"></div>
          <div className="terms-right-text">{content6}</div>
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl mb-5"
          content={content7}
        />
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl mb-5"
          content={content8}
        />
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content9}
        />
        <div className="font-weight-700  font-md mt-5-x mb-5 letter-spacing-xxxl">
          <div className="terms-left-icon"></div>
          <div className="terms-right-text">{content10}</div>
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content11}
        />
        <div className="font-weight-700  font-md mt-5-x mb-5 letter-spacing-xxxl">
          <div className="terms-left-icon"></div>
          <div className="terms-right-text">{content12}</div>
        </div>

        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content13}
        />
        <div className="text-orange-dark font-weight-700 letter-spacing-xxxl  font-md mt-5-x mb-10">
          {content14}
        </div>
        <div className="font-weight-700  font-md letter-spacing-xxxl mb-5 mt-10">
          {content15}
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content16}
        />
        <div className="font-weight-700  font-md letter-spacing-xxxl mb-5 mt-10">
          {content17}
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl"
          content={content18}
        />
      </div>
    </div>
  );
};

export default TermService;
