import JaImg from 'assets/images/japan.svg';

export interface ILanguageItemInfo {
  key: string;
  icon: string;
  name: string;
}

interface ILanguageItemInfosData {
  key?: string;
  icon?: string;
  name?: string;
}

class LanguageItemStructure {
  info: ILanguageItemInfo[] = [{ key: 'ja', icon: JaImg, name: '日本語' }];

  constructor(languageItemInfos: ILanguageItemInfosData) {
    this.initialize(languageItemInfos);
  }

  initialize(languageItemInfo: ILanguageItemInfosData) {
    this.info.forEach((info) => {
      info.key = languageItemInfo.key ?? 'ja';
      info.name = languageItemInfo.name ?? '';
      info.icon = languageItemInfo.icon ?? '';
    });
  }
}

export default LanguageItemStructure;
