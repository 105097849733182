import AppliedJobsPage from 'pages/AppliedJobs';
import CollectedJobsPage from 'pages/CollectedJobs';
import ErrorPage from 'pages/Error';
import Faq from 'pages/Faq';
import FaqDetail from 'pages/FaqDetail';
import IndexPage from 'pages/Index';
import Inquiry from 'pages/Inquiry';
import JobDetail from 'pages/JobDetail';
import { LoginNormalPage, LoginResetPasswordPage } from 'pages/Login';
import LogonPage from 'pages/Logon';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ProfileConfirmPage from 'pages/ProfileConfirm';
import ProfileFillPage from 'pages/ProfileFill';
import TermService from 'pages/TermService';
import ThirdPartyRedirectPage from 'pages/ThirdPartyRedirect';
import React from 'react';
import { Navigate } from 'react-router-dom';

import App from '../App';

const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={{ pathname: '/index', search: window.location.search }}
          />
        ),
        handle: {
          footer: true,
          header: true,
        },
      },
      {
        name: 'IndexPage',
        path: '/index',
        element: <IndexPage />,
        handle: {
          footer: true,
          header: true,
        },
      },
      {
        path: '/collected-jobs',
        element: <CollectedJobsPage />,
      },
      {
        path: '/applied-jobs',
        element: <AppliedJobsPage />,
      },
      {
        name: 'JobDetail',
        path: '/job/:jobId',
        element: <JobDetail />,
        handle: {
          footer: true,
          header: true,
        },
      },
      {
        path: '/inquiry',
        element: <Inquiry />,
        handle: {
          footer: true,
          // header: true,
          title: true,
          title_content: 'other.contact',
        },
      },
      {
        path: '/faq',
        element: <Faq />,
        handle: {
          footer: true,
          title: true,
          title_content: 'other.faq',
        },
      },
      {
        path: '/faq/:faqId',
        element: <FaqDetail />,
        handle: {
          footer: true,
          title: true,
          goBack: true,
          title_content: 'other.faq',
        },
      },
      {
        path: '/policy',
        element: <PrivacyPolicy />,
        handle: {
          footer: true,
          title: true,
          title_content: 'other.privacyPolicy',
        },
      },
      {
        path: '/terms',
        element: <TermService />,
        handle: {
          footer: true,
          title: true,
          title_content: 'other.termsService',
        },
      },
      {
        path: '/logon',
        element: <LogonPage />,
        handle: {
          footer: true,
          header: true,
          title: true,
          title_content: 'button.register',
        },
      },
      {
        path: '/login',
        element: <LoginNormalPage />,
        handle: {
          footer: true,
          header: true,
          title: true,
          title_content: 'button.login',
        },
      },
      {
        path: '/profile-fill',
        element: <ProfileFillPage />,
        handle: {
          // footer: true,
          // header: true,
        },
      },
      {
        path: '/profile-confirm',
        element: <ProfileConfirmPage />,
        handle: {
          // footer: true,
          // header: true,
        },
      },
      {
        path: '/login/reset-password',
        element: <LoginResetPasswordPage />,
        handle: {
          footer: true,
          header: true,
          title: true,
          title_content: 'button.resetPassword',
        },
      },
      {
        path: '/third-party-redirect',
        element: <ThirdPartyRedirectPage />,
      },
    ],
  },
  {
    path: '/*',
    element: <ErrorPage />,
  },
];
export default routes;
