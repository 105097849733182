import classNames from 'classnames';
import Footer from 'components/basic/Footer';
import Header from 'components/basic/Header';
import Navigator from 'components/compound/Nagivator';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useMatches,
  useOutlet,
} from 'react-router-dom';
import { Offscreen } from 'react-stillness-component';

interface RouterType {
  footer?: boolean;
  title?: boolean;
  header?: boolean;
  title_content?: string;
  goBack?: boolean;
}

const CACHED_ROUTES = [
  '/',
  '/index',
  '/index/',
  '/collected-jobs',
  '/collected-jobs/',
  '/applied-jobs',
  '/applied-jobs/',
  '/profile-fill',
];

export const Layout: React.FC = () => {
  const location = useLocation();
  const outlet = useOutlet();
  const matches = useMatches();

  const [outlets, setOutlets] = useState<
    Array<{ key: string; pathname: string; outlet: React.ReactElement | null }>
  >([]);
  useEffect(() => {
    const result = outlets.some((o) => o.pathname === location.pathname);
    if (result) return;
    setOutlets([
      ...outlets,
      {
        key: location.key,
        pathname: location.pathname,
        outlet,
      },
    ]);
  }, [location.pathname]);

  const handle = matches[1]?.handle || {};
  const { header, title, footer } = handle as RouterType;

  return (
    <div
      className={classNames('Layout flex-column', {
        'pb-13-x': !window.wowApp.isInApp() && footer,
      })}
    >
      {!window.wowApp.isInApp() && header && <Navigator />}
      {!window.wowApp.isInApp() && title && (
        <Header
          title={(matches[1].handle as RouterType).title_content ?? ''}
          goBack={(matches[1].handle as RouterType).goBack ?? false}
        />
      )}
      {outlets.map((o) => {
        if (!CACHED_ROUTES.includes(o.pathname))
          return (
            <Routes key={o.key}>
              <Route path={o.pathname} element={o.outlet} />
            </Routes>
          );
        else
          return (
            <Offscreen
              key={o.key}
              visible={location.pathname === o.pathname}
              scrollReset={true}
            >
              {o.outlet}
            </Offscreen>
          );
      })}
      {!window.wowApp.isInApp() && footer && <Footer />}
    </div>
  );
};
