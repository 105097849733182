import IconLogin from 'assets/images/icon-login.svg';
import IconLogout from 'assets/images/icon-logout.svg';
import Avatars from 'components/basic/Avatars';
import Button from 'components/basic/Button';
import Translation from 'components/compound/Translation';
import { useCustomNavigate, useGlobal } from 'hooks/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { firebaseSignOut } from 'services/third-party/firebase';
import { getRunEnviroment, installAppLink } from 'utils/help';

const Menu: React.FC = () => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const { setPopup, loginUser } = useGlobal();

  return (
    // mt-16-x
    <div className="menu-compound pt-16-x">
      {loginUser?.isLogin() && (
        <div className="menu-user-info p-4-x mb-10-x">
          <Avatars
            avatarUrl={loginUser?.avatar}
            name={loginUser?.name ?? ''}
            className="text-white pl-5-x"
          />
        </div>
      )}

      <Translation flag={0} />
      {getRunEnviroment() !== 'PC' && (
        <a target="_blank" href={installAppLink()} rel="noreferrer">
          <Button
            background="default"
            className="mt-5-x wd-full"
            shape="rectangular"
          >
            <span>{t('button.installApplication')}</span>
          </Button>
        </a>
      )}

      {!loginUser?.isLogin() ? (
        <div>
          {/* <Button
            background="primary"
            className="mt-5-x  wd-full"
            shape="rectangular"
            onClick={() => {
              ctx.setPopup?.({
                show: false,
              });
              navigate('/logon');
            }}
          >
            <IconUserWhite />
            <span>{t('button.register')}</span>
          </Button> */}

          <Button
            background="primary"
            className="wd-full text-orange-dark mt-5-x"
            shape="rectangular"
            fill="outline"
            onClick={() => {
              setPopup?.({
                show: false,
              });
              navigate('/login');
            }}
          >
            <IconLogin />
            <span>{t('button.login')}</span>
          </Button>
        </div>
      ) : (
        <Button
          background="primary"
          className="wd-full mt-5-x text-orange-dark"
          shape="rectangular"
          fill="outline"
          onClick={() => {
            firebaseSignOut()
              .then(() => {
                loginUser?.clearUserInfo();
                window.location.href = `${window.location.origin}${window.location.search}`;
              })
              .catch(() => {});
          }}
        >
          <IconLogout />
          <span>{t('button.logout')}</span>
        </Button>
      )}
    </div>
  );
};
export default Menu;
