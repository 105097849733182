import SearchFilter from 'components/compound/SearchFilter';
import { useGlobal, useLanguage } from 'hooks/hooks';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type NormalObj } from 'types/index';
import { getLocalLanguage, isInApp } from 'utils/help';

type TUWNCCommand = (params: NormalObj) => void;
export class UWNC {
  commands: NormalObj<TUWNCCommand> = {};

  setCommands(commands: NormalObj<TUWNCCommand>) {
    this.commands = commands;
  }

  getRandomCallbackName() {
    return `exec_once_callback_${Date.now()}${Math.floor(100 * Math.random())}`;
  }

  callbackRegister(callbackName: string, callback: TUWNCCommand) {
    this.commands[callbackName] = callback;
  }

  callNative(
    commandName: string,
    commandParams: NormalObj,
    callback?: TUWNCCommand,
  ) {
    console.log(commandName, JSON.stringify(commandParams), 'callNative');
    if (!isInApp()) return;
    let callbackName;
    if (callback) {
      callbackName = this.getRandomCallbackName();
      this.callbackRegister(callbackName, callback);
    }
    window.flutter_inappwebview?.callHandler?.(
      'webMessage',
      JSON.stringify({
        commandName,
        commandParams,
        callbackName,
      }),
    );
  }

  callWeb({
    commandName,
    commandParams,
    callbackName,
  }: {
    commandName: string;
    commandParams: string;
    callbackName: string;
  }) {
    console.log(commandName, commandParams, 'callWeb');
    if (!isInApp()) return;
    this.dispatcher(commandName, JSON.parse(commandParams || '{}'));
  }

  dispatcher(commandName: string, commandParams: NormalObj) {
    this.commands[commandName]?.(commandParams);
  }
}

const UWNCPage: React.FC = () => {
  const { t } = useTranslation();
  const ctx = useGlobal();
  const { changeLanguage } = useLanguage();

  const commands = useMemo(() => {
    return {
      WebToggleJobSearchFilter: (params: NormalObj) => {
        const { show } = params;
        ctx.setPopup?.({
          show,
          title: t('searchFilter.searchFilterTitle'),
          renderComponent: <SearchFilter />,
          hideHead: true,
        });
      },
      WebLanguageUse: (params: NormalObj) => {
        const { language } = params;
        changeLanguage(getLocalLanguage(language));
      },
      WebActiveBottomTab: (params: NormalObj) => {
        if (!isInApp()) return;
        window.dispatchEvent(
          new CustomEvent('bottomtabactive', {
            detail: params,
          }),
        );
      },
    };
  }, []);

  useEffect(() => {
    window.wowApp.uwnc = window.wowApp.uwnc || new UWNC();
    window.wowApp.uwnc.setCommands(commands);
  }, []);

  return <></>;
};

export default UWNCPage;
