import LeftLineIcon from 'assets/images/line-left.svg';
import RightLineIcon from 'assets/images/line-right.svg';
import { RA } from 'commands/ra';
import Button from 'components/basic/Button';
import RichTextRender from 'components/basic/RichTextRender';
import { useCustomNavigate, useGlobal, useMediaQuery } from 'hooks/hooks';
import useJobInfo from 'hooks/jonInfo';
import React, { type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/index';
import { type applyInfo } from 'structures/jobDetailItem';
import { isInApp } from 'utils/help';
import { getDocumentHidden } from 'utils/utils';

interface JobStatusProp {
  status?: number; //  0: 未登录 1: 工作失效 2: 工作应募时间失效(过期) 3:未认证  4:已认证但未应募 5:已认证且已应募  6:认证中
  jobId: number;
  refreshJob?: () => void;
  apply_info: applyInfo;
}

const JobStatus: React.FC<JobStatusProp> = ({
  status = 0,
  jobId,
  refreshJob,
  apply_info,
}) => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery();
  const { applyWorkApi } = api;
  const [{ isLoading }] = useJobInfo();
  const [loading, setLoading] = useState(false);
  const ctx = useGlobal();

  // この仕事を応募します
  const ApplyJob = (
    <>
      <div className="border-radius-10 bg-white p-8-x">
        <div className="text-black-dark mb-15-x  text-center font-md font-weight-700">
          {t('other.applyJob')}
        </div>
        <Button
          className="mb-5-x"
          background="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => {
            const result = await applyWorkApi.applyWork({
              workId: jobId,
            });
            ctx.setModal?.({
              show: false,
            });
            const { code } = result || {};
            if (+code === 200) {
              window.wowApp.toast.show({
                content: t('public.jobApplicationSuccess'),
              });
              refreshJob?.();
            } else {
              window.wowApp.toast.show({
                content: t('public.jobApplicationUnSuccess'),
              });
            }
          }}
        >
          {t('public.apply_yes')}
        </Button>
        <Button
          background="cyan"
          fill="outline"
          onClick={() => {
            ctx.setModal?.({
              show: false,
            });
          }}
        >
          {t('public.cancel')}
        </Button>
      </div>
    </>
  );

  // 仕事をキャンセルします mb-4-x
  const CancelApplyJob = (
    <>
      <div className="border-radius-10 bg-white text-center p-8-x">
        <div className="text-black-dark   mb-15-x font-md font-weight-700">
          {t('public.apply_cancel')}
        </div>
        <Button
          className="mb-5-x"
          background="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => {
            const result = await applyWorkApi.cancelAppliedWork({
              workId: jobId,
            });
            ctx.setModal?.({
              show: false,
            });
            const { code } = result || {};
            if (+code === 200) {
              window.wowApp.toast.show({
                content: t('public.jobCancelSuccessful'),
              });
              refreshJob?.();
            } else {
              ctx.setModal?.({
                show: true,
                maskClose: false,
                renderComponent: CancelApplyJobError,
              });
            }
          }}
        >
          {t('public.apply_yes')}
        </Button>
        <Button
          background="cyan"
          fill="outline"
          onClick={() => {
            ctx.setModal?.({
              show: false,
            });
          }}
        >
          {t('public.apply_close')}
        </Button>
      </div>
    </>
  );

  // 仕事をキャンセルします
  const CancelApplyJobError = (
    <>
      <div className="border-radius-10 bg-white p-8-x">
        <div className="text-orange-dark mb-4-x  text-center font-xl font-weight-700">
          {t('public.apply_cancel_failed')}
        </div>
        <RichTextRender
          className="mb-8-x  font-sm text-center line-height-20"
          content={t('other.applyCancelFailedTip')}
        />
        <Button
          background="cyan"
          fill="outline"
          onClick={() => {
            ctx.setModal?.({
              show: false,
            });
          }}
        >
          {t('public.apply_close')}
        </Button>
      </div>
    </>
  );

  let div = <></>;

  // 0: 未登录
  const NotLogin = (
    <>
      <div>
        <ApplyMethodTip
          icon_left={<LeftLineIcon />}
          icon_right={<RightLineIcon />}
          text={t('other.applyMethod')}
        />
        <Button
          background="primary"
          className="ml-10 job-detail-apply"
          onClick={() => {
            if (isInApp()) {
              window.wowApp.uwnc.callNative('NaGoToLogin', {}, () => {});
            } else {
              navigate('/login');
            }
          }}
        >
          <span> {t('button.registerOrLogin')}</span>
        </Button>
      </div>
    </>
  );
  // 1: 工作失效
  const IsValid = (
    <>
      <Button disabled className="ml-10 text-white">
        <span> {t('button.endRecruitment')}</span>
      </Button>
    </>
  );
  // 2: 工作应募时间失效(过期)
  const NotValidTo = (
    <>
      <Button disabled className="ml-10 text-white">
        <span> {t('button.deadline')}</span>
      </Button>
    </>
  );
  // 3:未认证
  const IsVerified = (
    <>
      <div className="ml-10">
        <ApplyMethodTip
          icon_left={<LeftLineIcon />}
          icon_right={<RightLineIcon />}
          text={t('other.applyMethod')}
        />
        <Button
          background="primary"
          loading={loading}
          className="job-detail-apply"
          onClick={() => {
            setLoading(true);
            if (!isInApp()) {
              RA.goToCredential();
              setTimeout(() => {
                if (!window.document[getDocumentHidden()]) {
                  setLoading(false);
                  window.wowApp.toast.show({
                    content: t('errorTips.openAppErrorTip'),
                  });
                }
                setLoading(false);
              }, 2000);
            } else {
              setLoading(false);
              window.wowApp.uwnc.callNative('NaGoToCredential', {}, () => {});
            }
          }}
        >
          <span>{t('button.proceedVerification')}</span>
        </Button>
      </div>
    </>
  );
  // 4:已认证但未应募
  const NotApply = (
    <>
      <Button
        background="primary"
        className="ml-10"
        disabled={isLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => {
          ctx.setModal?.({
            show: true,
            maskClose: false,
            renderComponent: ApplyJob,
          });
        }}
      >
        <span>{t('button.application')}</span>
      </Button>
    </>
  );
  // 5:已认证且已应募
  const IsApplied = (
    <>
      {/* apply_status === declined 辞退 -》应募状态 */}
      {apply_info?.apply_status === 'declined' ? (
        NotApply
      ) : (
        <div className="flex-center">
          <Button
            size={
              isMobile &&
              !['rejected', 'adopted'].includes(apply_info.apply_status)
                ? 'small'
                : 'medium'
            }
            disabled
            className="ml-10 text-gray-dark font-weight-700"
          >
            <span> {t('button.applied')}</span>
          </Button>
          {/* apply_status === rejected and adopted 不显示取消按钮 */}
          {!['rejected', 'adopted'].includes(apply_info.apply_status) && (
            <Button
              id="23"
              size={isMobile ? 'small' : 'medium'}
              fill="outline"
              className="ml-10 text-gray-dark font-weight-700 border-none"
              onClick={() => {
                apply_info?.apply_status === 'pending'
                  ? ctx.setModal?.({
                      show: true,
                      maskClose: false,
                      renderComponent: CancelApplyJob,
                    })
                  : ctx.setModal?.({
                      show: true,
                      maskClose: false,
                      renderComponent: CancelApplyJobError,
                    });
              }}
            >
              <span> {t('public.cancel')}</span>
            </Button>
          )}
        </div>
      )}
    </>
  );
  // 6:认证中
  const Apply = (
    <>
      <div>
        <ApplyMethodTip text={t('button.underReview')} />
        <Button className="ml-10 text-gray-dark" disabled>
          <span> {t('button.proceedVerification')}</span>
        </Button>
      </div>
    </>
  );
  switch (status) {
    case 0:
      div = NotLogin;
      break;
    case 1:
      div = IsValid;
      break;
    case 2:
      div = NotValidTo;
      break;
    case 3:
      div = IsVerified;
      break;
    case 4:
      div = NotApply;
      break;
    case 5:
      div = IsApplied;
      break;
    case 6:
      div = Apply;
      break;
    default:
      div = NotLogin;
      break;
  }
  return div;
};

export default JobStatus;

interface ApplyMethodTipProp {
  text?: string | ReactNode; //
  icon_left?: ReactNode;
  icon_right?: ReactNode;
}

export const ApplyMethodTip: React.FC<ApplyMethodTipProp> = ({
  text,
  icon_left,
  icon_right,
}) => {
  return (
    <div className="apply-method-tip">
      <span className="apply-method-tip-text">
        {icon_left}
        {text}
        {icon_right}
      </span>
    </div>
  );
};
