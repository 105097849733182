import {
  Input as AntdInput,
  type InputProps as AntdInputProps,
  type InputRef as AntdInputRef,
} from 'antd-mobile';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

export type TInput = 'default' | 'gray' | 'form';
export interface InputProps extends AntdInputProps {
  inputType?: TInput;
  beforeAddon?: React.ReactNode;
  afterAddon?: React.ReactNode;
}
const Input: React.FC<InputProps> = ({
  className,
  inputType = 'default',
  value,
  beforeAddon,
  afterAddon,
  ...props
}) => {
  const inputRef = useRef<AntdInputRef | null>(null);

  useEffect(() => {
    !value && inputRef.current?.clear();
  }, [value]);

  return (
    <div
      className={classNames(
        'input-basic font-md flex overflow-hidden',
        className,
        `input-${inputType}`,
        { 'border-radius-5 p-10': inputType === 'default' },
        {
          'bg-gray border-radius-10-x pt-10 pr-5-x pb-10 pl-5-x':
            inputType === 'gray',
        },
        {
          'bg-white border-radius-2': inputType === 'form',
        },
      )}
    >
      {beforeAddon && <div className="before-addon flex">{beforeAddon}</div>}
      <AntdInput ref={inputRef} value={value} {...props} />
      {afterAddon && <div className="after-addon flex">{afterAddon}</div>}
    </div>
  );
};
export default Input;
