import applyWork from 'services/applyWork';
import common from 'services/common';
import faq from 'services/faq';
import favorite from 'services/favorite';
import inquires from 'services/inquires';
import jobs from 'services/jobs';
import login from 'services/login';
import logon from 'services/logon';
import searchFilter from 'services/searchFilter';

export default {
  commonApi: common,
  loginApi: login,
  logonApi: logon,
  jobsApi: jobs,
  faqApi: faq,
  inquiresApi: inquires,
  favoriteApi: favorite,
  applyWorkApi: applyWork,
  searchFilterApi: searchFilter,
};
