/**
 * direct components register
 */
import Modal from 'components/basic/Modal';
import Popup from 'components/basic/Popup';
import React from 'react';
import { type Root, createRoot } from 'react-dom/client';
import { controlLayout } from 'utils/help';

interface RenderInstance {
  container: HTMLElement;
  root: Root;
  destroy: () => void;
}

abstract class DirectComponent {
  protected renderInstance?: RenderInstance;

  static directRender(
    reactElement: React.ReactNode,
    selector?: HTMLElement,
  ): RenderInstance {
    let container: HTMLElement | null =
      selector ?? document.createElement('div');
    let root: Root | null = createRoot(container);
    root.render(reactElement);
    document.body.append(container);

    return {
      container,
      root,
      destroy: () => {
        root?.unmount();
        root = null;
        container?.remove();
        container = null;
      },
    };
  }
}

export class DirectModal extends DirectComponent {
  static popupInstance?: DirectModal;

  constructor() {
    super();
    if (DirectModal.popupInstance) DirectModal.popupInstance = undefined;
    DirectModal.popupInstance = this;
  }

  show(modalProps?: { renderComponent?: React.ReactNode; maskClose: boolean }) {
    const { renderComponent, ...props } = modalProps || {};
    if (this.renderInstance) return;
    this.renderInstance = DirectComponent.directRender(
      <Modal {...props}>{renderComponent}</Modal>,
    );
  }

  destroy() {
    if (!this.renderInstance) return;
    this.renderInstance?.destroy();
    this.renderInstance = undefined;
  }
}
export const directModal = new DirectModal();

export class DirectPopup extends DirectComponent {
  static popupInstance?: DirectPopup;

  constructor() {
    super();
    if (DirectPopup.popupInstance) DirectPopup.popupInstance = undefined;
    DirectPopup.popupInstance = this;
  }

  show(popupProps?: { title?: string; renderComponent?: React.ReactNode }) {
    const { renderComponent, ...props } = popupProps || {};
    if (this.renderInstance) return;
    controlLayout('hidden');
    this.renderInstance = DirectComponent.directRender(
      <Popup {...props}>{renderComponent}</Popup>,
    );
  }

  destroy() {
    if (!this.renderInstance) return;
    controlLayout('auto');
    this.renderInstance?.destroy();
    this.renderInstance = undefined;
  }
}
export const directPopup = new DirectPopup();
