import IconCloseDark from 'assets/images/icon-close-dark.svg';
import classNames from 'classnames';
import React from 'react';

export type TagColor = 'default' | 'pink' | 'green' | 'orange';

export interface TagProps extends React.PropsWithChildren {
  className?: string;
  color?: TagColor;
  backgroundFill?: boolean;
  borderRadius?: string;
  showClose?: boolean;
  onClick?: () => void;
  onClose?: () => void;
}

export const Tag: React.FC<TagProps> = ({
  className,
  color = 'default',
  backgroundFill,
  borderRadius,
  showClose,
  children,
  onClick,
  onClose,
}) => {
  return (
    <span
      className={classNames(
        `tag-basic tag-${color} ${
          backgroundFill ? 'tag-background-fill' : ''
        } flex-center font-xs pt-3 pr-5 pb-3 pl-5 cursor-pointer`,
        className,
      )}
      style={{
        borderRadius: borderRadius ?? '0',
      }}
      onClick={onClick}
    >
      <span className="tag-text text-no-wrap">{children}</span>
      {showClose ? (
        <span className="icon-close flex-center ml-4" onClick={onClose}>
          <IconCloseDark />
        </span>
      ) : (
        <></>
      )}
    </span>
  );
};
