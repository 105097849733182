import classNames from 'classnames';
import Button from 'components/basic/Button';
import LanguageData from 'data/language';
import { useGlobal } from 'hooks/hooks';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Language: React.FC = () => {
  const { i18n, t } = useTranslation();

  const ctx = useGlobal();

  const [active, setActive] = useState(i18n.language);

  const changeLanguage = (lng: string) => {
    setActive(lng);
    i18n.changeLanguage(lng);
  };

  const languageItemList = useMemo(() => {
    return LanguageData.languageItemList || [];
  }, [LanguageData.languageItemList]);

  return (
    <div className="language-compound">
      {languageItemList.map((obj) => {
        return obj.info.map((languageInfo, index) => {
          return (
            <div
              key={index}
              className={classNames(
                'language-div',
                active === languageInfo.key ? 'language-div-active' : '',
              )}
              onClick={() => {
                setActive(languageInfo.key);
              }}
            >
              <languageInfo.icon />
              <span className="language-text">{t(languageInfo.name)}</span>
            </div>
          );
        });
      })}
      <Button
        background="primary"
        shape="rectangular"
        className="mt-10-x"
        onClick={() => {
          changeLanguage(active);
          ctx.setPopup?.({
            show: false,
          });
        }}
      >
        {t('public.ok')}
      </Button>
    </div>
  );
};

export default Language;
