import { Avatar } from 'antd-mobile';
import classNames from 'classnames';
import React from 'react';

interface avatarsProps {
  name?: string;
  avatarClass?: string;
  avatarUrl?: string;
  className?: string;
}

const Avatars: React.FC<avatarsProps> = ({
  name,
  avatarUrl,
  avatarClass,
  className,
}) => {
  return (
    <div className="avatars-basic flex-align-center">
      <Avatar className={avatarClass} src={avatarUrl ?? ''} />
      <div className={classNames('avatars-name overflow-ellipsis', className)}>
        {name}
      </div>
    </div>
  );
};

export default Avatars;
