import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
  signOut,
  updatePassword,
} from 'firebase/auth';

/* ========== DO NOT EDIT ========== */
const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
/* ========== DO NOT EDIT ========== */

export const firebaseApp = initializeApp(FIREBASE_CONFIG);

export const firebaseAuth = getAuth(firebaseApp);

export const firebaseSignInWithPhoneNumber = signInWithPhoneNumber;

export const firebaseCreateUserWithEmailAndPassword =
  createUserWithEmailAndPassword;

export const firebaseSignInWithEmailAndPassword = signInWithEmailAndPassword;

export const firebaseUpdatePassword = updatePassword;

export const firebaseSendPasswordResetEmail = sendPasswordResetEmail;

export const firebaseSignInWithPopup = signInWithPopup;

export const firebaseSignOut = () => {
  return signOut(firebaseAuth);
};
