abstract class AbsUserBase {
  id?: number | string;
  phoneNumber?: string;
  email?: string;
  name?: string;
  avatar?: string;
}

export abstract class AbsUser extends AbsUserBase {
  token?: string;
  tokenExpiresAt?: string;
  isResidenceCardVerified?:
    | 'unverified'
    | 'verified'
    | 'under_review'
    | 'rejected'; // verified
}

export default class LoginUser extends AbsUser {
  static LOCAL_USER_KEY = 'wow_app_user';

  constructor() {
    super();
    this.userInfoInitialize();
  }

  getLocalUserInfo() {
    return JSON.parse(
      window.localStorage.getItem(LoginUser.LOCAL_USER_KEY) || '{}',
    );
  }

  userInfoInitialize() {
    this.setUserInfo(this.getLocalUserInfo(), {
      isFullSet: true,
      store: false,
    });
  }

  getUserInfo() {
    return {
      id: this.id,
      phoneNumber: this.phoneNumber,
      email: this.email,
      name: this.name,
      avatar: this.avatar,
      token: this.token,
      tokenExpiresAt: this.tokenExpiresAt,
      isResidenceCardVerified: this.isResidenceCardVerified,
    };
  }

  async setUserInfo(
    userInfo?: AbsUser,
    config?: { isFullSet: boolean; store: boolean },
  ) {
    const { isFullSet = false, store = true } = config || {};
    const { removePhoneNumberPrefix } = await import('../utils/help');
    this.id = isFullSet ? userInfo?.id : userInfo?.id || this.id;
    const phoneNumber = removePhoneNumberPrefix(userInfo?.phoneNumber) as
      | string
      | undefined;
    this.phoneNumber = isFullSet
      ? phoneNumber
      : phoneNumber || this.phoneNumber;
    this.email = isFullSet ? userInfo?.email : userInfo?.email || this.email;
    this.name = isFullSet ? userInfo?.name : userInfo?.name || this.name;
    this.avatar = isFullSet
      ? userInfo?.avatar
      : `${process.env.REACT_APP_FILE_URL}${userInfo?.avatar}` || this.avatar;
    this.token = isFullSet ? userInfo?.token : userInfo?.token || this.token;
    this.tokenExpiresAt = isFullSet
      ? userInfo?.tokenExpiresAt
      : userInfo?.tokenExpiresAt || this.tokenExpiresAt;
    this.isResidenceCardVerified = isFullSet
      ? userInfo?.isResidenceCardVerified
      : userInfo?.isResidenceCardVerified || this.isResidenceCardVerified;
    store && this.storeUserInfo();
  }

  storeUserInfo() {
    window.localStorage.setItem(
      LoginUser.LOCAL_USER_KEY,
      JSON.stringify(this.getUserInfo()),
    );
  }

  clearUserInfo() {
    this.id = undefined;
    this.phoneNumber = undefined;
    this.email = undefined;
    this.name = undefined;
    this.avatar = undefined;
    this.token = undefined;
    this.tokenExpiresAt = undefined;
    this.isResidenceCardVerified = undefined;
    this.storeUserInfo();
  }

  getToken() {
    return this.token;
  }

  checkToken() {
    const localUserInfo = this.getLocalUserInfo();
    if (this.token && !localUserInfo.token) {
      this.clearUserInfo();
      window.location.reload();
      return;
    }
    if (
      this.tokenExpiresAt &&
      Date.now() >= new Date(this.tokenExpiresAt).getTime()
    )
      window.location.reload();
  }

  isLogin() {
    return !!this.getToken();
  }

  isVerified() {
    return this.isResidenceCardVerified === 'verified';
  }
}
