export interface ICategory {
  id?: number;
  category?: string;
}

export interface IQuestion {
  answer?: string;
  id?: number;
  question?: string;
}

export interface IFaqItemInfo {
  category_id?: number;
  category_name?: string;
  faqs?: IQuestion[];
}

interface IFaqItemInfoData {
  category_id?: number;
  category_name?: string;
  faqs?: IQuestion[];
}

class FaqItemStructure {
  info: IFaqItemInfo[] = [
    {
      category_id: 0,
      category_name: '',
      faqs: [
        {
          answer: '',
          id: 0,
          question: '',
        },
      ],
    },
  ];

  constructor(faqItemInfo: IFaqItemInfoData) {
    this.initialize(faqItemInfo);
  }

  initialize(faqItemInfo: IFaqItemInfoData) {
    this.info.forEach((info) => {
      info.category_id = faqItemInfo.category_id;
      info.category_name = faqItemInfo.category_name;
      info.faqs = faqItemInfo.faqs;
    });
  }
}

export default FaqItemStructure;
