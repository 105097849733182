import RichTextRender from 'components/basic/RichTextRender';
import useFaqs from 'hooks/faq';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const FaqDetail: React.FC = () => {
  const { t } = useTranslation();
  const { faqId } = useParams<{ faqId: string }>();

  const [{ faqDetail }, { getFaqById }] = useFaqs();

  useEffect(() => {
    getFaqById(parseInt(faqId ?? ''));
  }, []);

  return faqDetail.length > 0 ? (
    <div className="content-center bg-white flex-1">
      <div className="faq-detail-content page-content-width p-5-x">
        <div className="faq-detail-question">
          <span className="faq-detail-icon">Q</span>
          {faqDetail[0].info[0].question ?? ''}
        </div>
        <div>
          <div className="faq-detail-answer mb-5">{t('other.answer')}</div>
          <RichTextRender content={faqDetail[0].info[0].answer ?? ''} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FaqDetail;
