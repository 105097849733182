import classNames from 'classnames';
import { useGlobal } from 'hooks/hooks';
import React from 'react';

export interface ModalProps extends React.PropsWithChildren {
  className?: string;
  maskClose?: boolean;
  onClick?: () => void;
}
const Modal: React.FC<ModalProps> = (props) => {
  const ctx = useGlobal();

  const { className, maskClose, children, onClick } = props;

  return children ? (
    <div
      className={classNames('modal-basic', className)}
      onClick={() => {
        onClick?.();
      }}
    >
      <div
        className="modal-mask"
        onClick={() => {
          if (maskClose) {
            ctx.setModal?.({
              show: false,
            });
            window.wowApp.directModal.destroy();
          }
        }}
      ></div>
      <div className="modal-content">{children}</div>
    </div>
  ) : (
    <></>
  );
};
export default Modal;
