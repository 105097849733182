import { Form } from 'antd-mobile';
import { type SelectProps } from 'components/basic/Select';
import loginUser from 'data/users';
import { useCustomNavigate, useLanguage } from 'hooks/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import api from 'services/index';
import { type IProfileFillArgs } from 'services/login';
import { type IButton, type IInput, type IList } from 'types/common';
import {
  emailRegExp,
  getAPILanguage,
  nameKanaRegExp,
  phoneNumberRegExp,
  postalCodeRegExp,
  redirectToIndexPage,
} from 'utils/help';

const { searchFilterApi } = api;

export interface IProfileFillInput extends IInput {
  name:
    | 'lastName'
    | 'firstName'
    | 'lastNameKana'
    | 'firstNameKana'
    | 'email'
    | 'phoneNumber'
    | 'postalCode'
    | 'prefecture'
    | 'city'
    | 'street'
    | 'building';
}
export interface IProfileFillSelect extends SelectProps {
  isSelect: true;
}
export interface IProfileFillButton extends IButton {
  key: 'addressAutoFill' | 'submit';
  background: 'primary';
  fill: 'solid';
}
export interface IProfileFillInputs {
  label: string;
  requiredTip: string;
  inputs: Array<
    | IProfileFillInput
    | IProfileFillSelect
    | Array<IProfileFillInput | IProfileFillButton>
  >;
}
const DEFAULT_PREFECTURE = '東京都';
export const useProfileFillData = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const location = useLocation();
  const navigate = useCustomNavigate();

  const [form] = Form.useForm();
  const [profileFillForm, setProfileFillForm] = useState<IProfileFillArgs>({
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    phoneNumber: loginUser.phoneNumber || '',
    email: loginUser.email || '',
    postalCode: '',
    prefecture: undefined,
    city: '',
    street: '',
    building: '',
  });

  const [prefectureList, setPrefectureList] = useState<IList[]>([]);

  const [addressAutoFillButtonLoading, setAddressAutoFillButtonLoading] =
    useState(false);
  // export const clearProfileFillForm = () => {
  //   profileFillForm = {
  //     lastName: '',
  //     firstName: '',
  //     lastNameKana: '',
  //     firstNameKana: '',
  //     email: '',
  //     phoneNumber: '',
  //   };
  // };

  const name = useMemo<IProfileFillInputs>(() => {
    return {
      label: 'nameLable',
      requiredTip: 'nameRequiredTip',
      inputs: [
        {
          name: 'lastName',
          value: profileFillForm.lastName || '',
          inputType: 'gray',
          label: '',
          placeholder: 'lastNamePlaceholder',
          required: true,
          // pattern: nameRegExp,
          tips: {
            generalErrorTip: 'nameGeneralErrorTip',
          },
          onChange: (value) => {
            setProfileFillForm({
              ...profileFillForm,
              lastName: value,
            });
          },
        },
        {
          name: 'firstName',
          value: profileFillForm.firstName || '',
          inputType: 'gray',
          label: '',
          placeholder: 'firstNamePlaceholder',
          required: true,
          // pattern: nameRegExp,
          tips: {
            generalErrorTip: 'nameGeneralErrorTip',
          },
          onChange: (value) => {
            setProfileFillForm({
              ...profileFillForm,
              firstName: value,
            });
          },
        },
      ],
    };
  }, [profileFillForm]);

  const nameKana = useMemo<IProfileFillInputs>(() => {
    return {
      label: 'nameKanaLable',
      requiredTip: '',
      inputs: [
        {
          name: 'lastNameKana',
          value: profileFillForm.lastNameKana || '',
          inputType: 'gray',
          label: '',
          placeholder: 'lastNameKanaPlaceholder',
          required: true,
          pattern: nameKanaRegExp,
          tips: {
            generalErrorTip: 'nameKanaGeneralErrorTip',
          },
          onChange: (value) => {
            setProfileFillForm({
              ...profileFillForm,
              lastNameKana: value,
            });
          },
        },
        {
          name: 'firstNameKana',
          value: profileFillForm.firstNameKana || '',
          inputType: 'gray',
          label: '',
          placeholder: 'firstNameKanaPlaceholder',
          required: true,
          pattern: nameKanaRegExp,
          tips: {
            generalErrorTip: 'nameKanaGeneralErrorTip',
          },
          onChange: (value) => {
            setProfileFillForm({
              ...profileFillForm,
              firstNameKana: value,
            });
          },
        },
      ],
    };
  }, [profileFillForm]);

  const emailInput = useMemo<IProfileFillInput>(() => {
    return {
      name: 'email',
      value: profileFillForm.email || '',
      inputType: 'gray',
      label: 'emailLable',
      placeholder: 'emailPlaceholder',
      required: true,
      pattern: emailRegExp,
      tips: {
        generalErrorTip: 'emailGeneralErrorTip',
      },
      onChange: (value) => {
        setProfileFillForm({
          ...profileFillForm,
          email: value,
        });
      },
    };
  }, [profileFillForm]);

  const phoneNumberInput = useMemo<IProfileFillInput>(() => {
    return {
      name: 'phoneNumber',
      value: profileFillForm.phoneNumber || '',
      inputType: 'gray',
      label: 'phoneNumberLable',
      placeholder: 'phoneNumberPlaceholder',
      required: true,
      pattern: phoneNumberRegExp,
      tips: {
        generalErrorTip: 'phoneNumberGeneralErrorTip',
      },
      onChange: (value) => {
        setProfileFillForm({
          ...profileFillForm,
          phoneNumber: value,
        });
      },
    };
  }, [profileFillForm]);

  const address = useMemo<IProfileFillInputs>(() => {
    return {
      label: 'addressLable',
      requiredTip: '',
      inputs: [
        [
          {
            name: 'postalCode',
            value: '',
            inputType: 'gray',
            label: '',
            placeholder: 'postalCodePlaceholder',
            required: true,
            pattern: postalCodeRegExp,
            tips: {
              generalErrorTip: 'postalCodeGeneralErrorTip',
            },
            onChange: (value) => {},
          },
          {
            key: 'addressAutoFill',
            background: 'primary',
            fill: 'solid',
            text: 'addressAutoFillButtonText',
            loading: addressAutoFillButtonLoading,
            onClick: () => {
              form
                .validateFields(['postalCode'])
                .then(async () => {
                  const postalCode = form.getFieldValue('postalCode');
                  try {
                    setAddressAutoFillButtonLoading(true);
                    const response = await (
                      await window.fetch(
                        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`,
                      )
                    ).json();
                    setAddressAutoFillButtonLoading(false);
                    const { status, results } = response || {};
                    if (status !== 200) throw new Error();
                    const {
                      address1,
                      address2: city,
                      address3: street,
                    } = results?.[0] || {};
                    const prefecture = prefectureList.find(
                      (prefecture) => address1 === prefecture.label,
                    );
                    console.log('profileFillForm', profileFillForm);
                    const _profileFillForm = {
                      ...profileFillForm,
                      postalCode,
                      prefecture,
                      city,
                      street,
                    };
                    console.log('_profileFillForm', _profileFillForm);
                    setProfileFillForm(_profileFillForm);
                    form.setFieldsValue(_profileFillForm);
                  } catch (error) {
                    setAddressAutoFillButtonLoading(false);
                    window.wowApp.toast.show({
                      content: t('errorTips.systemError'),
                    });
                  }
                })
                .catch(() => {});
            },
          },
        ],
        {
          isSelect: true,
          name: 'prefecture',
          defaultValue: '',
          value: profileFillForm.prefecture?.value || '',
          options: prefectureList,
          placeholder: 'prefecturePlaceholder',
          required: true,
          inputStyle: true,
          // pattern: nameRegExp,
          tips: {
            generalErrorTip: 'notInputGeneralErrorTip',
          },
          onSelect: (value: any) => {
            setProfileFillForm({
              ...profileFillForm,
              prefecture: value,
            });
          },
        },
        {
          name: 'city',
          value: profileFillForm.city || '',
          inputType: 'gray',
          placeholder: 'cityPlaceholder',
          required: true,
          // pattern: nameRegExp,
          tips: {
            generalErrorTip: 'notInputGeneralErrorTip',
          },
          onChange: (value) => {},
        },
        {
          name: 'street',
          value: profileFillForm.street || '',
          inputType: 'gray',
          placeholder: 'streetPlaceholder',
          required: true,
          // pattern: nameRegExp,
          tips: {
            generalErrorTip: 'notInputGeneralErrorTip',
          },
          onChange: (value) => {},
        },
        {
          name: 'building',
          value: profileFillForm.building || '',
          inputType: 'gray',
          placeholder: 'buildingPlaceholder',
          required: false,
          // pattern: nameRegExp,
          tips: {
            generalErrorTip: 'notInputGeneralErrorTip',
          },
          onChange: (value) => {},
        },
      ],
    };
  }, [profileFillForm, prefectureList, addressAutoFillButtonLoading]);

  const button = useMemo<IProfileFillButton>(() => {
    return {
      key: 'submit',
      background: 'primary',
      fill: 'solid',
      text: 'submitButtonText',
      onClick: () => {
        form
          .validateFields()
          .then(async () => {
            setProfileFillForm({
              ...profileFillForm,
              ...form.getFieldsValue(),
            });
            const _profileFillForm = {
              ...profileFillForm,
              ...form.getFieldsValue(),
            };
            navigate({
              pathname: '/profile-confirm',
              search: `${
                location.search ? `${location.search}&` : '?'
              }form=${encodeURIComponent(JSON.stringify(_profileFillForm))}`,
            });
          })
          .catch(() => {});
      },
    };
  }, [profileFillForm]);

  useEffect(() => {
    if (!loginUser.isLogin()) {
      redirectToIndexPage();
      return;
    }
    searchFilterApi
      .getWorkLocation({
        language: getAPILanguage(language),
      })
      .then(({ code, data = [] }) => {
        if (+code !== 200) {
          // TODO: error toast
          return;
        }
        setPrefectureList(
          data.map((item) => ({
            label: item.prefecture_name,
            value: `${item.id}`,
          })),
        );
      });
  }, []);

  useEffect(() => {
    const defaultPrefecture = prefectureList.find(
      (prefecture) => DEFAULT_PREFECTURE === prefecture.label,
    );
    setProfileFillForm({
      ...profileFillForm,
      prefecture: defaultPrefecture,
    });
  }, [prefectureList]);

  return [
    {
      form,
      name,
      nameKana,
      emailInput,
      phoneNumberInput,
      address,
      button,
    },
  ] as const;
};
