import axiosInstance from 'services/fetch';
import { type IHttpResponse } from 'types/common';
import { addPhoneNumberPrefix } from 'utils/help';

export interface IUserLogonArgs {
  type: 'phoneNumber' | 'email';
  value: string;
  code: string;
  password: string;
  passwordConfirm: string;
}
export default {
  userLogon: (logonForm: IUserLogonArgs) => {
    logonForm = addPhoneNumberPrefix(logonForm) as IUserLogonArgs;
    return axiosInstance<any, IHttpResponse, IUserLogonArgs>({
      url: '/auth/register',
      method: 'post',
      data: logonForm,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
