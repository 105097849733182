import loginUser from 'data/users';
import { type TJobSortType } from 'hooks/jobs';
import axiosInstance from 'services/fetch';
import {
  type employmentStatuses,
  type prefectures,
  type professions,
  type salaryTypes,
  type visas,
} from 'structures/jobDetailItem';
import { type IHttpResponse } from 'types/common';

export interface IGetJobs {
  userId?: number | string;
  page: number;
  pageSize: number;
  order?: TJobSortType;
  keyword?: string;
  prefectureId?: string;
  employmentStatusId?: string;
  salaryTypeId?: string;
  visaId?: string;
}

export interface IGetJobInfo {
  id: number;
  userId?: number | string;
}

export interface IResponseJobItem {
  is_favorite: boolean;
  is_valid: 'valid' | 'invalid';
  valid_to: string | null;
  apply_info: {
    apply_status:
      | 'pending'
      | 'contacting'
      | 'scheduled'
      | 'registered'
      | 'adopted'
      | 'rejected'
      | 'declined';
  };
  thumbnail_path: string;
  id: number;
  title: string;
  prefectures: Array<{ prefecture_id: number; prefecture_name: string }>;
  work_location: string;
  professions: Array<{
    profession_id: number;
    profession_name: string;
  }>;
  salary_detail: string;
  working_hour_detail: string;
  employment_statuses: Array<{
    employment_status_id: number;
    employment_status_name: string;
  }>;
}

export default {
  getJobs: (args: IGetJobs) => {
    loginUser?.id && (args.userId = loginUser.id);

    return axiosInstance<
      any,
      {
        data: {
          total: number;
          workList: IResponseJobItem[];
        };
      } & IHttpResponse,
      IGetJobs
    >({
      url: '/works/getWorkList',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getJobInfo: (args: IGetJobInfo) => {
    loginUser?.id && (args.userId = loginUser.id);
    return axiosInstance<
      any,
      {
        data: {
          station_detail: string;
          created_at: string;
          employment_statuses: employmentStatuses[];
          experience_detail: string;
          id: number;
          apply_info: {
            applied_at: string;
            apply_status:
              | 'pending'
              | 'contacting'
              | 'scheduled'
              | 'registered'
              | 'adopted'
              | 'rejected'
              | 'declined';
            is_applied: boolean;
          };
          is_favorite: boolean;
          is_valid: string;
          language: string;
          prefectures: prefectures[];
          professions: professions[];
          salary_detail: string;
          salary_types: salaryTypes[];
          thumbnail_path: string;
          title: string;
          valid_from: string;
          valid_to: string;
          visas: visas[];
          work_detail: string;
          work_location: string;
          working_hour_detail: string;
          working_period_detail: string;
        };
      } & IHttpResponse,
      IGetJobInfo
    >({
      url: '/works/getWorkById',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
