import { Toast } from 'antd-mobile';
import { RA } from 'commands/ra';
import { directModal, directPopup } from 'components/direct/index';
import 'config/i18n/configs';
import routes from 'config/routes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { StillnessProvider } from 'react-stillness-component';
import 'styles/entry.scss';
import {
  dispatchHideSelectOptionsEvent,
  getRunEnviroment,
  isInApp,
} from 'utils/help';

import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

console.log(process.env, 'process.env');

// global variables
window.wowApp = window.wowApp || {
  ra: new RA(),
  getRunEnviroment,
  isInApp,
  toast: Toast,
  directModal,
  directPopup,
};

window.document.addEventListener('click', () => {
  dispatchHideSelectOptionsEvent();
});

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StillnessProvider>
    <RouterProvider router={router} />
  </StillnessProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
