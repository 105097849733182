import { Collapse } from 'antd-mobile';
import { AddOutline, MinusOutline } from 'antd-mobile-icons';
import React, { useMemo } from 'react';

export interface IAccordionData {
  title: string | React.ReactNode;
  context: string | React.ReactNode;
}

interface AccordionProps {
  accordion?: boolean;
  accordionData?: IAccordionData[];
}

const Accordion: React.FC<AccordionProps> = ({
  accordion = true,
  accordionData,
}) => {
  const infos = useMemo(() => {
    return accordionData ?? [];
  }, [accordionData]);

  return (
    <div className="accordion-basic">
      <Collapse
        accordion={accordion}
        arrow={(active) => (active ? <MinusOutline /> : <AddOutline />)}
        className="mb-5"
      >
        {infos.map((item, index) => {
          return (
            <Collapse.Panel
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              key={index + ''}
              title={<div className="accordion-panel-title">{item.title}</div>}
            >
              {item.context}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
export default Accordion;
