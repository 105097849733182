import axiosInstance from 'services/fetch';
import { type IHttpResponse, type IList } from 'types/common';
import { addPhoneNumberPrefix } from 'utils/help';
import { renameObjectKeys } from 'utils/utils';

export interface IProfileFillArgs {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  email: string;
  phoneNumber?: string;
  phone?: string;
  postalCode: string;
  postal_code?: string;
  prefecture?: IList;
  prefecture_id?: string;
  city: string;
  street: string;
  building: string;
}

export default {
  profileFill: (args: IProfileFillArgs) => {
    args.phoneNumber = addPhoneNumberPrefix(args.phoneNumber) as string;
    args.prefecture_id = args.prefecture?.value;
    args = renameObjectKeys(args, {
      phoneNumber: 'phone',
      postalCode: 'postal_code',
    }) as IProfileFillArgs;

    return axiosInstance<any, IHttpResponse, IProfileFillArgs>({
      url: '/users/updateUserBasicInfo',
      method: 'put',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
