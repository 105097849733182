import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocalLanguage } from 'utils/help';

import translationEN from './en_US.json';
import translationID from './id_ID.json';
import translationJA from './ja_JP.json';
import translationNENP from './ne_NP.json';
import translationVIVN from './vi_VN.json';
import translationZHCH from './zh_CH.json';
import translationZHHK from './zh_HK.json';

const localLanguage = getLocalLanguage(
  window.location.search.match(/language=([^&]+)/)?.[1],
);

const resources = {
  en: {
    translation: translationEN,
  },
  ja: {
    translation: translationJA,
  },
  vi_vn: {
    translation: translationVIVN,
  },
  zh_ch: {
    translation: translationZHCH,
  },
  zh_hk: {
    translation: translationZHHK,
  },
  ne_np: {
    translation: translationNENP,
  },
  id_id: {
    translation: translationID,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localLanguage || 'ja',
    fallbackLng: localLanguage || 'ja',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
