import axios, {
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import i18n from 'config/i18n/configs';
import loginUser from 'data/users';
import memoryCache from 'services/cache';
import { type TLanguage } from 'types/common';
import { getAPILanguage } from 'utils/help';

const _axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PATH}`,
});
// memoryCache layer
const axiosInstance = <A = any, B = AxiosResponse<any, any>, C = any>(
  config: AxiosRequestConfig<C>,
  useCache: boolean = false,
): Promise<B> => {
  if (!useCache) return _axiosInstance<A, B, C>(config);
  const memoryCacheKey = memoryCache.getSerializationKey(
    config as InternalAxiosRequestConfig<any>,
  );
  const memoryCacheResult = memoryCache.get(memoryCacheKey);
  if (memoryCacheResult) return Promise.resolve(memoryCacheResult as B);
  return _axiosInstance<A, B, C>(config);
};

_axiosInstance.interceptors.request.use((config) => {
  loginUser.checkToken();
  config.params = config.params || {};
  const token = loginUser.token;
  token && (config.params.accessToken = token);
  const language = getAPILanguage(i18n.language as TLanguage);
  language && (config.params.language = language);
  return config;
});

_axiosInstance.interceptors.response.use(
  (response) => {
    // const { config, data } = response;
    // memoryCache.set(memoryCache.getSerializationKey(config), data); // cache the response
    return response.data;
  },
  (error) => {
    const {
      response,
    }: { response: { data: { code: number; message: string } } } = error;
    const { data } = response || {};
    const { message } = data || {};
    if (message?.toLowerCase().includes('invalid token')) {
      loginUser.clearUserInfo();
      window.location.reload();
    }
    return error.response.data;
  },
);

export default axiosInstance;
