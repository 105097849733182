import { Form } from 'antd-mobile';
import classNames from 'classnames';
import Button from 'components/basic/Button';
import Input from 'components/basic/Input';
import Select from 'components/basic/Select';
import loginUser from 'data/users';
import {
  type IProfileFillButton,
  type IProfileFillInput,
  type IProfileFillInputs,
  type IProfileFillSelect,
  useProfileFillData,
} from 'hooks/profileFill';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const getErrorTipElement = (children: React.ReactElement | string) => {
  return <div className="text-orange-dark">{children}</div>;
};

const ProfileFillPage: React.FC = () => {
  const { t } = useTranslation();
  const [
    { form, name, nameKana, emailInput, phoneNumberInput, address, button },
  ] = useProfileFillData();

  const getInputElement = (
    input: IProfileFillInput,
    index: number,
    transformWhenHasValue?: boolean,
    className?: string,
  ) => {
    return !transformWhenHasValue || !input.value ? (
      <Form.Item
        key={input.name}
        className={classNames(className, { 'mt-7': index >= 1 })}
        name={input.name}
        label={
          !input.label ? (
            <></>
          ) : (
            <div className="text-black-dark font-lg font-weight-700 line-height-28">
              {t(`profileFillPage.${input.label}`)}
            </div>
          )
        }
        rules={[
          {
            required: input.required,
            pattern: input.pattern,
            message: getErrorTipElement(
              t(`errorTips.${input.tips?.generalErrorTip}`),
            ),
          },
        ]}
      >
        <Input
          value={input.value}
          inputType={input.inputType}
          type={input.type}
          placeholder={t(`profileFillPage.${input.placeholder ?? ''}`)}
          onChange={input.onChange}
        />
      </Form.Item>
    ) : (
      <div className={classNames(className)}>
        {input.label ? (
          <div className="text-black-dark font-lg font-weight-700 line-height-28">
            {t(`profileFillPage.${input.label ?? ''}`)}
          </div>
        ) : (
          <></>
        )}
        <div>{input.value}</div>
      </div>
    );
  };

  const getFormInputsElement = (inputs: IProfileFillInputs, index: number) => {
    return (
      <div
        className={classNames('form-inputs-item', {
          'mt-7-x': index >= 1,
        })}
        key={index}
      >
        <div className="form-inputs-item-top flex-align-center mb-8">
          <div className="text-black-dark font-lg font-weight-700 line-height-28 text-no-wrap">
            {t(`profileFillPage.${inputs.label}`)}
          </div>
          {!inputs.requiredTip ? (
            <></>
          ) : (
            <div className="text-orange-dark font-sm line-height-20 ml-8 overflow-ellipsis">
              {t(`profileFillPage.${inputs.requiredTip}`)}
            </div>
          )}
        </div>
        {(inputs.inputs || []).map((input, index) => {
          if (!Array.isArray(input)) {
            if (Object.keys(input).includes('isSelect'))
              return (
                <Select
                  key={index}
                  className={classNames({ 'mt-7': index >= 1 })}
                  name={input.name}
                  value={input.value}
                  defaultValue={(input as IProfileFillSelect).defaultValue}
                  options={(input as IProfileFillSelect).options}
                  placeholder={t(`profileFillPage.${input.placeholder || ''}`)}
                  allowClear={(input as IProfileFillSelect).allowClear}
                  inputStyle={(input as IProfileFillSelect).inputStyle}
                  onSelect={(input as IProfileFillSelect).onSelect}
                />
              );
            else return getInputElement(input as IProfileFillInput, index);
          } else {
            return (
              <div className="input-array profile-fill" key={index}>
                {input.map((subInput) => {
                  if (Object.keys(subInput).includes('key'))
                    return getButtonElement(subInput as IProfileFillButton);
                  else
                    return getInputElement(subInput as IProfileFillInput, -1);
                })}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const getButtonElement = (button: IProfileFillButton, className?: string) => {
    return (
      <Button
        key={button.key}
        className={classNames('font-weight-700', className)}
        background={button.background}
        fill={button.fill}
        loading={button.loading}
        onClick={() => {
          button.onClick();
        }}
      >
        <div>
          <span>{t(`profileFillPage.${button.text}`)}</span>
        </div>
      </Button>
    );
  };

  return (
    <div className="profile-fill-page flex-column flex-align-center p-5-x">
      <div className="profile-fill-container mt-3-x">
        <Form form={form}>
          {[name, nameKana].map((inputs, index) =>
            getFormInputsElement(inputs, index),
          )}
          {getInputElement(emailInput, -1, !!loginUser.email, 'mt-7-x')}
          {getInputElement(
            phoneNumberInput,
            -1,
            !!loginUser.phoneNumber,
            'mt-7-x',
          )}
          {getFormInputsElement(address, 4)}
          {getButtonElement(button, 'mt-20-x')}
        </Form>
      </div>
    </div>
  );
};
export default ProfileFillPage;
