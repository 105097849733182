import { convert } from 'utils/help';

//  雇用形態
export interface employmentStatuses {
  employment_status_id: number;
  employment_status_name: string;
}

// 時給 info
export interface salaryTypes {
  salary_amount_from: string;
  salary_amount_to: string;
  salary_type_id: number;
  salary_type_name: string;
}

// 勤務可能なビザ info
export interface visas {
  visa_id: number;
  visa_name: string;
}

//
export interface prefectures {
  prefecture_id: number;
  prefecture_name: string;
}

export interface professions {
  profession_id: number;
  profession_name: string;
}
//
export interface applyInfo {
  applied_at: string;
  apply_status: string;
  is_applied: boolean;
}
// 基本情報
export interface IBaseInfo {
  occupation_title?: string; // 職種title
  professions: professions[];
  location_title?: string; // 勤務地title
  work_location?: string; // 埼玉県
  salary_title?: string; // 給与title
  salary_types?: salaryTypes[]; // 時給
  working_hours_title?: string; // 勤務時間title
  working_hour_detail?: string; // 13：00～20：00（内5～8時間）
  status_title?: string; // 雇用形態title
  employment_statuses?: employmentStatuses[]; // 雇用形態 info
  work_visa_title?: string; // 勤務可能なビザtitle
  visas?: visas[]; // 留学
  deadline_title?: string;
  valid_to?: string; // // 仕事締切の時間
}

// 募集情報
export interface IRecruitmentInfo {
  jobDetails_title?: string; // 仕事詳細
  work_detail?: string; // サービス
  working_title?: string; // 勤務期間title
  working_period_detail?: string; // 長期（3カ月以上）
  salaryDetails?: string; // 休日・休暇title
  salary_detail?: string; // 時給1,500円交通費実費支給
  experience_title?: string; // 経験・資格title
  experience_content?: string[]; // 未経験OK
  experience_detail?: string; // 日本語レベルN2以上
  commuteMethod_title?: string; // 最寄駅title
  station_detail?: string; //
}

// 応募情報
export interface IApplicationInfo {
  apply_title?: string; // 応募先
  apply?: string; // ファントゥファン株式会社
  apply_method_title?: string; // 応募方法
  apply_method?: string; // 電話
}

// 会社情報
export interface ICompanyInfo {
  company_name?: string; // ファントゥファン株式会社
  location_title?: string; // 所在地
  location?: string; // 東京都千代田区神田須田町2-7-2 NKビル5階
  representative_title?: string; // 代表者名
  representative_name?: string; // 櫻木 亮平
  permission_title?: string; // 派遣許可番号
  permission_number?: string; // v派13ｰ312372
  business_title?: string; // 有料職業紹介事業許可番号
  business_number?: string; // 13 ｰユｰ309971
  base_title?: string; // 拠点
  base?: string; // 17ヶ所帯広営業所、仙台営業所、東京営業所、立川営業所、津田沼営業所、成田営業所、さいたま営
  businessTitle?: string; // 事業内容
  business_content?: string; // 1.アウトソーシング事業
  url_title?: string; // URL
  url?: string;
}

export interface IJobDetailItemInfo {
  is_favorite: boolean; // 0: 为收藏 1: 已收藏
  id: number;
  is_valid: string;
  language: string;
  apply_info: applyInfo; // 工作应募情况
  thumbnail_path: string;
  title: string;
  valid_from: string;
  valid_to: string;
  created_at: string;
  baseInfo: IBaseInfo; // 基本情報
  recruitmentInfo: IRecruitmentInfo; // 募集情報
}

export interface IJobDetailItemInfosData {
  is_favorite: boolean;
  is_valid: string;
  language: string;
  apply_info: applyInfo; // 工作应募情况
  id: number;
  thumbnail_path: string;
  title: string;
  valid_from: string;
  valid_to: string;
  created_at: string;
  baseInfo: {
    professions: professions[];
    work_location?: string;
    salary_types?: salaryTypes[];
    working_hour_detail?: string;
    employment_statuses?: employmentStatuses[];
    visas?: visas[];
    valid_to?: string;
  };

  recruitmentInfo: {
    work_detail?: string;
    working_period_detail?: string;
    salary_detail?: string;
    experience_detail?: string;
    station_detail?: string; //
  };
}

class JobDetailItemStructure {
  info: IJobDetailItemInfo = {
    is_favorite: false,
    is_valid: '',
    language: '',
    apply_info: {
      applied_at: '',
      apply_status: '',
      is_applied: false,
    },
    id: 0,
    thumbnail_path: '',
    title: '',
    valid_from: '',
    valid_to: '',
    created_at: '',
    baseInfo: {
      occupation_title: 'occupationText', //
      professions: [],
      location_title: 'locationText', //
      work_location: '',
      salary_title: 'moneyText', // 給与
      salary_types: [],
      working_hours_title: 'workingHoursText', // 勤務時間
      working_hour_detail: '',
      status_title: 'employmentTypeText', // ;雇用形態
      employment_statuses: [],
      work_visa_title: 'workVisa', // 勤務可能なビザ;
      visas: [],
      deadline_title: 'deadlineDate', // 締切日
      valid_to: '',
    },
    recruitmentInfo: {
      jobDetails_title: 'jobDetails', // アピールポイント
      working_title: 'employmentPeriod', // 勤務期間
      working_period_detail: '',
      work_detail: '',
      salaryDetails: 'salaryDetails', //
      salary_detail: '',
      experience_title: 'experienceAndQualifications', //
      experience_content: [],
      experience_detail: '',
      commuteMethod_title: 'commuteMethod', // 通勤方法
      station_detail: '',
    },
  };

  constructor(jobDetailItemInfo: IJobDetailItemInfosData) {
    this.initialize(jobDetailItemInfo);
  }

  initialize(jobDetailItemInfo: IJobDetailItemInfosData) {
    this.info.is_favorite = jobDetailItemInfo.is_favorite;
    this.info.apply_info = jobDetailItemInfo.apply_info;
    this.info.is_valid = jobDetailItemInfo.is_valid;
    this.info.language = jobDetailItemInfo.language;
    this.info.id = jobDetailItemInfo.id;
    this.info.thumbnail_path = jobDetailItemInfo.thumbnail_path;
    this.info.title = jobDetailItemInfo.title;
    this.info.valid_from = jobDetailItemInfo.valid_from;
    this.info.valid_to = jobDetailItemInfo.valid_to;
    this.info.created_at = jobDetailItemInfo.created_at;
    this.info.baseInfo = convert({
      type: 'baseInfo',
      objs: this.info.baseInfo,
      obj: jobDetailItemInfo.baseInfo,
    }) as IBaseInfo;

    this.info.recruitmentInfo = convert({
      type: 'recruitmentInfo',
      objs: this.info.recruitmentInfo,
      obj: jobDetailItemInfo.recruitmentInfo,
    }) as IRecruitmentInfo;
  }
}

export default JobDetailItemStructure;
