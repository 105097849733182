import { AppliedJobList } from 'components/compound/JobList';
import React from 'react';

export interface AppliedJobsPageProps {
  className?: string;
}
const AppliedJobsPage: React.FC<AppliedJobsPageProps> = () => {
  return (
    <div className="applied-jobs-page flex-column flex-align-center">
      <AppliedJobList />
    </div>
  );
};
export default AppliedJobsPage;
