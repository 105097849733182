import RichTextRender from 'components/basic/RichTextRender';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const content1 = t('policy.content1');
  const content2 = t('policy.content2');
  const content3 = t('policy.content3');
  const content4 = t('policy.content4');
  const content5 = t('policy.content5');
  const content6 = t('policy.content6');
  const content7 = t('policy.content7');
  const content8 = t('policy.content8');
  const content9 = t('policy.content9');
  const content10 = t('policy.content10');
  const content11 = t('policy.content11');

  return (
    <div className="page-content-container">
      <div className="p-5-x page-content-width">
        <RichTextRender
          className="font-sm letter-spacing-xxl line-height-20"
          content={content1}
        />
        <div className="text-orange font-weight-700 letter-spacing-xxxl font-md mt-5-x mb-10">
          {content2}
        </div>

        <div className="font-weight-700 letter-spacing-xxxl font-md">
          {content3}
        </div>
        <div className="font-sm letter-spacing-xxl line-height-20 mt-5">
          {content4}
        </div>

        <div className="font-weight-700 letter-spacing-xxxl font-md mt-10">
          {content5}
        </div>
        <div className="font-sm letter-spacing-xxl line-height-20 mt-5">
          {content6}
        </div>

        <div className="text-orange font-weight-700  letter-spacing-xxxl font-md mt-5-x mb-10">
          {content7}
        </div>
        <div className="font-sm line-height-20 letter-spacing-xxl mt-5">
          {content8}
        </div>
        <div className="font-weight-700 letter-spacing-xxxl font-md mt-10">
          {content9}
        </div>
        <RichTextRender
          className="font-sm line-height-20 letter-spacing-xxl mt-5"
          content={content10}
        />
        <div className="font-sm line-height-20 letter-spacing-xxl mt-10">
          {content11}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
