import { Tag } from 'components/basic/Tag';
import { type JobStatus } from 'hooks/jonInfo';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ApplyStatusProps {
  data: JobStatus;
}

const ApplyStatus: React.FC<ApplyStatusProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="apply-status-container">
      <div className={`apply-status-context status-${data.color}`}>
        <div className="apply-status-tag">
          <Tag color={data.color} borderRadius="5px">
            {t(`jobApplyStatus.applyStatus${data.type}`)}
          </Tag>
        </div>
        <div className="apply-status-description">
          {['Declined', 'Adopted'].includes(data.type)
            ? t(`jobApplyStatus.applyStatus${data.type}Text`, {
                title: data.job_title,
              })
            : t(`jobApplyStatus.applyStatus${data.type}Text`)}
        </div>
      </div>
    </div>
  );
};

export default ApplyStatus;
