import classNames from 'classnames';
import Button from 'components/basic/Button';
import {
  type IProfileConfirmButton,
  type IProfileConfirmTip,
  useProfileConfirmData,
} from 'hooks/profileConfirm';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const getErrorTipElement = (children: React.ReactElement | string) => {
  return <div className="text-orange-dark">{children}</div>;
};

const ProfileConfirmPage: React.FC = () => {
  const { t } = useTranslation();
  const [
    { nameTip, kanaNameTip, emailTip, phoneNumberTip, addressTip, button },
  ] = useProfileConfirmData();

  const getTipElement = (tip: IProfileConfirmTip, index: number) => {
    return (
      <div
        className={classNames('display-flex-column', { 'mt-5-x': index > 0 })}
        key={index}
      >
        <span className="text-gray-dark font-sm font-weight-700 line-height-20">
          {t(`profileConfirmPage.${tip.label}`)}
        </span>
        <div
          className="text-black-dark font-lg font-weight-700 line-height-26"
          dangerouslySetInnerHTML={{
            __html: tip.text,
          }}
        ></div>
      </div>
    );
  };

  const getButtonElement = (button: IProfileConfirmButton) => {
    return (
      <Button
        key={button.key}
        className="font-weight-700 mt-20-x"
        background={button.background}
        fill={button.fill}
        loading={button.loading}
        onClick={() => {
          button.onClick();
        }}
      >
        <div>
          <span>{t(`profileConfirmPage.${button.text}`)}</span>
        </div>
      </Button>
    );
  };

  return (
    <div className="profile-confirm-page flex-column flex-align-center p-5-x">
      <div className="profile-confirm-container">
        {[nameTip, kanaNameTip, emailTip, phoneNumberTip, addressTip].map(
          (tip, index) => getTipElement(tip, index),
        )}
        {getButtonElement(button)}
      </div>
    </div>
  );
};
export default ProfileConfirmPage;
