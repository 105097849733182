import { StarFill, StarOutline } from 'antd-mobile-icons';
import React from 'react';

interface RateProps {
  rate?: boolean;
  className?: string;
  onClick?: () => void;
}

const Rate: React.FC<RateProps> = ({ rate = false, className, onClick }) => {
  return !rate ? (
    <StarOutline
      className={className}
      onClick={onClick}
      color="#DDDDDD"
      fontSize={26}
    />
  ) : (
    <StarFill
      onClick={onClick}
      className={className}
      color="#FF6161"
      fontSize={26}
    />
  );
};
export default Rate;
