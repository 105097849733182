import { Tag } from 'components/basic/Tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type IRecruitmentInfo } from 'structures/jobDetailItem';

import { Info } from './Info';
import { SubTitle } from './SubTitle';

interface recruitmentInfoProps {
  info: IRecruitmentInfo;
}

const RecruitmentInfo: React.FC<recruitmentInfoProps> = ({ info }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Info text={t('jobInfo.recruitmentInfo')}>
        <SubTitle title={t(info.jobDetails_title ?? '')}>
          <div className="mt-10 text-wrap text-no-wrap">{info.work_detail}</div>
        </SubTitle>
        <SubTitle title={t(info.salaryDetails ?? '')} className="mt-5-x">
          <div className="mt-10 text-wrap text-no-wrap">
            {info.salary_detail}
          </div>
        </SubTitle>
        <SubTitle title={t(info.working_title ?? '')} className="mt-5-x">
          <div className="mt-10 text-wrap text-no-wrap">
            {info.working_period_detail}
          </div>
        </SubTitle>

        <SubTitle title={t(info.experience_title ?? '')} className="mt-5-x">
          <div className="mb-10 mt-10">
            {info.experience_content?.map((obj, index) => {
              return (
                <Tag color="pink" className="mr-5 mb-5" key={index}>
                  {obj}
                </Tag>
              );
            })}
          </div>
          <div className="text-wrap text-no-wrap">{info.experience_detail}</div>
        </SubTitle>
        <SubTitle title={t(info.commuteMethod_title ?? '')} className="mt-5-x">
          <div className="mt-10 text-wrap text-no-wrap mb-10">
            {info.station_detail}
          </div>
        </SubTitle>
      </Info>
    </div>
  );
};
export default RecruitmentInfo;
