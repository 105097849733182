import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface GradientProps extends React.PropsWithChildren {
  title?: string;
  flag?: boolean;
  className?: string;
}

export const SubTitle: React.FC<GradientProps> = ({
  title = '',
  flag = true,
  className,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <div className="job-info-basic">
      <div className={classNames(`job-info-subtitle`, className)}>
        {flag ? t(`jobInfo.${title}`) : title}
      </div>
      {children}
    </div>
  );
};
