// import loginUser from 'data/users';
import axiosInstance from 'services/fetch';
import { type IHttpResponse } from 'types/common';

export interface favoriteIdParam {
  workId: number;
}

export default {
  saveFavorite: (args: favoriteIdParam) => {
    return axiosInstance<any, IHttpResponse, favoriteIdParam>({
      url: `/works/saveFavoriteWork`,
      method: 'post',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  deleteFavorite: (args: favoriteIdParam) => {
    return axiosInstance<any, IHttpResponse, favoriteIdParam>({
      url: '/works/deleteFavoriteWork',
      method: 'delete',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
