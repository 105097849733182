import axiosInstance from 'services/fetch';
import { type IHttpResponse, type TAPILanguage } from 'types/common';

export interface ISearchFilter {
  language: TAPILanguage;
}
export type TWorkLocationData = Array<{ id: number; prefecture_name: string }>;
export type TOccupationData = Array<{ id: number; profession_name: string }>;
export type TSalaryGiveData = Array<{ id: number; salary_type_name: string }>;
export type TEmploymentStatusData = Array<{
  id: number;
  employment_status_name: string;
}>;
export type TVisaData = Array<{
  id: number;
  visa_name: string;
}>;
export default {
  getWorkLocation: (args: ISearchFilter) => {
    return axiosInstance<
      any,
      { data: TWorkLocationData } & IHttpResponse,
      ISearchFilter
    >({
      url: '/masters/prefectures/search',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getOccupation: (args: ISearchFilter) => {
    return axiosInstance<
      any,
      { data: TOccupationData } & IHttpResponse,
      ISearchFilter
    >({
      url: '/masters/professions/search',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getSalaryGive: (args: ISearchFilter) => {
    return axiosInstance<
      any,
      { data: TSalaryGiveData } & IHttpResponse,
      ISearchFilter
    >({
      url: '/masters/salary_types/search',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getEmploymentStatus: (args: ISearchFilter) => {
    return axiosInstance<
      any,
      { data: TEmploymentStatusData } & IHttpResponse,
      ISearchFilter
    >({
      url: '/masters/employment_statuses/search',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getVisa: (args: ISearchFilter) => {
    return axiosInstance<
      any,
      { data: TVisaData } & IHttpResponse,
      ISearchFilter
    >({
      url: '/masters/visas/search',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
