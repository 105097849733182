import loginUser from 'data/users';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import api from 'services/index';
import { type IProfileFillArgs } from 'services/login';
import { type IButton } from 'types/common';
import { redirectToIndexPage } from 'utils/help';

const { loginApi } = api;

export interface IProfileConfirmTip {
  label: string;
  text: string;
}
export interface IProfileConfirmButton extends IButton {
  key: 'submit';
  background: 'primary';
  fill: 'solid';
}
export const useProfileConfirmData = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<IProfileFillArgs>({
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    phoneNumber: '',
    email: '',
    postalCode: '',
    prefecture: undefined,
    city: '',
    street: '',
    building: '',
  });

  const nameTip = useMemo<IProfileConfirmTip>(() => {
    return {
      label: 'nameLable',
      text: `${form.lastName} ${form.firstName}` || '',
    };
  }, [form]);

  const kanaNameTip = useMemo<IProfileConfirmTip>(() => {
    return {
      label: 'nameKanaLable',
      text: `${form.lastNameKana} ${form.firstNameKana}` || '',
    };
  }, [form]);

  const emailTip = useMemo<IProfileConfirmTip>(() => {
    return {
      label: 'emailLable',
      text: form.email || '',
    };
  }, [form]);

  const phoneNumberTip = useMemo<IProfileConfirmTip>(() => {
    return {
      label: 'phoneNumberLable',
      text: form.phoneNumber || '',
    };
  }, [form]);

  const addressTip = useMemo<IProfileConfirmTip>(() => {
    return {
      label: 'addressLable',
      text:
        `${form.postalCode}<br />${form.prefecture?.label}${form.city}${form.street}<br />${form.building}` ||
        '',
    };
  }, [form]);

  const button = useMemo<IProfileConfirmButton>(() => {
    return {
      key: 'submit',
      background: 'primary',
      fill: 'solid',
      text: 'submitButtonText',
      loading,
      onClick: async () => {
        setLoading(true);
        const { code, message = t('errorTips.systemError') } =
          await loginApi.profileFill(form);
        setLoading(false);
        if (+code !== 200) {
          window.wowApp.toast.show({ content: message });
          return;
        }
        const { origin, search } = window.location;
        window.location.href = `${origin}?language=${
          search.match(/language=([^&]*)/)?.[1] || ''
        }`;
      },
    };
  }, [form, loading]);

  useEffect(() => {
    const _formString = decodeURIComponent(
      location.search.match(/form=([^&]*)/)?.[1] || '',
    );
    try {
      if (!loginUser.isLogin() || !_formString) {
        redirectToIndexPage();
        return;
      }
      setForm(JSON.parse(_formString));
    } catch (error) {
      console.error(error);
      redirectToIndexPage();
    }
  }, []);

  return [
    {
      nameTip,
      kanaNameTip,
      emailTip,
      phoneNumberTip,
      addressTip,
      button,
    },
  ] as const;
};
