// eslint-disable-next-line import/no-duplicates
import ChinaImg from 'assets/images/china.svg';
// eslint-disable-next-line import/no-duplicates
import ChinaTdImg from 'assets/images/china.svg';
import JaImg from 'assets/images/japan.svg';
import EnImg from 'assets/images/united-kingdom.svg';
import ViImg from 'assets/images/vietnam.svg';
import LanguageItemStructure from 'structures/languageItem';

// eslint-disable-next-line @typescript-eslint/naming-convention
const img_arr = [
  { key: 'ja', icon: JaImg, name: 'public.ja' },
  { key: 'en', icon: EnImg, name: 'public.en' },
  { key: 'zh_ch', icon: ChinaImg, name: 'public.zh_ch' },
  { key: 'zh_hk', icon: ChinaTdImg, name: 'public.zh_hk' },
  { key: 'vi_vn', icon: ViImg, name: 'public.vi_vn' },
  { key: 'ne_np', icon: ViImg, name: 'public.ne_np' },
  { key: 'id_id', icon: ViImg, name: 'public.id_ID' },
];

class LanguageData {
  languageItemList: LanguageItemStructure[] = [];

  constructor() {
    this.getLanguageItemList();
  }

  getLanguageItemList() {
    this.languageItemList = img_arr.map(
      (obj) =>
        new LanguageItemStructure({
          key: obj.key,
          icon: obj.icon,
          name: obj.name,
        }),
    );
  }
}

export default new LanguageData();
