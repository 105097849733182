import { Image } from 'antd-mobile';
import defaultImg from 'assets/images/default-image.png';
import IconCollect from 'assets/images/icon-collect.svg';
import IconCollected from 'assets/images/icon-collected.svg';
import classNames from 'classnames';
import { Tag } from 'components/basic/Tag';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type JobItemStructure from 'structures/jobItem';
import { getRunEnviroment } from 'utils/help';
import { firstLetterTransform } from 'utils/utils';

export interface JobItemViewProps {
  className?: string;
  jobItem?: JobItemStructure;
  onJobCollect?: (jobId?: number) => void;
  onJobRemoveCollect?: (jobId?: number) => void;
}
export const JobItemView: React.FC<JobItemViewProps> = (props) => {
  const { className, jobItem, onJobCollect, onJobRemoveCollect } = props;

  const { t } = useTranslation();

  const applyStatus = useMemo<
    undefined | { type: string; color: 'green' | 'pink' | 'orange' }
  >(() => {
    const { applyStatus } = jobItem || {};
    if (!applyStatus) return;
    else if (['pending', 'contacting', 'scheduled'].includes(applyStatus))
      return {
        type: firstLetterTransform(applyStatus, 'upper'),
        color: 'orange',
      };
    else if (['registered', 'adopted'].includes(applyStatus))
      return {
        type: firstLetterTransform(applyStatus, 'upper'),
        color: 'green',
      };
    else if (['rejected', 'declined'].includes(applyStatus))
      return {
        type: firstLetterTransform(applyStatus, 'upper'),
        color: 'pink',
      };
  }, []);

  return (
    <div className={classNames('job-item-view-compound flex', className)}>
      <div className="job-item-view-left">
        <Image
          src={jobItem?.image}
          fit="cover"
          placeholder={
            <img
              style={{ width: '100%', objectFit: 'cover' }}
              src={defaultImg}
            />
          }
          fallback={
            <img
              style={{ width: '100%', objectFit: 'cover' }}
              src={defaultImg}
            />
          }
          lazy
        />
        <div className="job-item-collect">
          {!jobItem?.collected ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onJobCollect?.(jobItem?.id);
              }}
            >
              <IconCollect />
            </div>
          ) : (
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onJobRemoveCollect?.(jobItem?.id);
              }}
            >
              <IconCollected />
            </div>
          )}
        </div>
        {jobItem?.expired && (
          <div className="job-item-expired text-white font-xl font-weight-700 flex-center">
            {t('jobList.jobExpiredText')}
          </div>
        )}
      </div>
      <div className="job-item-view-right flex-1 flex-align-center pr-10 pl-10">
        <span className="title-text font-md font-weight-700">
          {jobItem?.title}
        </span>
        {applyStatus ? (
          <Tag color={applyStatus.color} borderRadius="5px">
            {t(`jobApplyStatus.applyStatus${applyStatus.type}`)}
          </Tag>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export interface JobItemProps extends JobItemViewProps {
  onClick?: () => void;
}
const JobItem: React.FC<JobItemProps> = (props) => {
  const { className, jobItem, onClick, onJobCollect, onJobRemoveCollect } =
    props;

  const { t } = useTranslation();

  const infos = useMemo(() => {
    return jobItem?.infos ?? [];
  }, [jobItem]);

  const jobItemInfosElement = useMemo(() => {
    return (
      <div className="job-item-infos bg-white font-sm flex-column pt-10 pr-6 pb-10 pl-6">
        {infos
          .filter((info) => info.value)
          .map((info, index) => {
            return (
              <div
                key={`key-${info.key}`}
                className={classNames(
                  'job-item-info text-gray-darker line-height-20 flex-align-center',
                  {
                    'mt-7': index >= 1,
                  },
                )}
              >
                <div className="job-item-info-left text-no-wrap flex-align-center pr-12">
                  <info.icon />
                  <span className="overflow-ellipsis font-weight-700 ml-6">
                    {t(`job.${info.text}`)}
                  </span>
                </div>
                <div className="job-item-info-right overflow-ellipsis">
                  {info.value}
                </div>
              </div>
            );
          })}
      </div>
    );
  }, [infos]);

  return (
    <div
      className={classNames(
        'job-item-compound overflow-hidden cursor-pointer',
        { 'translate-y-n-m': getRunEnviroment() === 'PC' },
        className,
      )}
      onClick={() => {
        onClick?.();
      }}
    >
      <JobItemView
        jobItem={jobItem}
        onJobCollect={onJobCollect}
        onJobRemoveCollect={onJobRemoveCollect}
      />
      {jobItemInfosElement}
      {jobItem?.finished && (
        <div className="job-item-finished text-white font-xl font-weight-700 flex-center">
          <div className="job-item-finished-mask"></div>
          <span className="text-white font-weight-700">
            {t('jobList.jobFinishedText')}
          </span>
        </div>
      )}
    </div>
  );
};
export default JobItem;
