import { CollectedJobList } from 'components/compound/JobList';
import React from 'react';

export interface CollectedJobsPageProps {
  className?: string;
}
const CollectedJobsPage: React.FC<CollectedJobsPageProps> = () => {
  return (
    <div className="collected-jobs-page flex-column flex-align-center">
      <CollectedJobList />
    </div>
  );
};
export default CollectedJobsPage;
