import { DotLoading, InfiniteScroll, Switch } from 'antd-mobile';
import classNames from 'classnames';
import Empty from 'components/basic/Empty';
import { Tag } from 'components/basic/Tag';
import JobItem from 'components/compound/JobItem';
import { useCustomNavigate, useMediaQuery } from 'hooks/hooks';
import useJobs from 'hooks/jobs';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'styles/variables.module.scss';

export interface JobListProps {
  className?: string;
}

// 自定义加载样式
const InfiniteScrollContent = ({
  hasMore,
  endText,
}: {
  hasMore?: boolean;
  endText?: string;
}) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : (
        <span>--- {endText} ---</span>
      )}
    </>
  );
};

const JobList: React.FC<JobListProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const isMobile = useMediaQuery();
  const navigate = useCustomNavigate();
  const [
    {
      initialized,
      page,
      pageSize,
      totalCount,
      jobs,
      hasMore,
      sortTypes,
      jobSearchFilters,
    },
    { removeJobSearchFilter, getJobs, jobCollect, jobRemoveCollect },
  ] = useJobs();

  const getJobsRef = useRef(() => {});
  useEffect(() => {
    getJobsRef.current = () => {
      getJobs(
        {
          targetPage: 1,
          targetPageSize: (page - 1) * pageSize,
        },
        true,
      );
    };
  }, [page, pageSize]);

  useEffect(() => {
    // const bottomtabactiveHandle = (e: Event) => {
    //   window.location.reload();
    // };
    // window.addEventListener('bottomtabactive', bottomtabactiveHandle);
    // return () => {
    //   // clear event side-effect
    //   window.removeEventListener('bottomtabactive', bottomtabactiveHandle);
    // };
  }, []);

  return (
    <div
      className={classNames(
        'job-list-compound wd-full flex-justify-center p-5-x',
        `${isMobile ? 'is-mobile' : 'is-pc'}`,
        className,
      )}
    >
      <div className="job-list-content">
        {isMobile && (
          <div className="job-list-search-filters flex-align-center mb-5-x no-scrollbar">
            {jobSearchFilters.map((jobSearchFilter, index) => {
              return (
                <Tag
                  key={index}
                  className={classNames(index > 0 ? 'ml-8' : '')}
                  showClose
                  onClose={() => {
                    removeJobSearchFilter(jobSearchFilter?.key);
                  }}
                >
                  {jobSearchFilter?.label}
                </Tag>
              );
            })}
          </div>
        )}
        <div className="job-list-filter flex-justify-between flex-align-center mb-4-x overflow-hidden">
          <div className="filter-left font-sm line-height-22">
            {t('jobList.jobCountText', { count: totalCount })}
          </div>
          <div className="filter-right font-xs line-height-20 flex-align-center">
            <span className="text-center text-no-wrap">
              {t('jobList.jobSortText')}:&nbsp;&nbsp;
            </span>
            <div className="text-black flex">
              {sortTypes.map((sortType, index) => {
                return (
                  <div
                    key={sortType.sort}
                    className={classNames(
                      'border-gray-light border-radius-2 text-center text-no-wrap pr-7 pl-7 cursor-pointer',
                      {
                        'ml-8': index >= 1,
                      },
                      {
                        'active bg-green border-none text-white':
                          sortType.active,
                      },
                    )}
                    onClick={() => {
                      sortType.onClick();
                    }}
                  >
                    {t(`jobList.${sortType.text}`)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={classNames('job-list', {
            'flex-wrap': !isMobile,
          })}
        >
          {initialized && !jobs.length ? (
            <Empty />
          ) : (
            jobs.map((job, index) => (
              <JobItem
                key={`jobItem-${index}`}
                className={classNames(
                  index >= (isMobile ? 1 : 2)
                    ? `mt-${isMobile ? '4' : '6'}-x`
                    : null,
                  { 'ml-5-x': !isMobile && index % 2 !== 0 },
                )}
                jobItem={job}
                onClick={() => {
                  navigate(`/job/${job.id}`);
                }}
                onJobCollect={jobCollect}
                onJobRemoveCollect={(jobId?: number) => {
                  jobRemoveCollect({
                    jobId,
                  });
                }}
              />
            ))
          )}
          {initialized && !jobs.length ? (
            <></>
          ) : (
            <InfiniteScroll
              className="text-gray-darker font-sm pt-5-x pb-5-x"
              loadMore={async (isRetry: boolean) => {
                await getJobs();
              }}
              hasMore={hasMore}
            >
              <InfiniteScrollContent
                hasMore={hasMore}
                endText={t('public.noMore')}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};
export default JobList;

export interface CollectedJobListProps {
  className?: string;
}
export const CollectedJobList: React.FC<CollectedJobListProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useCustomNavigate();
  const isMobile = useMediaQuery();
  const [
    { initialized, jobs, hasMore },
    { resetJobs, getJobs, jobCollect, jobRemoveCollect },
  ] = useJobs();

  const [validFilter, setValidFilter] = useState(false);

  useEffect(() => {
    // const bottomtabactiveHandle = (e: Event) => {
    //   window.location.reload();
    // };
    // window.addEventListener('bottomtabactive', bottomtabactiveHandle);
    // return () => {
    //   // clear event side-effect
    //   window.removeEventListener('bottomtabactive', bottomtabactiveHandle);
    // };
  }, []);

  return (
    <div
      className={classNames(
        'collected-job-list-compound wd-full',
        `${isMobile ? 'is-mobile' : 'is-pc'}`,
        'flex-justify-center p-5-x',
        className,
      )}
    >
      <div className="collected-job-list-content">
        <div className="collected-job-list-filter border-bottom-gray flex-justify-between flex-align-center pt-10 pr-5-x pb-10 pl-5-x">
          <span>{t('jobList.collectedJobsInLifeText')}</span>
          <Switch
            style={{
              '--checked-color': variables.orange,
              '--width': '1.71rem',
              '--height': '0.88rem',
            }}
            defaultChecked={validFilter}
            onChange={(state) => {
              setValidFilter(state);
              resetJobs();
            }}
          />
        </div>
        <div
          className={classNames('collected-job-list pt-15 pb-15', {
            'flex-wrap': !isMobile,
          })}
        >
          {initialized && !jobs.length ? (
            <Empty />
          ) : (
            jobs.map((job, index) => (
              <JobItem
                key={`jobItem-${index}`}
                className={classNames(
                  index >= (isMobile ? 1 : 2)
                    ? `mt-${isMobile ? '4' : '6'}-x`
                    : null,
                  { 'ml-5-x': !isMobile && index % 2 !== 0 },
                )}
                jobItem={job}
                onClick={() => {
                  navigate(`/job/${job.id}`);
                }}
                onJobCollect={jobCollect}
                onJobRemoveCollect={(jobId?: number) => {
                  jobRemoveCollect({ jobId, removeJobFromList: true });
                }}
              />
            ))
          )}
          {initialized && !jobs.length ? (
            <></>
          ) : (
            <InfiniteScroll
              className="text-gray-darker font-sm pt-5-x pb-5-x"
              loadMore={async (isRetry: boolean) => {
                await getJobs(
                  {
                    targetPage: 1,
                    targetPageSize: 10000,
                    isFavorite: true,
                    isValid: validFilter,
                  },
                  true,
                );
              }}
              hasMore={hasMore}
            >
              <InfiniteScrollContent
                hasMore={hasMore}
                endText={t('public.noMore')}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export interface AppliedJobListProps {
  className?: string;
}
export const AppliedJobList: React.FC<AppliedJobListProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useCustomNavigate();
  const isMobile = useMediaQuery();
  const [
    { initialized, jobs, hasMore, applyStatusList },
    // resetJobs,
    { getJobs, jobCollect, jobRemoveCollect },
  ] = useJobs();

  // setValidFilter
  const [validFilter] = useState(false);

  useEffect(() => {
    // const bottomtabactiveHandle = (e: Event) => {
    //   window.location.reload();
    // };
    // window.addEventListener('bottomtabactive', bottomtabactiveHandle);
    // return () => {
    //   // clear event side-effect
    //   window.removeEventListener('bottomtabactive', bottomtabactiveHandle);
    // };
  }, []);

  return (
    <div
      className={classNames(
        'applied-job-list-compound wd-full',
        `${isMobile ? 'is-mobile' : 'is-pc'}`,
        'flex-justify-center p-5-x',
        className,
      )}
    >
      <div className="applied-job-list-content">
        <div className="applied-job-list-filter flex pt-10 pb-10 overflow-x-auto no-scrollbar">
          {applyStatusList.map((applyStatus, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  'apply-status border-radius-5 font-xs text-no-wrap pt-3 pr-4 pb-3 pl-4',
                  {
                    'ml-8': index > 0,
                  },
                  !applyStatus.active
                    ? 'border-gray-dark text-gray-dark'
                    : 'active bg-orange border-none text-white font-weight-700',
                )}
                onClick={applyStatus.onClick}
              >
                {t(`${applyStatus.text}`)}
              </div>
            );
          })}
        </div>
        <div
          className={classNames('applied-job-list pt-15 pb-15', {
            'flex-wrap': !isMobile,
          })}
        >
          {initialized && !jobs.length ? (
            <Empty />
          ) : (
            jobs.map((job, index) => (
              <JobItem
                key={`jobItem-${index}`}
                className={classNames(
                  index >= (isMobile ? 1 : 2)
                    ? `mt-${isMobile ? '4' : '6'}-x`
                    : null,
                  { 'ml-5-x': !isMobile && index % 2 !== 0 },
                )}
                jobItem={job}
                onClick={() => {
                  navigate(`/job/${job.id}`);
                }}
                onJobCollect={jobCollect}
                onJobRemoveCollect={(jobId?: number) => {
                  jobRemoveCollect({ jobId, removeJobFromList: true });
                }}
              />
            ))
          )}
          {initialized && !jobs.length ? (
            <></>
          ) : (
            <InfiniteScroll
              className="text-gray-darker font-sm pt-5-x pb-5-x"
              loadMore={async (isRetry: boolean) => {
                await getJobs(
                  {
                    targetPage: 1,
                    targetPageSize: 10000,
                    isApplied: true,
                    isValid: validFilter,
                  },
                  true,
                );
              }}
              hasMore={hasMore}
            >
              <InfiniteScrollContent
                hasMore={hasMore}
                endText={t('public.noMore')}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};
