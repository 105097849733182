import { DotLoading } from 'antd-mobile';
import classNames from 'classnames';
import React from 'react';

export type TButtonBackground =
  | 'default'
  | 'primary'
  | 'green'
  | 'orange'
  | 'orange-dark'
  | 'blue'
  | 'cyan';
export type TButtonSize = 'small' | 'medium' | 'x-medium' | 'large' | 'full';
export type TButtonShape = 'rectangular' | 'rounded';
export type TButtonFill = 'solid' | 'outline';
export interface ButtonProps extends React.PropsWithChildren {
  id?: string;
  className?: string;
  background?: TButtonBackground;
  size?: TButtonSize;
  shape?: TButtonShape;
  fill?: TButtonFill;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}
const Button: React.FC<ButtonProps> = (props) => {
  const {
    id,
    className,
    background = 'default',
    size = 'medium',
    shape = 'rounded',
    fill = 'solid',
    loading,
    disabled,
    children,
    onClick,
  } = props;

  return (
    <div
      id={id}
      className={classNames(
        'button-basic flex-center',
        background,
        size,
        shape,
        fill,
        { loading },
        { disabled },
        className,
      )}
      onClick={() => {
        !loading && !disabled && onClick?.();
      }}
    >
      {loading && <DotLoading color="currentColor" />}
      {children}
    </div>
  );
};
export default Button;
