import classNames from 'classnames';
import UWNCPage from 'commands/uwnc';
import Modal from 'components/basic/Modal';
import Popup from 'components/basic/Popup';
import {
  GlobalContext,
  useListenPageVisibilitychange,
  useModalContext,
  usePopupContext,
  useScrollToTop,
} from 'hooks/hooks';
import { useLoginAuthentication } from 'hooks/login';
import { useLoginUser } from 'hooks/loginUser';
import { Layout } from 'pages/Layout';
import React from 'react';

const App = () => {
  const [{ modalShow, modalMaskClose, modalRenderComponent }, { setModal }] =
    useModalContext();
  const [
    { popupShow, popupTitle, popupHideHead, popupRenderComponent },
    { setPopup },
  ] = usePopupContext();
  useScrollToTop();
  useListenPageVisibilitychange();
  useLoginAuthentication();
  const [loginUser] = useLoginUser();

  return (
    <GlobalContext.Provider
      value={{
        setModal,
        setPopup,
        loginUser,
      }}
    >
      <div
        className={classNames('App', {
          'overflow-hidden': modalShow || popupShow,
        })}
      >
        <Layout />
        <UWNCPage />
        <Modal
          className={`${modalShow ? 'show' : 'hide'}`}
          maskClose={modalMaskClose}
        >
          {modalRenderComponent}
        </Modal>
        <Popup
          className={`${popupShow ? 'show' : 'hide'}`}
          title={popupTitle}
          hideHead={popupHideHead}
        >
          {popupRenderComponent}
        </Popup>
      </div>
    </GlobalContext.Provider>
  );
};

export default App;
