/* eslint-disable @typescript-eslint/restrict-plus-operands */
import loginUser from 'data/users';
import type JobDetailItemStructure from 'structures/jobDetailItem';
import {
  type IBaseInfo,
  type IJobDetailItemInfosData,
  type IRecruitmentInfo,
} from 'structures/jobDetailItem';
import { type AbsUser } from 'structures/user';
import { type TAPILanguage, type TLanguage } from 'types/common';
import { type AnyObj, type NormalObj } from 'types/index';
import { addPrefixZero, objectAssignment } from 'utils/utils';

const hideSelectOptionsEvent = new CustomEvent('hideSelectOptions');

/**
 * 对象赋值
 * @param data_obj 新的数据对象
 * @param setData_obj 需要给赋值的对象
 * @returns
 */
export const convert = ({
  type,
  objs,
  obj,
}:
  | {
      type: 'baseInfo';
      objs: IBaseInfo;
      obj: IJobDetailItemInfosData['baseInfo'];
    }
  | {
      type: 'recruitmentInfo';
      objs: IRecruitmentInfo;
      obj: IJobDetailItemInfosData['recruitmentInfo'];
    }) => {
  // 需要返回的数据
  return objectAssignment<typeof objs>(objs, obj);
};

export const getAPILanguage = (language: TLanguage): TAPILanguage => {
  const languageMap = {
    en: 'en_US',
    ja: 'ja_JP',
    vi_vn: 'vi_VN',
    zh_ch: 'zh_CN',
    zh_hk: 'zh_TW',
    ne_np: 'ne_NP',
    id_id: 'id_ID',
  };
  return languageMap[language] as TAPILanguage;
};

export const getLocalLanguage = (language: TAPILanguage): TLanguage => {
  const languageMap = {
    en_US: 'en',
    ja_JP: 'ja',
    vi_VN: 'vi_vn',
    zh_CN: 'zh_ch',
    zh_TW: 'zh_hk',
    ne_NP: 'ne_np',
    id_ID: 'id_id',
  };
  return languageMap[language] as TLanguage;
};

// form regexp
export const phoneNumberRegExp = /^[0-9]{10,11}$/;
export const emailRegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const codeRegExp = /^[0-9]{4,6}$/;
export const passwordRegExp =
  /^[A-Za-z0-9-~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/]{8,16}$/;
export const nameRegExp = /[A-Za-zぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/;
export const nameKanaRegExp = /^[ァ-ヶー]+$/;
// /^[ｱ-ﾝﾞﾟ]+$/ 半角カタカナ;
export const postalCodeRegExp = /[0-9]{3}[-]?[0-9]{4}/;

// control layout to avoid scroll penetration
export const controlLayout = (type: 'hidden' | 'auto') => {
  document.querySelector('.Layout')?.setAttribute('style', `overflow: ${type}`);
};

// Select component
export const dispatchHideSelectOptionsEvent = () => {
  document.dispatchEvent(hideSelectOptionsEvent);
};

// current is mobile or PC
export const judgeIsMobile = () => {
  return document.documentElement.clientWidth < 768;
};

// run enviroment: InAppAndroid Android InAppIOS IOS PC
export const getRunEnviroment = () => {
  const userAgent = window.navigator.userAgent.toLocaleLowerCase();
  const isInApp = userAgent.includes('wow_app');
  if (userAgent.includes('android')) return `${isInApp ? 'InApp' : ''}Android`;
  else if (userAgent.includes('ios') || userAgent.includes('iphone'))
    return `${isInApp ? 'InApp' : ''}IOS`;
  else return 'PC';
};

export const isInApp = () => {
  return getRunEnviroment().includes('InApp');
};

const android = 'jp.wowfun.app'; // wow_app
const ios = '/id6469296718';

export const androidAppInstallUrl = () => {
  // wow_app
  return `https://play.google.com/store/apps/details?id=${android}`;
};

export const iosAppInstallUrl = () => {
  return `https://apps.apple.com/jp/app/${ios}`;
};

/**
 * 工作详情的状态
 * @param login 登录状态
 * @param jobObj // 工作详情data
 * @returns 0: 未登录 1: 工作失效 2: 工作应募时间失效(过期) 3:未认证  4:已认证但未应募 5:已认证且已应募 6:认证中
 */
export const jobInfoButtonStatus = (jobObj?: JobDetailItemStructure) => {
  // verifiedType  // verifiedEnum

  // 未传参数或未登录
  if (!jobObj || !loginUser.isLogin()) return 0;
  // 工作失效  募集終了
  if (jobObj.info.is_valid !== 'valid') return 1;
  // 小于当前时间 工作应募时间失效(过期) 締切
  if (
    jobObj.info.valid_to != null &&
    new Date(jobObj.info.valid_to).getTime() < new Date().getTime()
  )
    return 2;
  else {
    // 未认证
    // if (!loginUser.isVerified()) return 3;
    // // 已应募
    // else
    if (loginUser.isVerified() && jobObj.info.apply_info.is_applied) {
      return 5;
    } else
      return verifiedType[loginUser.isResidenceCardVerified ?? 'unverified'];
  }
};

// date
export const dateConversion = (time: string) => {
  const date = new Date(time);
  return `${date.getFullYear()}-${addPrefixZero(
    date.getMonth() + 1,
    2,
  )}-${addPrefixZero(date.getDate(), 2)} ${addPrefixZero(
    date.getHours(),
    2,
  )}:${addPrefixZero(date.getMinutes(), 2)}:${addPrefixZero(
    date.getSeconds(),
    2,
  )}`;
};

// install app Android ios
export const installAppLink = () => {
  if (
    getRunEnviroment() === 'InAppAndroid' ||
    getRunEnviroment() === 'Android'
  ) {
    return androidAppInstallUrl();
  } else if (
    getRunEnviroment() === 'InAppIOS' ||
    getRunEnviroment() === 'IOS'
  ) {
    return iosAppInstallUrl();
  }
};

export const addPhoneNumberPrefix = (params?: AnyObj | string) => {
  if (!params) return params;
  if (typeof params === 'object') {
    if (params.type === 'phoneNumber' && !params.value?.startsWith('+81'))
      params.value = `+81${params.value}`;
  } else {
    if (!params.startsWith('+81')) params = `+81${params}`;
  }
  return params;
};

export const removePhoneNumberPrefix = (params?: AnyObj | string) => {
  if (!params) return params;
  if (typeof params === 'object') {
    if (params.type === 'phoneNumber' && params.value?.startsWith('+81'))
      params.value = params.value?.replace('+81', '');
  } else {
    if (params.startsWith('+81')) params = params.replace('+81', '');
  }
  return params;
};

export const transformFirebaseUserDataToLoginUserData = (
  firebaseUserData: NormalObj,
): AbsUser => {
  const {
    uid: id,
    phoneNumber,
    email,
    displayName: name,
    photoURL: avatar,
    accessToken: token,
  } = firebaseUserData;

  return { id, phoneNumber, email, name, avatar, token };
};

export const transformUserDataToLoginUserData = (
  userData: NormalObj,
): AbsUser => {
  const {
    id,
    phone,
    email,
    last_name,
    first_name,
    name,
    is_residence_card_verified,
    token,
    thumbnail_path,
    token_expires_at,
  } = userData;

  return {
    id,
    phoneNumber: phone,
    email,
    name: last_name && first_name ? `${last_name} ${first_name}` : name,
    isResidenceCardVerified: is_residence_card_verified,
    token,
    avatar: thumbnail_path,
    tokenExpiresAt: token_expires_at,
  };
};

/**
 * unverified：未認証、verified：認証済、under_review：審査中、rejected：認証失敗
 */
export const verifiedType = {
  unverified: 3,
  verified: 4,
  under_review: 6,
  rejected: 3,
};

export const redirectToIndexPage = () => {
  const { origin, search } = window.location;
  window.location.href = `${origin}?language=${
    search.match(/language=([^&]*)/)?.[1] || ''
  }`;
};
