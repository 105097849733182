import type React from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  type NavigateOptions,
  type To,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import type LoginUser from 'structures/user';
import { type TLanguage } from 'types/common';
import { getAPILanguage, judgeIsMobile } from 'utils/help';
import { getDocumentHidden, replaceCurrentURLAndReflush } from 'utils/utils';

interface ISetModal {
  show?: boolean;
  maskClose?: boolean;
  renderComponent?: React.ReactNode;
}
interface ISetPopup {
  show?: boolean;
  title?: string;
  hideHead?: boolean;
  renderComponent?: React.ReactNode;
}
export const GlobalContext = createContext<{
  setModal?: (params: ISetModal) => void;
  setPopup?: (params: ISetPopup) => void;
  loginUser?: LoginUser;
}>({});
export const useGlobal = () => {
  const ctx = useContext(GlobalContext);

  return ctx;
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    if (!language || language === i18n.language) return;
    i18n.changeLanguage(language);
    const apiLanguage = getAPILanguage(language as TLanguage);
    const { origin, pathname, search } = window.location;
    const _pathname = pathname.includes('/job/') ? '' : pathname;
    let _search = '';
    if (!search.includes('language'))
      _search = `${search ? `${search}&` : '?'}language=${apiLanguage}`;
    else
      _search = search.replace(/language=([^&]*)/, `language=${apiLanguage}`);
    replaceCurrentURLAndReflush(`${origin}${_pathname}${_search}`);
  };

  return { language: i18n.language as TLanguage, changeLanguage };
};

export const useCustomNavigate = () => {
  const _location = useLocation();
  const _navigate = useNavigate();

  return (to: To, options?: NavigateOptions) => {
    _navigate(
      {
        pathname: typeof to === 'string' ? to : to.pathname,
        search: typeof to.search === 'string' ? to.search : _location.search,
      },
      options,
    );
  };
};

export const useModalContext = () => {
  const location = useLocation();

  const [modalShow, setModalShow] = useState(false);
  const [modalMaskClose, setModalMaskClose] = useState(false);
  const [modalRenderComponent, setModalRenderComponent] = useState<
    React.ReactNode | undefined
  >();
  const setModal = ({ show, maskClose, renderComponent }: ISetModal) => {
    setModalShow(!!show);
    setModalMaskClose(!!maskClose);
    setModalRenderComponent(renderComponent);
  };

  useEffect(() => {
    setModal({ show: false });
  }, [location.pathname, location.key]);

  return [
    { modalShow, modalMaskClose, modalRenderComponent },
    { setModal },
  ] as const;
};

export const usePopupContext = () => {
  const location = useLocation();

  const [popupShow, setPopupShow] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [popupHideHead, setPopupHideHead] = useState(false);
  const [popupRenderComponent, setPopupRenderComponent] = useState<
    React.ReactNode | undefined
  >();
  const setPopup = ({ show, title, hideHead, renderComponent }: ISetPopup) => {
    setPopupShow(!!show);
    window.wowApp?.uwnc?.callNative?.(
      'NaToggleJobSearchFilter',
      {
        show: !!show,
      },
      () => {},
    );
    setPopupTitle(title ?? '');
    setPopupHideHead(!!hideHead);
    setPopupRenderComponent(renderComponent);
  };

  useEffect(() => {
    setPopup({ show: false });
  }, [location.pathname, location.key]);

  return [
    { popupShow, popupTitle, popupHideHead, popupRenderComponent },
    { setPopup },
  ] as const;
};

let isListening = false;
export const useMediaQuery = () => {
  const [isMobile, setIsMobile] = useState(judgeIsMobile());

  const resizeHandle = () => {
    setIsMobile(judgeIsMobile());
  };
  if (isListening) {
    window.removeEventListener('resize', resizeHandle);
    isListening = false;
  }
  window.addEventListener('resize', resizeHandle);
  isListening = true;

  return isMobile;
};

export const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('.App')?.scrollIntoView();
  }, [location.pathname, location.key]);
};

/* listen page visibility change */
export const useListenPageVisibilitychange = () => {
  const location = useLocation();

  useEffect(() => {
    window.document.addEventListener(
      `${getDocumentHidden()?.replace(/[H|h]idden/, '') ?? ''}visibilitychange`,
      () => {
        window.dispatchEvent(
          new CustomEvent('pagevisibilitychange', {
            detail: {
              isVisible: !window.document.hidden,
              location,
            },
          }),
        );
      },
    );
  }, []);
};

/* count down 60s hook */
export const useCountDown = (second: number) => {
  const [countDown, setCountDown] = useState(0);
  const [timer, setTimer] = useState<number | null>(null);

  const startCountDown = useCallback(() => {
    setCountDown(Math.max(0, second));
  }, []);

  const endCountDown = useCallback(() => {
    if (timer) {
      setCountDown(0);
      window.clearInterval(timer);
      setTimer(null);
    }
  }, []);

  const tickRef = useRef(() => {});
  const tick = () => {
    if (countDown > 0) setCountDown(countDown - 1);
    else {
      if (timer) {
        window.clearInterval(timer);
        setTimer(null);
      }
    }
  };
  useEffect(() => {
    tickRef.current = tick;
  });

  useEffect(() => {
    if (!countDown || countDown < second) return;
    setTimer(
      window.setInterval(() => {
        tickRef.current();
      }, 1000),
    );
  }, [countDown]);

  return [{ countDown }, { startCountDown, endCountDown }] as const;
};
