import { Form } from 'antd-mobile';
import IconEmail from 'assets/images/icon-email-2.svg';
import IconFacebook from 'assets/images/icon-facebook-2.svg';
import IconPhone from 'assets/images/icon-phone-2.svg';
import IconTwitter from 'assets/images/icon-twitter-2.svg';
import loginUser from 'data/users';
import {
  FacebookAuthProvider,
  RecaptchaVerifier,
  TwitterAuthProvider,
} from 'firebase/auth';
import { useCountDown, useCustomNavigate, useGlobal } from 'hooks/hooks';
import { EmailLoginInput, getErrorTipElement } from 'pages/Login';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import api from 'services/index';
import {
  firebaseAuth,
  firebaseSendPasswordResetEmail,
  firebaseSignInWithEmailAndPassword,
  firebaseSignInWithPhoneNumber,
  firebaseSignInWithPopup,
  firebaseUpdatePassword,
} from 'services/third-party/firebase';
import { type IButton, type IInput } from 'types/common';
import { type NormalObj } from 'types/index';
import {
  addPhoneNumberPrefix,
  codeRegExp,
  emailRegExp,
  isInApp,
  passwordRegExp,
  phoneNumberRegExp,
  transformFirebaseUserDataToLoginUserData,
  transformUserDataToLoginUserData,
} from 'utils/help';

// import { isSubObject, resetObjectKeys } from 'utils/utils';

const { commonApi } = api;

// const USER_NOT_FOUND = 'user-not-found';
const INVALID_PASSWORD = 'wrong-password';

export type ILoginType = 'phoneNumber' | 'email';

export let loginResetPasswordForm = {
  phoneNumber: '',
  email: '',
  code: '',
  password: '',
  passwordConfirm: '',
};
export const clearLoginResetPasswordForm = () => {
  loginResetPasswordForm = {
    phoneNumber: '',
    email: '',
    code: '',
    password: '',
    passwordConfirm: '',
  };
};
interface ILoginInputStepButton extends IButton {
  key: 'send' | 'close';
  background: 'default' | 'primary';
}
export const useLoginInputStep = (type: ILoginType) => {
  const ctx = useGlobal();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [tip] = useState(`${type}InputTip`);
  const [inputTarget] = useState<IInput>({
    name: type,
    value: '',
    inputType: 'default',
    placeholder: `${type}InputPlaceholder`,
    required: true,
    pattern: type === 'phoneNumber' ? phoneNumberRegExp : emailRegExp,
    tips: {
      generalErrorTip: `${type}GeneralErrorTip`,
    },
  });
  const buttons = useMemo<ILoginInputStepButton[]>(() => {
    return [
      {
        key: 'send',
        background: 'primary',
        text: `${type}SendButtonText`,
        loading,
        onClick: () => {
          form
            .validateFields()
            .then(async () => {
              loginResetPasswordForm = {
                ...loginResetPasswordForm,
                ...form.getFieldsValue(),
              };
              try {
                setLoading(true);
                firebaseSendPasswordResetEmail(
                  firebaseAuth,
                  loginResetPasswordForm.email,
                )
                  .then(() => {
                    ctx.setModal?.({
                      show: false,
                    });
                    window.wowApp.toast.show({
                      content: t('correctTips.sendEmailCorrectTips'),
                    });
                  })
                  .catch(() => {
                    window.wowApp.toast.show({
                      content: t('errorTips.systemError'),
                    });
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              } catch (error) {
                setLoading(false);
                window.wowApp.toast.show({
                  content: t('errorTips.systemError'),
                });
                return;
              }
            })
            .catch(() => {});
        },
      },
      {
        key: 'close',
        background: 'default',
        text: `${type}CloseButtonText`,
        onClick: () => {
          ctx.setModal?.({
            show: false,
          });
        },
      },
    ];
  }, [loading]);

  return [{ form, loading, tip, inputTarget, buttons }] as const;
};

interface ILoginConfirmStepButton extends IButton {
  key: 'confirm' | 'resend' | 'close';
  background: 'default' | 'primary';
  fill: 'solid' | 'outline';
}
export const useLoginConfirmStep = (type: ILoginType) => {
  const ctx = useGlobal();

  const { t } = useTranslation();

  const navigate = useCustomNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [tip] = useState(`${type}ConfirmTip`);
  const [confirmCode] = useState<IInput>({
    name: 'code',
    value: '',
    inputType: 'default',
    placeholder: `${type}ConfirmPlaceholder`,
    required: true,
    pattern: codeRegExp,
    tips: {
      generalErrorTip: 'confirmCodeGeneralErrorTip',
    },
  });
  const buttons = useMemo<ILoginConfirmStepButton[]>(() => {
    return [
      {
        key: 'confirm',
        background: 'primary',
        fill: 'solid',
        text: `${type}ConfirmButtonText`,
        loading,
        onClick: () => {
          form
            .validateFields()
            .then(async () => {
              loginResetPasswordForm = {
                ...loginResetPasswordForm,
                ...form.getFieldsValue(),
              };
              setLoading(true);
              const result = await commonApi.checkCode({
                type,
                value: loginResetPasswordForm[type],
                code: loginResetPasswordForm.code,
              });
              setLoading(false);
              const {
                code,
                message = t('errorTips.systemError'),
                data,
              } = result || {};
              if (+code !== 200 || !data?.match) {
                window.wowApp.toast.show({ content: message });
                return;
              }
              navigate('/login/reset-password');
            })
            .catch(() => {});
        },
      },
      {
        key: 'resend',
        background: 'primary',
        fill: 'outline',
        text: `${type}ResendButtonText`,
        onClick: () => {},
      },
      {
        key: 'close',
        background: 'default',
        fill: 'solid',
        text: `${type}CloseButtonText`,
        onClick: () => {
          ctx.setModal?.({
            show: false,
          });
        },
      },
    ];
  }, [loading]);

  return [{ form, tip, confirmCode, buttons }] as const;
};

export let loginNormalForm = {
  phoneNumber: '',
  code: '',
  email: '',
  password: '',
};
export const clearLoginNormalForm = () => {
  loginNormalForm = {
    phoneNumber: '',
    code: '',
    email: '',
    password: '',
  };
};
export interface ILoginInput extends IInput {
  name: 'phoneNumber' | 'email' | 'code' | 'password' | 'passwordConfirm';
}
export interface ILoginButton extends IButton {
  key: 'send' | 'login' | 'logon';
  background: 'default' | 'primary' | 'orange-dark';
  fill: 'solid' | 'outline';
  countDown?: number;
  timer?: number | null;
}
interface ILoginMethod {
  key: string;
  icon: string;
  onClick: () => void;
}
export const useLoginNormalData = () => {
  const ctx = useGlobal();

  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const [{ countDown }, { startCountDown, endCountDown }] = useCountDown(60);

  const [form] = Form.useForm();

  const [showType, setShowType] = useState<ILoginType>('phoneNumber');
  const [loading, setLoading] = useState(false);

  const inputs = useMemo<
    Array<ILoginInput | Array<ILoginInput | ILoginButton>>
  >(() => {
    if (showType === 'phoneNumber') {
      return [
        {
          name: 'phoneNumber',
          value: '',
          inputType: 'gray',
          label: 'normalPhoneNumberLabel',
          placeholder: 'normalPhoneNumberPlaceholder',
          required: true,
          pattern: phoneNumberRegExp,
          tips: {
            generalErrorTip: 'phoneNumberGeneralErrorTip',
          },
          onChange: (value) => {},
        },
        [
          {
            name: 'code',
            value: '',
            inputType: 'gray',
            label: 'normalCodeLabel',
            placeholder: `normalCodePlaceholder`,
            required: true,
            pattern: codeRegExp,
            tips: {
              generalErrorTip: 'confirmCodeGeneralErrorTip',
            },
            onChange: () => {},
          },
          {
            key: 'send',
            background: 'orange-dark',
            fill: 'outline',
            text: `normalCodeSendButtonText`,
            onClick: () => {
              form
                .validateFields(['phoneNumber'])
                .then(async () => {
                  loginNormalForm = {
                    ...loginNormalForm,
                    ...form.getFieldsValue(),
                  };
                  try {
                    startCountDown();
                    const confirmationResult =
                      await firebaseSignInWithPhoneNumber(
                        firebaseAuth,
                        addPhoneNumberPrefix(
                          loginNormalForm.phoneNumber,
                        ) as string,
                        window.wowApp.firebaseRecaptchaVerifier,
                      );
                    if (!confirmationResult) {
                      endCountDown();
                      window.wowApp.toast.show({
                        content: t('errorTips.systemError'),
                      });
                    } else {
                      window.wowApp.firebaseConfirmationResult =
                        confirmationResult;
                    }
                  } catch (error) {
                    endCountDown();
                    window.wowApp.toast.show({
                      content: t('errorTips.systemError'),
                    });
                  }
                })
                .catch(() => {});
            },
          },
        ],
      ];
    } else {
      return [
        {
          name: 'email',
          value: '',
          inputType: 'gray',
          label: 'normalEmailLabel',
          placeholder: 'normalEmailPlaceholder',
          required: true,
          pattern: emailRegExp,
          tips: {
            generalErrorTip: 'emailGeneralErrorTip',
          },
          onChange: (value) => {},
        },
        {
          name: 'password',
          value: '',
          inputType: 'gray',
          type: 'password',
          label: 'normalPasswordLabel',
          placeholder: 'normalPasswordPlaceholder',
          required: true,
          pattern: passwordRegExp,
          tips: {
            generalErrorTip: 'passwordGeneralErrorTip',
          },
          onChange: (value) => {},
        },
      ];
    }
  }, [showType]);
  const loginTip = {
    show: showType === 'phoneNumber',
    text: 'normalLoginTip',
  };
  const passwordForgot = useMemo(() => {
    if (showType === 'phoneNumber') return;
    else {
      return {
        text: 'normalPasswordForgotText',
        onClick: () => {
          ctx.setModal?.({
            show: true,
            maskClose: true,
            renderComponent: EmailLoginInput,
          });
        },
      };
    }
  }, [showType]);
  const buttons = useMemo<ILoginButton[]>(() => {
    const loginButton: ILoginButton = {
      key: 'login',
      background: 'primary',
      fill: 'solid',
      text: 'normalButtonLoginText',
      loading,
      onClick: () => {
        form
          .validateFields()
          .then(async () => {
            loginNormalForm = {
              ...loginNormalForm,
              ...form.getFieldsValue(),
            };
            if (showType === 'phoneNumber') {
              try {
                setLoading(true);
                const { user } =
                  await window.wowApp.firebaseConfirmationResult?.confirm?.(
                    loginNormalForm.code,
                  );
                setLoading(false);
                if (!user) {
                  window.wowApp.toast.show({
                    content: t('errorTips.systemError'),
                  });
                  return;
                }
                loginUser.setUserInfo(
                  transformFirebaseUserDataToLoginUserData(user),
                );
                window.location.href = `${window.location.origin}${window.location.search}`;
              } catch (error) {
                setLoading(false);
                window.wowApp.toast.show({
                  content: t('errorTips.systemError'),
                });
                return;
              }
            } else {
              try {
                setLoading(true);
                const { user } = await firebaseSignInWithEmailAndPassword(
                  firebaseAuth,
                  loginNormalForm.email,
                  loginNormalForm.password,
                );
                setLoading(false);
                if (!user) {
                  window.wowApp.toast.show({
                    content: t('errorTips.systemError'),
                  });
                  return;
                }
                loginUser.setUserInfo(
                  transformFirebaseUserDataToLoginUserData(user as NormalObj),
                );
                window.location.href = `${window.location.origin}${window.location.search}`;
              } catch (error: any) {
                setLoading(false);
                // if (error.message.includes(USER_NOT_FOUND)) {
                //   window.wowApp.toast.show({
                //     content: 'user not found',
                //   });
                // } else
                if (error.message.includes(INVALID_PASSWORD)) {
                  window.wowApp.toast.show({
                    content: t('errorTips.emailOrPasswordErrorTip'),
                  });
                } else {
                  window.wowApp.toast.show({
                    content: t('errorTips.systemError'),
                  });
                }
              }
            }
          })
          .catch(() => {});
      },
    };
    const logonButton: ILoginButton = {
      key: 'logon',
      background: 'primary',
      fill: 'outline',
      text: 'normalButtonLogonText',
      onClick: () => {
        navigate('/logon');
      },
    };
    if (showType === 'phoneNumber') return [loginButton];
    else return [loginButton, logonButton];
  }, [showType, loading]);
  const methods = useMemo<ILoginMethod[]>(() => {
    return [
      showType === 'phoneNumber'
        ? {
            key: 'loginEmailMethod',
            icon: IconEmail,
            onClick: () => {
              clearLoginNormalForm();
              setShowType('email');
            },
          }
        : {
            key: 'loginPhoneNumberMethod',
            icon: IconPhone,
            onClick: () => {
              clearLoginNormalForm();
              setShowType('phoneNumber');
            },
          },

      {
        key: 'loginFacebookMethod',
        icon: IconFacebook,
        onClick: () => {
          firebaseSignInWithPopup(firebaseAuth, new FacebookAuthProvider())
            .then((result) => {
              const { user } = result;
              if (!user) return Promise.reject(new Error());
              loginUser.setUserInfo(
                transformFirebaseUserDataToLoginUserData(user),
              );
              window.location.href = `${window.location.origin}${window.location.search}`;
            })
            .catch(() => {
              window.wowApp.toast.show({
                content: t('errorTips.systemError'),
              });
            });
        },
      },
      {
        key: 'loginTwitterMethod',
        icon: IconTwitter,
        onClick: () => {
          const twitterProvider = new TwitterAuthProvider();
          twitterProvider.addScope('user_email');
          firebaseSignInWithPopup(firebaseAuth, twitterProvider)
            .then((result) => {
              const { user } = result;
              if (!user) return Promise.reject(new Error());
              loginUser.setUserInfo(
                transformFirebaseUserDataToLoginUserData(user),
              );
              window.location.href = `${window.location.origin}${window.location.search}`;
            })
            .catch(() => {
              window.wowApp.toast.show({
                content: t('errorTips.systemError'),
              });
            });
        },
      },
    ];
  }, [showType]);

  useEffect(() => {
    clearLoginNormalForm();
    clearLoginResetPasswordForm();
    window.wowApp.firebaseRecaptchaVerifier = new RecaptchaVerifier(
      firebaseAuth,
      'login-phoneNumber-send-code-button',
      {
        size: 'invisible',
        callback: () => {},
      },
    );
  }, []);

  return [
    { form, countDown, inputs, loginTip, passwordForgot, buttons, methods },
  ] as const;
};

interface ILoginResetPasswordInput extends IInput {
  name: 'password' | 'passwordConfirm';
  dependencies?: string[];
  validator?: (_form: any) => any;
}
export const useLoginResetPasswordData = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [inputs] = useState<ILoginResetPasswordInput[]>([
    {
      name: 'password',
      value: '',
      inputType: 'gray',
      type: 'password',
      label: 'resetPasswordLabel',
      placeholder: 'resetPasswordPlaceholder',
      required: true,
      pattern: passwordRegExp,
      tips: {
        generalErrorTip: 'passwordGeneralErrorTip',
      },
      onChange: (value) => {},
    },
    {
      name: 'passwordConfirm',
      value: '',
      inputType: 'gray',
      type: 'password',
      label: 'resetPasswordConfirmLabel',
      placeholder: 'resetPasswordPlaceholder',
      dependencies: ['password'],
      required: true,
      pattern: passwordRegExp,
      tips: {
        generalErrorTip: 'passwordGeneralErrorTip',
      },
      validator: (_form) => {
        const _formValue = form.getFieldsValue();
        const { password, passwordConfirm } = _formValue;
        if (_formValue.passwordConfirm === _formValue.password)
          return Promise.resolve();
        else
          return Promise.reject(
            password && passwordConfirm?.length >= 8
              ? getErrorTipElement(t('errorTips.passwordNotSameErrorTip'))
              : '',
          );
      },
      onChange: (value) => {},
    },
  ]);
  const buttons = useMemo<IButton[]>(() => {
    return [
      {
        key: 'resetPassword',
        background: 'primary',
        text: 'resetPasswordButtonText',
        loading,
        onClick: () => {
          form
            .validateFields()
            .then(() => {
              loginResetPasswordForm = {
                ...loginResetPasswordForm,
                ...form.getFieldsValue(),
              };
              const currentUser = firebaseAuth.currentUser;
              if (!currentUser) {
                window.wowApp.toast.show({
                  content: t('errorTips.systemError'),
                });
                navigate('/login');
                return;
              }
              try {
                setLoading(true);
                firebaseUpdatePassword(
                  currentUser,
                  loginResetPasswordForm.password,
                )
                  .then((result) => {
                    window.wowApp.toast.show({
                      content: 'reset password success',
                    });
                    navigate('/login');
                  })
                  .catch(() => {
                    window.wowApp.toast.show({
                      content: t('reset password fail'),
                    });
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              } catch {
                window.wowApp.toast.show({
                  content: t('errorTips.systemError'),
                });
              }
            })
            .catch(() => {});
        },
      },
    ];
  }, [loading]);

  useEffect(() => {
    if (!loginResetPasswordForm.phoneNumber && !loginResetPasswordForm.email) {
      navigate('/login', { replace: true });
    }
  }, []);

  return [{ form, inputs, buttons }] as const;
};

export const useLoginAuthentication = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useCustomNavigate();

  const getUserDataFromNative = useCallback(async () => {
    window.wowApp.uwnc.callNative(
      'NaGetLoginUserInfo',
      {},
      ({ status, data: { userInfo } = { userInfo: null } }) => {
        if (!status || !userInfo) return;
        const user = JSON.parse(userInfo);
        if (
          loginUser.token === user.token &&
          loginUser.isResidenceCardVerified === user.is_residence_card_verified
          // isSubObject(
          //   {
          //     ...resetObjectKeys(
          //       {
          //         ...loginUser,
          //         phoneNumber: loginUser.phoneNumber
          //           ? `+81${loginUser.phoneNumber}`
          //           : loginUser.phoneNumber,
          //       },
          //       {
          //         avatar: 'thumbnail_path',
          //         phoneNumber: 'phone',
          //         tokenExpiresAt: 'token_expires_at',
          //       },
          //     ),
          //   },
          //   user,
          // )
        )
          return;
        loginUser.setUserInfo(transformUserDataToLoginUserData(user), {
          isFullSet: true,
          store: true,
        });
        window.location.reload();
      },
    );
  }, [loginUser]);

  const getUserDataFromServer = useCallback(async () => {
    if (!loginUser.isLogin()) return;
    const {
      code,
      data,
      message = t('errorTips.systemError'),
    } = await commonApi.getUserData();
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({ content: message });
      return;
    }
    loginUser.setUserInfo(transformUserDataToLoginUserData(data));
    // 暂时不扩展loginUser字段, 直接用data判断
    // if (
    //   !data.first_name ||
    //   !data.last_name ||
    //   !data.first_name_kana ||
    //   !data.last_name_kana ||
    //   !data.email ||
    //   !data.phone
    // ) {
    //   navigate('/profile-fill');
    //   return;
    // }
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent('loginuserchange', {
          detail: {},
        }),
      );
    }, 20);
  }, [loginUser]);

  useEffect(() => {
    if (!isInApp()) getUserDataFromServer();
    else {
      getUserDataFromNative();
      // const bottomtabactiveHandle = (e: Event) => {
      //   getUserDataFromNative();
      // };
      // window.addEventListener('bottomtabactive', bottomtabactiveHandle);
      // return () => {
      //   // clear event side-effect
      //   window.removeEventListener('bottomtabactive', bottomtabactiveHandle);
      // };
    }
  }, []);

  useEffect(() => {
    if (!loginUser.isLogin()) return;
    if (['/login', '/logon'].includes(location.pathname)) {
      navigate('/index');
    }
  }, [location.pathname]);
};
