import { Form } from 'antd-mobile';
import { useCountDown, useCustomNavigate } from 'hooks/hooks';
import { getErrorTipElement } from 'pages/Login';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/index';
import {
  firebaseAuth,
  firebaseCreateUserWithEmailAndPassword,
} from 'services/third-party/firebase';
import { type IButton, type IInput } from 'types/common';
import { codeRegExp, emailRegExp, passwordRegExp } from 'utils/help';

const { commonApi } = api;

const INVALID_EMAIL = 'email-already-in-use';

export type TLogonType = 'phoneNumber' | 'email';

export let logonForm = {
  phoneNumber: '',
  email: '',
  code: '',
  password: '',
  passwordConfirm: '',
};
export const clearLogonForm = () => {
  logonForm = {
    phoneNumber: '',
    email: '',
    code: '',
    password: '',
    passwordConfirm: '',
  };
};

export interface ILogonInput extends IInput {
  name: 'email' | 'code' | 'password' | 'passwordConfirm';
  dependencies?: string[];
  validator?: (_form: any) => any;
}
export interface ILogonButton extends IButton {
  key: 'send' | 'login' | 'logon';
  background: 'default' | 'primary' | 'orange-dark';
  fill: 'solid' | 'outline';
}
export const useLogonData = () => {
  const { t } = useTranslation();

  const navigate = useCustomNavigate();

  const [{ countDown }, { startCountDown }] = useCountDown(60);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [tip] = useState('inputTip');
  const inputs = useMemo<
    Array<ILogonInput | Array<ILogonInput | ILogonButton>>
  >(() => {
    return [
      {
        name: 'email',
        value: '',
        inputType: 'gray',
        label: 'emailLabel',
        placeholder: 'emailPlaceholder',
        required: true,
        pattern: emailRegExp,
        tips: {
          generalErrorTip: 'emailGeneralErrorTip',
        },
        onChange: (value) => {},
      },
      [
        {
          name: 'code',
          value: '',
          inputType: 'gray',
          label: 'codeLable',
          placeholder: `codePlaceholder`,
          required: true,
          pattern: codeRegExp,
          tips: {
            generalErrorTip: 'confirmCodeGeneralErrorTip',
          },
          onChange: () => {},
        },
        {
          key: 'send',
          background: 'orange-dark',
          fill: 'outline',
          text: `codeSendButtonText`,
          onClick: () => {
            form
              .validateFields(['email'])
              .then(async () => {
                logonForm = {
                  ...logonForm,
                  ...form.getFieldsValue(),
                };
                startCountDown();
                const { code, message = t('errorTips.systemError') } =
                  await commonApi.sendCode({
                    type: 'email',
                    value: logonForm.email,
                  });
                if (+code !== 200) {
                  window.wowApp.toast.show({ content: message });
                  return;
                }
              })
              .catch(() => {});
          },
        },
      ],
      {
        name: 'password',
        value: '',
        inputType: 'gray',
        type: 'password',
        label: 'passwordLabel',
        placeholder: 'passwordPlaceholder',
        required: true,
        pattern: passwordRegExp,
        tips: {
          generalErrorTip: 'passwordGeneralErrorTip',
        },
      },
      {
        name: 'passwordConfirm',
        value: '',
        inputType: 'gray',
        type: 'password',
        label: 'passwordConfirmLabel',
        placeholder: 'passwordConfirmPlaceholder',
        dependencies: ['password'],
        required: true,
        pattern: passwordRegExp,
        tips: {
          generalErrorTip: 'passwordGeneralErrorTip',
        },
        validator: (_form) => {
          const _formValue = form.getFieldsValue();
          const { password, passwordConfirm } = _formValue;
          if (_formValue.passwordConfirm === _formValue.password)
            return Promise.resolve();
          else
            return Promise.reject(
              password && passwordConfirm?.length >= 8
                ? getErrorTipElement(t('errorTips.passwordNotSameErrorTip'))
                : '',
            );
        },
      },
    ];
  }, []);

  const buttons = useMemo<ILogonButton[]>(() => {
    return [
      {
        key: 'logon',
        background: 'primary',
        fill: 'solid',
        text: 'logonButtonText',
        loading,
        onClick: () => {
          form
            .validateFields()
            .then(async () => {
              logonForm = {
                ...logonForm,
                ...form.getFieldsValue(),
              };
              const { email, code, password } = logonForm;
              setLoading(true);
              const { code: responseCode, data } = await commonApi.checkCode({
                type: 'email',
                value: email,
                code,
              });
              if (+responseCode !== 200 || !data?.match) {
                setLoading(false);
                window.wowApp.toast.show({
                  content: t('errorTips.confirmCodeInValidErrorTip'),
                });
                return;
              }
              try {
                firebaseCreateUserWithEmailAndPassword(
                  firebaseAuth,
                  email,
                  password,
                )
                  .then(({ user }) => {
                    if (!user) {
                      window.wowApp.toast.show({
                        content: t('errorTips.systemError'),
                      });
                      return;
                    }
                    window.wowApp.toast.show({ content: 'success' });
                    navigate('/login');
                  })
                  .catch((error) => {
                    if (error.message.includes(INVALID_EMAIL)) {
                      window.wowApp.toast.show({
                        content: t('errorTips.emailAlreadyRegisteredErrorTip'),
                      });
                    }
                    console.log(
                      error,
                      error.code,
                      error.message,
                      'logon error',
                    );
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              } catch (error) {
                setLoading(false);
                window.wowApp.toast.show({
                  content: t('errorTips.systemError'),
                });
                return;
              }
            })
            .catch(() => {});
        },
      },
    ];
  }, [loading]);

  return [{ form, countDown, tip, inputs, buttons }] as const;
};
