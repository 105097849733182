import React, { useEffect, useRef } from 'react';

const RichTextRender: React.FC<{
  content: string;
  className?: string;
  style?: React.CSSProperties;
}> = (props) => {
  const { content, ...rest } = props;
  const renderRef = useRef<HTMLDivElement | null>(null);
  // 可以将 CSS 包含在组件中
  const shadowRoot = useRef<ShadowRoot | undefined>();

  useEffect(() => {
    if (!renderRef.current) return;
    // 影子根的css作用域会和dom tree中的css作用域隔离，以正确显示富文本内容
    if (!shadowRoot.current)
      // 通过 attachShadow 向元素附加 Shadow DOM 允许js获取它
      shadowRoot.current = renderRef.current.attachShadow({ mode: 'open' });
    // 向 shodowRoot 中添加一些内容
    shadowRoot.current.innerHTML = `<div class="rich-text-render-container"}>${content}</div>`;
    // 设置一些初始化的样式
    const style = document.createElement('style');
    style.textContent = `
      .rich-text-render-container {
        font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
        overflow: hidden;
      }
      .rich-text-render-container * {
        font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
        white-space: pre-wrap;
        word-break: break-all;
      }
      .rich-text-render-container li {
        white-space: inherit;
      }
    `;
    shadowRoot.current.appendChild(style);
  }, [content]);

  return <div ref={renderRef} {...rest} />;
};
export default RichTextRender;
