import axiosInstance from 'services/fetch';
import { type IHttpResponse } from 'types/common';

export interface IGetFaqById {
  id: number;
}

export default {
  getFaqList: () => {
    return axiosInstance<
      any,
      {
        data: Array<{
          category_id: number;
          category_name: string;
          faqs: Array<{
            answer: string;
            id: number;
            question: string;
          }>;
          language: string;
        }>;
      } & IHttpResponse
    >({
      url: 'faqs/getFaqList',
      method: 'get',
      // params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getFaqById: (args: IGetFaqById) => {
    return axiosInstance<
      any,
      {
        data: Array<{
          answer: string;
          id: number;
          language: string;
          question: string;
        }>;
      } & IHttpResponse
    >({
      url: 'faqs/getFaqById',
      method: 'get',
      params: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
