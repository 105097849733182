import { useState } from 'react';
import api from 'services/index';
import FaqDetailItemStructure from 'structures/faqDetailItem';
import FaqItemStructure from 'structures/faqItem';

const { faqApi } = api;

const useFaqs = () => {
  const [faqs, setFaqs] = useState<FaqItemStructure[]>([]);
  const [faqDetail, setFaqDetail] = useState<FaqDetailItemStructure[]>([]);

  const getFaqs = async () => {
    const result = await faqApi.getFaqList();
    const { code, message, data } = result || {};
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({ content: message });
      return;
    }
    setFaqs(
      data.map((faq) => {
        return new FaqItemStructure({
          category_id: faq.category_id,
          category_name: faq.category_name,
          faqs: faq.faqs,
        });
      }),
    );
  };

  const getFaqById = async (id: number) => {
    const result = await faqApi.getFaqById({ id });
    const { code, message, data } = result || {};
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({ content: message });
      return;
    }
    setFaqDetail(
      data.map((faq) => {
        return new FaqDetailItemStructure({
          id: faq.id,
          answer: faq.answer,
          question: faq.question,
          language: faq.language,
        });
      }),
    );
  };

  return [
    { faqs, faqDetail },
    { getFaqs, getFaqById },
  ] as const;
};

export default useFaqs;
