import IconClose from 'assets/images/icon-close.svg';
import classNames from 'classnames';
import { useGlobal } from 'hooks/hooks';
import React from 'react';

export interface PopupProps extends React.PropsWithChildren {
  className?: string;
  title?: string;
  hideHead?: boolean;
  onClose?: () => void;
}
const Popup: React.FC<PopupProps> = (props) => {
  const { className, title, hideHead, children, onClose } = props;

  const ctx = useGlobal();

  return children ? (
    <div className={classNames('popup-basic p-5-x', className)}>
      {hideHead ? (
        <></>
      ) : (
        <div className="popup-head flex-justify-between">
          <div className="text-white font-xl font-weight-700">{title}</div>
          <div
            onClick={() => {
              ctx.setPopup?.({
                show: false,
              });
              window.wowApp.directPopup.destroy();
              onClose?.();
            }}
          >
            <IconClose />
          </div>
        </div>
      )}
      {children}
    </div>
  ) : (
    <></>
  );
};
export default Popup;
