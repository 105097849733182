import { Empty } from 'antd-mobile';
import IconArrowDown from 'assets/images/icon-arrow-down.svg';
import IconCloseDark from 'assets/images/icon-close-dark.svg';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { type Root, createRoot } from 'react-dom/client';
import variables from 'styles/variables.module.scss';
import { findScrollParent } from 'utils/utils';

const appendOptions = (options: React.ReactNode, parent?: Element | null) => {
  let container: HTMLElement | null = document.createElement('div');
  let root: Root | null = createRoot(container);
  root.render(options);
  if (parent) parent.appendChild(container);
  else document.body.append();

  return {
    container,
    root,
    destroy: () => {
      root?.unmount();
      root = null;
      container?.remove();
      container = null;
    },
  };
};

export interface IOption {
  label: string;
  value: string;
}
export interface SelectProps extends React.PropsWithChildren {
  className?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  options?: IOption[];
  placeholder?: string;
  trigger?: 'click' | 'hover';
  allowClear?: boolean;
  selectOptionsHeight?: string;
  inputStyle?: boolean;
  getOptionsContainer?: () => HTMLElement | null;
  onSelect?: (option?: IOption) => void;
}
const TRANSITION_DURATION_FAST = +variables.transitionDurationFast;

const Select: React.FC<SelectProps> = (props) => {
  const {
    className,
    value,
    defaultValue,
    options,
    placeholder,
    trigger = 'click',
    allowClear,
    selectOptionsHeight,
    inputStyle,
    getOptionsContainer,
    onSelect,
  } = props;

  const randomId = useMemo(() => {
    return Date.now() + Math.floor(1000 * Math.random());
  }, []);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const [showOptions, setShowOptions] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState<
    | {
        width: number;
        height: number;
        top: number;
        left: number;
      }
    | undefined
  >();
  const optionsWrapperRef = useRef<HTMLDivElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>();
  const handleTrigger = (
    e: React.MouseEvent,
    type: 'click' | 'mouseenter' | 'mouseleave',
  ) => {
    // set optionsPosition
    if (trigger === 'click') {
      if (type === 'click') {
        if (!showOptions) {
          const target = (e.target as HTMLElement).offsetParent as HTMLElement;
          if (!target) return;
          setOptionsPosition({
            width: target.offsetWidth,
            height: target.offsetHeight,
            top: target.offsetTop,
            left: target.offsetLeft,
          });
        } else setOptionsPosition(undefined);
      }
    } else if (trigger === 'hover') {
      if (type === 'mouseenter') setShowOptions(true);
      if (type === 'mouseleave') setShowOptions(false);
    }
  };
  const optionsElement = useMemo(() => {
    return optionsPosition ? (
      <div
        ref={optionsWrapperRef}
        className="select-options-wrapper"
        style={{
          position: 'absolute',
          width: `${optionsPosition.width}px`,
          inset: `${
            optionsPosition.top + optionsPosition.height + 4
          }px auto auto ${optionsPosition.left}px`,
          zIndex: 3,
          display: 'none',
        }}
      >
        {!options?.length ? (
          <div className="select-empty">
            <Empty className="pt-6-x pb-6-x" description="No Data" />
          </div>
        ) : (
          <div
            className={classNames(
              'select-options border-radius-2 bg-white p-5 overflow-hidden',
              showOptions ? 'select-options-show' : 'select-options-hide',
            )}
            style={{
              maxHeight: selectOptionsHeight ?? '180px',
              transition: `all ${TRANSITION_DURATION_FAST / 1000}s`,
            }}
          >
            {(options || []).map((option) => (
              <div
                key={option.value}
                className="select-option text-black font-sm cursor-pointer"
                onClick={() => {
                  _onSelect(option, true);
                }}
              >
                <div
                  className={classNames('border-radius-4 p-10', {
                    'selected font-weight-700':
                      option.value === selectedOption?.value,
                  })}
                >
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ) : undefined;
  }, [options, showOptions, optionsPosition, selectedOption]);
  const _onSelect = (option?: IOption, callOnSelect: boolean = false) => {
    setSelectedOption(option);
    setOptionsPosition(undefined);
    callOnSelect && onSelect?.(option);
  };
  const optionsShowAndHideToggle = () => {
    const optionsElement = optionsWrapperRef.current;
    if (!optionsElement) return;
    if (!showOptions) {
      optionsElement.style.display = 'block';
      setShowOptions(true);
    } else {
      optionsElement.style.display = 'none';
      setShowOptions(false);
      optionsElement?.remove();
    }
  };
  const showClearIcon = useMemo(() => {
    return allowClear && selectedOption;
  }, [selectedOption]);
  useEffect(() => {
    if (!showOptions && optionsPosition) {
      appendOptions(
        optionsElement,
        getOptionsContainer?.()
          ? getOptionsContainer()
          : findScrollParent(selectRef.current as HTMLElement),
      );
      // async toggle
      setTimeout(() => {
        optionsShowAndHideToggle();
      }, 20);
    } else optionsShowAndHideToggle();
  }, [optionsPosition]);

  useEffect(() => {
    const hideSelectOptionsHandle = () => {
      if (randomId !== +(document.activeElement?.getAttribute('data-id') || 0))
        showOptions && optionsShowAndHideToggle();
    };
    window.document.addEventListener(
      'hideSelectOptions',
      hideSelectOptionsHandle,
    );
    return () => {
      window.document.removeEventListener(
        'hideSelectOptions',
        hideSelectOptionsHandle,
      );
    };
  }, [showOptions]);

  useEffect(() => {
    // set selectedOption
    let selectedOption;
    if (value)
      selectedOption = options?.find((option) => value === option.value);
    if (defaultValue && !selectedOption)
      selectedOption = options?.find((option) => defaultValue === option.value);
    _onSelect(selectedOption);
  }, [options, value, defaultValue]);

  return (
    <div
      className={classNames(
        'select-basic bg-white border-radius-2',
        {
          'input-style bg-gray border-radius-10-x': inputStyle,
        },
        className,
      )}
      ref={selectRef}
    >
      <div
        className={classNames(
          'select-input flex-justify-between flex-align-center',
          {
            'pt-10 pr-5-x pb-10 pl-5-x': inputStyle,
          },
        )}
        onClick={(e) => {
          handleTrigger(e, 'click');
        }}
        onMouseEnter={(e) => {
          handleTrigger(e, 'mouseenter');
        }}
        onMouseLeave={(e) => {
          handleTrigger(e, 'mouseleave');
        }}
      >
        <input
          className="cursor-pointer"
          data-id={randomId}
          type="search"
          autoComplete="off"
          readOnly
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            border: 'none',
            outline: 'none',
            appearance: 'none',
            opacity: 0,
            padding: 0,
            margin: 0,
          }}
        />
        {selectedOption ? (
          <div
            className={classNames(
              'text-black',
              { 'pr-10 pl-10': !inputStyle },
              `font-${!inputStyle ? 'md' : 'lg'} line-height-${
                !inputStyle ? 16 : 18
              }`,
            )}
          >
            {selectedOption.label}
          </div>
        ) : (
          <div
            className={classNames(
              'placeholder line-height-18 overflow-ellipsis',
              !inputStyle
                ? 'text-black-lighter font-sm pr-10 pl-10'
                : 'text-gray-dark font-md font-weight-700',
            )}
          >
            {placeholder}
          </div>
        )}
        <div
          className="icon-arrow-down flex cursor-pointer pr-10"
          style={{
            opacity: showClearIcon ? 0 : 1,
            zIndex: showClearIcon ? 0 : 1,
          }}
        >
          <IconArrowDown />
        </div>
        <div
          className="icon-clear flex cursor-pointer pr-10"
          style={{
            opacity: showClearIcon ? 1 : 0,
            zIndex: showClearIcon ? 1 : 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            _onSelect(undefined, true);
          }}
        >
          <IconCloseDark />
        </div>
      </div>
    </div>
  );
};
export default Select;
