import React from 'react';

export interface GradientProps extends React.PropsWithChildren {
  text?: string;
}

export const Info: React.FC<GradientProps> = ({ text, children }) => {
  return (
    <div>
      <div className="job-info-gradient font-md font-weight-700 letter-spacing-lg">
        {text}
      </div>
      <div className="job-info-context">{children}</div>
    </div>
  );
};
