import { type IOption } from 'components/basic/Select';
import { useCustomNavigate, useLanguage, useMediaQuery } from 'hooks/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import api from 'services/index';
import {
  type TEmploymentStatusData,
  type TOccupationData,
  type TSalaryGiveData,
  type TVisaData,
  type TWorkLocationData,
} from 'services/searchFilter';
import { type IButton, type IList } from 'types/common';
import { getAPILanguage } from 'utils/help';
import { firstLetterTransform, isSearchParamEmpty } from 'utils/utils';

const { searchFilterApi } = api;

export let workLocationListData: IList[] = [];
export let occupationListData: IList[] = [];
export let salaryGiveListData: IList[] = [];
export let employmentStatusListData: IList[] = [];
export let visaListData: IList[] = [];

export const useGetSearchFilterData = () => {
  const { t } = useTranslation();

  const { language } = useLanguage();

  const [workLocationList, setWorkLocationList] =
    useState<IList[]>(workLocationListData);
  const [occupationList, setOccupationList] =
    useState<IList[]>(occupationListData);
  const [salaryGiveList, setSalaryGiveList] =
    useState<IList[]>(salaryGiveListData);
  const [employmentStatusList, setEmploymentStatusList] = useState<IList[]>(
    employmentStatusListData,
  );
  const [visaList, setVisaList] = useState<IList[]>(visaListData);

  // get options from api
  const getOptions = async (target: TSearchFilter) => {
    if (
      (target === 'workLocation' && workLocationList.length) ||
      (target === 'occupation' && occupationList.length) ||
      (target === 'salaryGive' && salaryGiveList.length) ||
      (target === 'employmentStatus' && employmentStatusList.length) ||
      (target === 'visa' && visaList.length)
    )
      return;
    const result = await searchFilterApi[
      `get${firstLetterTransform(target)}` as keyof typeof searchFilterApi
    ]?.({
      language: getAPILanguage(language),
    });
    const { code, message = t('errorTips.systemError'), data } = result || {};
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({ content: message });
      return;
    }
    const operationMap = {
      workLocation: () => {
        const listData = ((data as TWorkLocationData) || []).map((item) => ({
          label: item.prefecture_name,
          value: `${item.id}`,
        }));
        workLocationListData = listData;
        setWorkLocationList(listData);
      },
      occupation: () => {
        const listData = ((data as TOccupationData) || []).map((item) => ({
          label: item.profession_name,
          value: `${item.id}`,
        }));
        occupationListData = listData;
        setOccupationList(listData);
      },
      salaryGive: () => {
        const listData = ((data as TSalaryGiveData) || []).map((item) => ({
          label: item.salary_type_name,
          value: `${item.id}`,
        }));
        salaryGiveListData = listData;
        setSalaryGiveList(listData);
      },
      employmentStatus: () => {
        const listData = ((data as TEmploymentStatusData) || []).map(
          (item) => ({
            label: item.employment_status_name,
            value: `${item.id}`,
          }),
        );
        employmentStatusListData = listData;
        setEmploymentStatusList(listData);
      },
      visa: () => {
        const listData = ((data as TVisaData) || []).map((item) => ({
          label: item.visa_name,
          value: `${item.id}`,
        }));
        visaListData = listData;
        setVisaList(listData);
      },
    };
    operationMap[target as keyof typeof operationMap]?.();
  };

  useEffect(() => {
    getOptions('workLocation');
    getOptions('occupation');
    getOptions('salaryGive');
    getOptions('employmentStatus');
    getOptions('visa');
  }, []);

  return [
    workLocationList,
    occupationList,
    salaryGiveList,
    employmentStatusList,
    visaList,
  ];
};

type TSearchFilter =
  | 'keyword'
  | 'workLocation'
  | 'occupation'
  | 'salaryGive'
  | 'employmentStatus'
  | 'visa';
interface ISearchFilterItem {
  key: string;
  text?: string;
  name: string;
  value: string;
  defaultValue?: string;
  placeholder?: string;
  allowClear?: boolean;
  options?: IOption[];
  onSelect?: (value?: IOption) => void;
  onChange?: (value?: string) => void;
  onSearch?: () => void;
}
export const useSearchFilter = () => {
  const [
    workLocationList,
    occupationList,
    salaryGiveList,
    employmentStatusList,
    visaList,
  ] = useGetSearchFilterData();

  const location = useLocation();
  const navigate = useCustomNavigate();
  const isMobile = useMediaQuery();

  // get value from location search
  const getValue = (key: string) => {
    return (
      location.search
        .replace('?', '')
        .split('&')
        .filter((filter) => !!filter)
        .map((filter) => filter.split('='))
        .find(([_key, _value]) => {
          return key === _key;
        })?.[1] || ''
    );
  };

  const [keyword, setKeyword] = useState<ISearchFilterItem>({
    key: 'keyword',
    name: 'keyword',
    value: window.decodeURIComponent(getValue('keyword')),
    placeholder: 'keywordPlaceholder',
    onChange: (value) => {
      setKeyword({ ...keyword, value: value ?? '' });
    },
    onSearch: () => {},
  });
  const [workLocation, setWorkLocation] = useState<ISearchFilterItem>({
    key: 'workLocation',
    text: 'workLocationText',
    name: 'workLocation',
    value: getValue('workLocation'),
    placeholder: 'workLocationPlaceholder',
    allowClear: true,
    options: workLocationList,
  });
  const [occupation, setOccupation] = useState<ISearchFilterItem>({
    key: 'occupation',
    text: 'occupationText',
    name: 'occupation',
    value: getValue('occupation'),
    placeholder: 'occupationPlaceholder',
    allowClear: true,
    options: occupationList,
  });
  const [salaryGive, setSalaryGive] = useState<ISearchFilterItem>({
    key: 'salaryGive',
    text: 'salaryGiveText',
    name: 'salaryGive',
    value: getValue('salaryGive'),
    placeholder: 'salaryGivePlaceholder',
    allowClear: true,
    options: salaryGiveList,
  });
  const [employmentStatus, setEmploymentStatus] = useState<ISearchFilterItem>({
    key: 'employmentStatus',
    text: 'employmentStatusText',
    name: 'employmentStatus',
    value: getValue('employmentStatus'),
    placeholder: 'employmentStatusPlaceholder',
    allowClear: true,
    options: employmentStatusList,
  });
  const [visa, setVisa] = useState<ISearchFilterItem>({
    key: 'visa',
    text: 'visaText',
    name: 'visa',
    value: getValue('visa'),
    placeholder: 'visaPlaceholder',
    allowClear: true,
    options: visaList,
  });
  const [clearButton] = useState<IButton>({
    key: 'clear',
    background: 'default',
    size: 'small',
    shape: 'rectangular',
    text: 'searchFilterClearButtonText',
    onClick: () => {},
  });
  const [searchButton] = useState<IButton>({
    key: 'search',
    background: isMobile ? 'primary' : 'cyan',
    size: 'small',
    shape: 'rectangular',
    text: 'searchFilterSearchButtonText',
    onClick: () => {},
  });

  useEffect(() => {
    setKeyword(() => ({
      ...keyword,
      onChange: (value) => {
        setKeyword({ ...keyword, value: value ?? '' });
      },
    }));
  }, [keyword.value]);

  useEffect(() => {
    setWorkLocation(() => ({
      ...workLocation,
      options: workLocationList,
      onSelect: (selectedOption) => {
        setWorkLocation({
          ...workLocation,
          value: selectedOption?.value ?? '',
        });
      },
    }));
  }, [workLocationList, workLocation.options, workLocation.value]);

  useEffect(() => {
    setOccupation(() => ({
      ...occupation,
      options: occupationList,
      onSelect: (selectedOption) => {
        setOccupation({
          ...occupation,
          value: selectedOption?.value ?? '',
        });
      },
    }));
  }, [occupationList, occupation.options, occupation.value]);

  useEffect(() => {
    setSalaryGive(() => ({
      ...salaryGive,
      options: salaryGiveList,
      onSelect: (selectedOption) => {
        setSalaryGive({
          ...salaryGive,
          value: selectedOption?.value ?? '',
        });
      },
    }));
  }, [salaryGiveList, salaryGive.options, salaryGive.value]);

  useEffect(() => {
    setEmploymentStatus(() => ({
      ...employmentStatus,
      options: employmentStatusList,
      onSelect: (selectedOption) => {
        setEmploymentStatus({
          ...employmentStatus,
          value: selectedOption?.value ?? '',
        });
      },
    }));
  }, [employmentStatusList, employmentStatus.options, employmentStatus.value]);

  useEffect(() => {
    setVisa(() => ({
      ...visa,
      options: visaList,
      onSelect: (selectedOption) => {
        setVisa({
          ...visa,
          value: selectedOption?.value ?? '',
        });
      },
    }));
  }, [visaList, visa.options, visa.value]);

  useEffect(() => {
    if (!window.sessionStorage.getItem('wow-app-can-clear-search-filters'))
      return;
    if (isSearchParamEmpty('keyword')) clearKeyword.current();
    if (isSearchParamEmpty('workLocation')) clearWorkLocation.current();
    if (isSearchParamEmpty('occupation')) clearOccupation.current();
    if (isSearchParamEmpty('salaryGive')) clearSalaryGive.current();
    if (isSearchParamEmpty('employmentStatus')) clearEmploymentStatus.current();
    if (isSearchParamEmpty('visa')) clearVisa.current();
    window.sessionStorage.setItem('wow-app-can-clear-search-filters', '');
  }, [location.search]);

  const clearKeyword = useRef(() => {});
  const clearWorkLocation = useRef(() => {});
  const clearOccupation = useRef(() => {});
  const clearSalaryGive = useRef(() => {});
  const clearEmploymentStatus = useRef(() => {});
  const clearVisa = useRef(() => {});

  const clear = useCallback(() => {
    clearKeyword.current();
    clearWorkLocation.current();
    clearOccupation.current();
    clearSalaryGive.current();
    clearEmploymentStatus.current();
    clearVisa.current();
    setTimeout(() => {
      submitFormDataRef.current();
    }, 20);
  }, [keyword, workLocation, occupation, salaryGive, employmentStatus, visa]);

  const submitFormDataRef = useRef(() => {});
  useEffect(() => {
    submitFormDataRef.current = () => {
      const keywordSearch = keyword.value ? `&keyword=${keyword.value}` : '';
      const workLocationSearch = workLocation.value
        ? `&workLocation=${workLocation.value}`
        : '';
      const occupationSearch = occupation.value
        ? `&occupation=${occupation.value}`
        : '';
      const salaryGiveSearch = salaryGive.value
        ? `&salaryGive=${salaryGive.value}`
        : '';
      const employmentStatusSearch = employmentStatus.value
        ? `&employmentStatus=${employmentStatus.value}`
        : '';
      const visaSearch = visa.value ? `&visa=${visa.value}` : '';
      const searchFilterString =
        `${keywordSearch}${workLocationSearch}${occupationSearch}${salaryGiveSearch}${employmentStatusSearch}${visaSearch}`.replace(
          /^[&]*/,
          '',
        );
      const { search } = window.location;
      const languageString = search.match(/(language=[A-Za-z_]*)/)?.[0];
      const searchString = `?${
        languageString ? `${languageString}&` : ''
      }${searchFilterString}`;
      navigate(
        {
          pathname: '/index',
          search: searchString,
        },
        {
          replace: true,
        },
      );
    };

    clearKeyword.current = () => {
      setKeyword({ ...keyword, value: '' });
    };
    clearWorkLocation.current = () => {
      setWorkLocation({ ...workLocation, value: '' });
    };
    clearOccupation.current = () => {
      setOccupation({ ...occupation, value: '' });
    };
    clearSalaryGive.current = () => {
      setSalaryGive({ ...salaryGive, value: '' });
    };
    clearEmploymentStatus.current = () => {
      setEmploymentStatus({ ...employmentStatus, value: '' });
    };
    clearVisa.current = () => {
      setVisa({ ...visa, value: '' });
    };
  });

  return [
    {
      workLocationList,
      occupationList,
      salaryGiveList,
      employmentStatusList,
      visaList,

      keyword,
      workLocation,
      occupation,
      salaryGive,
      employmentStatus,
      visa,
      clearButton,
      searchButton,
    },
    {
      clearKeyword: clearKeyword.current,
      clearWorkLocation: clearWorkLocation.current,
      clearOccupation: clearOccupation.current,
      clearSalaryGive: clearSalaryGive.current,
      clearEmploymentStatus: clearEmploymentStatus.current,
      clearVisa: clearVisa.current,
      clear,
      submitFormData: submitFormDataRef.current,
    },
  ] as const;
};
