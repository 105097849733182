import loginUser from 'data/users';
import { useCustomNavigate } from 'hooks/hooks';
import { useGetSearchFilterData } from 'hooks/searchFilter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useStillness } from 'react-stillness-component';
import api from 'services/index';
import { type IResponseJobItem } from 'services/jobs';
import JobItemStructure, { getJobItemData } from 'structures/jobItem';
import { type IList } from 'types/common';
import { type NormalObj } from 'types/index';
import { isInApp } from 'utils/help';

const { jobsApi, favoriteApi } = api;

export type TJobSortType = 'recommend' | 'new';
interface IJobSortType {
  text: string;
  sort: TJobSortType;
  active: boolean;
  onClick: () => void;
}

export type TJobApplyStatus =
  | 'all'
  | 'pending'
  | 'contacting'
  | 'scheduled'
  | 'registered'
  | 'adopted'
  | 'rejected'
  | 'declined';
interface IJobApplyStatus {
  text: string;
  value: TJobApplyStatus;
  active: boolean;
  onClick: () => void;
}

const useJobs = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigation = useCustomNavigate();

  const collected = useStillness({
    mounted: () => {},
    unmounted: () => {},
    collect: (contract) => {
      return {
        isStillness: contract.isStillness(),
        stillnessId: contract.getStillnessId(),
      };
    },
  });

  useEffect(() => {
    if (loginUser.isLogin() && !collected.isStillness) {
      setTimeout(() => {
        const jobId = sessionStorage.getItem('wow_app_job_id');
        jobId && updateJob(+jobId);
        sessionStorage.removeItem('wow_app_job_id');
      }, 20);
    }
  }, [collected.isStillness]);

  const [
    workLocationList,
    occupationList,
    salaryGiveList,
    employmentStatusList,
    visaList,
  ] = useGetSearchFilterData();

  const [initialized, setInitialized] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [totalCount, setTotalCount] = useState(0);
  const [jobs, setJobs] = useState<JobItemStructure[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const [sortTypes, setSortTypes] = useState<IJobSortType[]>([
    {
      text: 'jobSortRecommendText',
      sort: 'recommend',
      active: true,
      onClick: () => {
        setSortTypeActive('recommend');
      },
    },
    {
      text: 'jobSortNewestText',
      sort: 'new',
      active: false,
      onClick: () => {
        setSortTypeActive('new');
      },
    },
  ]);
  const setSortTypeActive = (type: TJobSortType) => {
    setSortTypes(
      sortTypes.map((sortType) => {
        sortType.active = sortType.sort === type;
        return sortType;
      }),
    );
  };

  const [applyStatusList, setApplyStatusList] = useState<IJobApplyStatus[]>([
    {
      text: 'job.applyStatusAll',
      value: 'all',
      active: true,
      onClick: () => {
        setApplyStatusActive('all');
      },
    },
    {
      text: 'jobApplyStatus.applyStatusPending',
      value: 'pending',
      active: false,
      onClick: () => {
        setApplyStatusActive('pending');
      },
    },
    {
      text: 'jobApplyStatus.applyStatusContacting',
      value: 'contacting',
      active: false,
      onClick: () => {
        setApplyStatusActive('contacting');
      },
    },
    {
      text: 'jobApplyStatus.applyStatusScheduled',
      value: 'scheduled',
      active: false,
      onClick: () => {
        setApplyStatusActive('scheduled');
      },
    },
    {
      text: 'jobApplyStatus.applyStatusRegistered',
      value: 'registered',
      active: false,
      onClick: () => {
        setApplyStatusActive('registered');
      },
    },
    {
      text: 'jobApplyStatus.applyStatusAdopted',
      value: 'adopted',
      active: false,
      onClick: () => {
        setApplyStatusActive('adopted');
      },
    },
    {
      text: 'job.applyStatusRejected',
      value: 'rejected',
      active: false,
      onClick: () => {
        setApplyStatusActive('rejected');
      },
    },
    {
      text: 'job.applyStatusDeclined',
      value: 'declined',
      active: false,
      onClick: () => {
        setApplyStatusActive('declined');
      },
    },
  ]);
  const setApplyStatusActive = (status: TJobApplyStatus) => {
    setApplyStatusList(
      applyStatusList.map((applyStatus) => {
        applyStatus.active = applyStatus.value === status;
        return applyStatus;
      }),
    );
  };

  const resetJobs = useCallback((clearJobs: boolean = true) => {
    setInitialized(false);
    setPage(1);
    setPageSize(10);
    clearJobs && setJobs([]);
    setHasMore(true);
  }, []);

  const updateJob = async (jobId: number) => {
    const job = await getJob(jobId);
    if (!job) return;

    setJobs(() => {
      return jobs.map((j) => {
        if (+j.id === +jobId) return job;
        else return j;
      });
    });
  };

  useEffect(() => {
    resetJobs();
  }, [location.search, sortTypes, applyStatusList]);

  const locationParams = useMemo(() => {
    return location.search
      .replace('?', '')
      .split('&')
      .filter((filter) => !!filter)
      .map((filter) => filter.split('='))
      .map((filter) => {
        filter[1] = decodeURIComponent(filter[1]);
        return filter;
      })
      .filter((filter) => !!filter[1]);
  }, [location.search]);

  const searchParams = useMemo(() => {
    const searchFilters = locationParams.reduce<NormalObj>(
      (accumulator, filter) => {
        const keyMap = {
          keyword: 'keyword',
          workLocation: 'prefectureId',
          occupation: 'professionId',
          salaryGive: 'salaryTypeId',
          employmentStatus: 'employmentStatusId',
          visa: 'visaId',
        };
        if (Object.keys(keyMap).includes(filter[0]))
          accumulator[keyMap[filter[0] as keyof typeof keyMap]] = filter[1];
        return accumulator;
      },
      {},
    );
    const sortType =
      sortTypes.find((sortType) => sortType.active)?.sort || 'new';
    const applyStatus =
      applyStatusList.find((applyStatus) => applyStatus.active)?.value || 'all';
    return {
      page,
      pageSize,
      order: sortType,
      status: applyStatus === 'all' ? undefined : applyStatus,
      ...searchFilters,
    };
  }, [page, pageSize, sortTypes, applyStatusList, location.search]);

  const jobSearchFilterListMap = useMemo(() => {
    return {
      workLocation: workLocationList,
      occupation: occupationList,
      salaryGive: salaryGiveList,
      employmentStatus: employmentStatusList,
      visa: visaList,
    };
  }, [
    workLocationList,
    occupationList,
    salaryGiveList,
    employmentStatusList,
    visaList,
  ]);
  const jobSearchFilters: Array<(IList & { key: string }) | undefined> =
    useMemo(() => {
      return locationParams
        .map(([key, value]) => {
          console.log(key, value);
          let findedItem = (
            jobSearchFilterListMap[
              key as keyof typeof jobSearchFilterListMap
            ] || []
          ).find((dataItem) => +dataItem.value === +value);
          if (!findedItem && key === 'keyword')
            findedItem = {
              label: value,
              value,
            };
          if (findedItem) {
            findedItem.label.length > 10 &&
              (findedItem.label = `${findedItem.label.slice(0, 10)}...`);
          }
          return findedItem ? { ...findedItem, key } : undefined;
        })
        .filter((dataItem) => !!dataItem);
    }, [jobSearchFilterListMap, locationParams]);
  const removeJobSearchFilter = (searchFilterKey?: string) => {
    if (!searchFilterKey) return;

    window.sessionStorage.setItem('wow-app-can-clear-search-filters', '1');
    navigation(
      {
        pathname: location.pathname,
        search: location.search
          .split('&')
          .filter((item) => !!item)
          .map((item) => {
            if (item.includes(searchFilterKey)) return `${searchFilterKey}=`;
            else return item;
          })
          .join('&'),
      },
      {
        replace: true,
      },
    );
  };

  const getJobs = async (
    args?: {
      targetPage?: number;
      targetPageSize?: number;
      isFavorite?: boolean;
      isApplied?: boolean;
      isValid?: boolean;
    },
    replace?: boolean,
  ) => {
    const { targetPage, targetPageSize, isFavorite, isApplied, isValid } =
      args || {};
    const jobsSearchParams = {
      ...searchParams,
      page: targetPage || searchParams.page,
      pageSize: targetPageSize || searchParams.pageSize,
      isApplied,
      isFavorite,
      isValid: isValid || undefined,
    };
    const result = await jobsApi.getJobs(jobsSearchParams);
    setInitialized(true);
    const { code, message = t('errorTips.systemError'), data } = result || {};
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({
        content: message,
      });
      setHasMore(false);
      return;
    }
    const jobsData = data.workList || [];
    setTotalCount(data.total);
    setJobs([
      ...(replace ? [] : jobs),
      ...jobsData.map((job) => new JobItemStructure(getJobItemData(job))),
    ]);
    setHasMore(jobsData.length >= (targetPageSize || pageSize));
    setPageSize(() => 10);
    setPage(() => {
      return targetPageSize
        ? Math.ceil((jobsData || []).length / pageSize) + 1
        : page + 1;
    });
  };

  const getJob = async (jobId: number) => {
    const result = await jobsApi.getJobInfo({
      id: jobId,
    });
    const { code, message, data } = result || {};
    if (+code !== 200 || !data) {
      window.wowApp.toast.show({ content: message });
      return;
    }
    return new JobItemStructure(getJobItemData(data as IResponseJobItem));
  };

  const jobCollect = (jobId?: number) => {
    if (!loginUser.isLogin()) {
      if (!isInApp()) navigation('/login');
      else window.wowApp.uwnc.callNative('NaGoToLogin', {}, () => {});
      return;
    }
    if (!jobId) return;
    favoriteApi.saveFavorite({ workId: jobId }).then((result) => {
      const { code, message = t('errorTips.systemError') } = result || {};
      if (+code !== 200) {
        window.wowApp.toast.show({
          content: message,
        });
        return;
      }
      setJobs(
        jobs.map((job) => {
          if (+job.id !== +jobId) return job;
          else {
            job.collected = true;
            return job;
          }
        }),
      );
    });
  };

  const jobRemoveCollect = (params: {
    jobId?: number;
    removeJobFromList?: boolean;
  }) => {
    const { jobId, removeJobFromList } = params;
    if (!loginUser.isLogin()) {
      if (!isInApp()) navigation('/login');
      else window.wowApp.uwnc.callNative('NaGoToLogin', {}, () => {});
      return;
    }
    if (!jobId) return;
    favoriteApi.deleteFavorite({ workId: jobId }).then((result) => {
      const { code, message = t('errorTips.systemError') } = result || {};
      if (+code !== 200) {
        window.wowApp.toast.show({
          content: message,
        });
        return;
      }
      setJobs(() => {
        let _jobs = jobs.map((job) => {
          if (+job.id !== +jobId) return job;
          else {
            job.collected = false;
            return job;
          }
        });
        if (removeJobFromList)
          _jobs = _jobs.filter((job) => +job.id !== +jobId);
        return _jobs;
      });
    });
  };

  return [
    {
      initialized,
      page,
      pageSize,
      totalCount,
      jobs,
      hasMore,
      sortTypes,
      applyStatusList,
      jobSearchFilters,
    },
    {
      resetJobs,
      updateJob,
      removeJobSearchFilter,
      getJobs,
      jobCollect,
      jobRemoveCollect,
    },
  ] as const;
};
export default useJobs;
