export class RA {
  static SCHEMA = 'wowapp://user?ra=';

  // RaGoToUrl
  static goToUrl(url: string) {
    window.location.href =
      RA.SCHEMA +
      JSON.stringify({
        commandName: 'RaGoToUrl',
        params: JSON.stringify({ url }),
      });
  }

  // RaGoToCredential
  static goToCredential() {
    window.location.href =
      RA.SCHEMA +
      JSON.stringify({
        commandName: 'RaGoToCredential',
      });
  }
}
