import { Form } from 'antd-mobile';
import classNames from 'classnames';
import Button from 'components/basic/Button';
import Input from 'components/basic/Input';
import {
  type ILoginButton,
  type ILoginInput,
  type ILoginType,
  useLoginConfirmStep,
  useLoginInputStep,
  useLoginNormalData,
  useLoginResetPasswordData,
} from 'hooks/login';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const getErrorTipElement = (children: React.ReactElement | string) => {
  return <div className="text-orange-dark">{children}</div>;
};

interface ILoginInputStep {
  type: ILoginType;
}
const _LoginInputStep: React.FC<ILoginInputStep> = ({ type }) => {
  const { t } = useTranslation();

  const [{ form, tip, inputTarget, buttons }] = useLoginInputStep(type);

  return (
    <div className="login-input-step border-radius-10 bg-white flex-column p-7-x">
      <div className="tip text-black-dark font-md font-weight-700 line-height-28 text-wrap">
        {t(`loginPage.${tip}`)}
      </div>
      <Form form={form}>
        <Form.Item
          name={inputTarget.name}
          rules={[
            {
              required: inputTarget.required,
              pattern: inputTarget.pattern,
              message: getErrorTipElement(
                t(`errorTips.${inputTarget.tips?.generalErrorTip ?? ''}`),
              ),
            },
          ]}
        >
          <Input
            className="input-target mt-7-x"
            inputType={inputTarget.inputType}
            placeholder={t(`loginPage.${inputTarget.placeholder ?? ''}`)}
          />
        </Form.Item>
      </Form>
      <div className="button-group mt-7-x">
        {buttons.map((button, index) => {
          return (
            <Button
              key={button.key}
              className={classNames('font-weight-700', {
                'mt-5-x': index >= 1,
              })}
              background={button.background}
              loading={button.loading}
              onClick={() => {
                button.onClick();
              }}
            >
              {t(`loginPage.${button.text}`)}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
export const PhoneNumberLoginInput = <_LoginInputStep type="phoneNumber" />;
export const EmailLoginInput = <_LoginInputStep type="email" />;

interface ILoginConfirmStep {
  type: ILoginType;
}
const _LoginConfirmStep: React.FC<ILoginConfirmStep> = ({ type }) => {
  const { t } = useTranslation();

  const [{ form, tip, confirmCode, buttons }] = useLoginConfirmStep(type);

  return (
    <div className="login-confirm-step border-radius-10 bg-white flex-column p-7-x">
      <div className="tip text-black-dark font-md font-weight-700 line-height-28">
        {t(`loginPage.${tip}`)}
      </div>
      <Form form={form}>
        <Form.Item
          name={confirmCode.name}
          rules={[
            {
              required: true,
              pattern: confirmCode.pattern,
              message: getErrorTipElement(
                t(`errorTips.${confirmCode.tips?.generalErrorTip ?? ''}`),
              ),
            },
          ]}
        >
          <Input
            className="confirm-code mt-7-x"
            inputType={confirmCode.inputType}
            placeholder={t(`loginPage.${confirmCode.placeholder}`)}
          />
        </Form.Item>
      </Form>
      <div className="button-group mt-7-x">
        {buttons.map((button, index) => {
          return (
            <Button
              key={button.key}
              className={classNames('font-weight-700', {
                'mt-5-x': index >= 1,
              })}
              background={button.background}
              fill={button.fill}
              loading={button.loading}
              onClick={() => {
                button.onClick();
              }}
            >
              {t(`loginPage.${button.text}`)}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
export const PhoneNumberLoginConfirm = <_LoginConfirmStep type="phoneNumber" />;
export const EmailLoginConfirm = <_LoginConfirmStep type="email" />;

export const LoginNormalPage: React.FC = () => {
  const { t } = useTranslation();

  const { search } = window.location;
  const [
    { form, countDown, inputs, loginTip, passwordForgot, buttons, methods },
  ] = useLoginNormalData();

  const getInputElement = (input: ILoginInput, index: number) => {
    return (
      <Form.Item
        key={input.name}
        className={classNames({ 'mt-7-x': index >= 1 })}
        name={input.name}
        label={
          <div className="text-black-dark font-lg font-weight-700 line-height-28">
            {t(`loginPage.${input.label ?? ''}`)}
          </div>
        }
        rules={[
          {
            required: input.required,
            pattern: input.pattern,
            message: getErrorTipElement(
              t(`errorTips.${input.tips?.generalErrorTip}`),
            ),
          },
        ]}
      >
        <Input
          inputType={input.inputType}
          type={input.type}
          placeholder={t(`loginPage.${input.placeholder ?? ''}`)}
          onChange={input.onChange}
        />
      </Form.Item>
    );
  };

  const getButtonElement = (
    button: ILoginButton,
    isCountDownButton?: boolean,
    className?: string,
  ) => {
    return (
      <Button
        key={button.key}
        className={classNames(
          'font-weight-700 mt-5-x',
          { countdown: isCountDownButton && countDown },
          className,
        )}
        background={button.background}
        fill={button.fill}
        loading={button.loading}
        disabled={!!(isCountDownButton && countDown)}
        onClick={() => {
          button.onClick();
        }}
      >
        <div>
          <span>{t(`loginPage.${button.text}`)}</span>
          {isCountDownButton && countDown ? (
            <span className="counting text-orange font-sm font-weight-400 ml-8">
              {countDown}s
            </span>
          ) : (
            <></>
          )}
        </div>
      </Button>
    );
  };

  return (
    <div className="login-normal-page flex-column flex-align-center p-5-x">
      <div className="login-info-container mt-15-x">
        <Form form={form}>
          {inputs.map((input, index) => {
            if (!Array.isArray(input)) {
              return getInputElement(input, index);
            } else {
              return (
                <div
                  className={classNames('input-array login', {
                    'mt-7-x': index >= 1,
                  })}
                  key={index}
                >
                  {input.map((subInput, i) => {
                    if (Object.keys(subInput).includes('key'))
                      return getButtonElement(
                        subInput as ILoginButton,
                        true,
                        'mt-8-x',
                      );
                    else return getInputElement(subInput as ILoginInput, i);
                  })}
                </div>
              );
            }
          })}
          {loginTip.show && (
            <div
              className="login-tip font-sm line-height-20 text-center mt-7-x"
              dangerouslySetInnerHTML={{
                __html: t(`loginPage.normalLoginTip`, {
                  language: search.match(/language=([^&]*)/)?.[1] || '',
                }),
              }}
            ></div>
          )}
          {passwordForgot ? (
            <div
              className="password-forgot text-orange-dark font-sm line-height-20 text-center cursor-pointer mt-5-x"
              onClick={() => {
                passwordForgot.onClick();
              }}
            >
              {t(`loginPage.${passwordForgot.text}`)}
            </div>
          ) : (
            <></>
          )}
          {buttons.map((button) => {
            return getButtonElement(button);
          })}
          <button
            id="login-phoneNumber-send-code-button"
            style={{ width: 0, height: 0, opacity: 0, display: 'none' }}
          ></button>
        </Form>
        <div className="or mt-12-x">
          <span className="flex-center">or</span>
        </div>
        <div className="login-methods flex-justify-center mt-10-x mb-5-x">
          {methods.map((method, index) => {
            return (
              <div
                key={method.key}
                className={classNames('flex cursor-pointer translate-y-n-s', {
                  'ml-4-x': index >= 1,
                })}
                onClick={() => {
                  method.onClick();
                }}
              >
                <method.icon />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const LoginResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  const [{ form, inputs, buttons }] = useLoginResetPasswordData();

  return (
    <div className="login-reset-password-page flex-column flex-align-center p-5-x">
      <div className="reset-password-container mt-15-x">
        <Form form={form}>
          {inputs.map((input, index) => {
            return (
              <Form.Item
                key={input.name}
                className={classNames({ 'mt-7-x': index >= 1 })}
                name={input.name}
                label={
                  <div className="text-black-dark font-lg font-weight-700">
                    {t(`loginPage.${input.label ?? ''}`)}
                  </div>
                }
                rules={[
                  () => {
                    return {
                      required: input.required,
                      pattern: input.pattern,
                      message: getErrorTipElement(
                        t(`errorTips.${input.tips?.generalErrorTip ?? ''}`),
                      ),
                    };
                  },
                  () => {
                    return {
                      validator: input.validator,
                    };
                  },
                ]}
              >
                <Input
                  inputType={input.inputType}
                  type={input.type}
                  placeholder={t(`loginPage.${input.placeholder ?? ''}`)}
                  onChange={input.onChange}
                />
              </Form.Item>
            );
          })}
          {buttons.map((button) => {
            return (
              <Button
                key={button.key}
                className={classNames('font-weight-700 mt-5-x')}
                background={button.background}
                loading={button.loading}
                onClick={() => {
                  button.onClick();
                }}
              >
                {t(`loginPage.${button.text}`)}
              </Button>
            );
          })}
        </Form>
      </div>
    </div>
  );
};
