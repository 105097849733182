import { type AnyObj, type NormalObj } from 'types/index';

export const isEmptyObject = (obj: AnyObj) => {
  return !!Object.keys(obj).length;
};

export const objectAssignment = <T = AnyObj>(originObj: T, targetObj: T) => {
  for (const key in targetObj) {
    originObj[key] = targetObj[key];
  }
  return { ...originObj };
};

// transform first letter to upper/lower case
export const firstLetterTransform = (
  word: string,
  type: 'upper' | 'lower' = 'upper',
) => {
  if (!word) return '';
  return `${word[0][type === 'upper' ? 'toLocaleUpperCase' : 'toLowerCase']()}${
    word.slice(1) ?? ''
  }`;
};

// transform camel case to kebab case
export const camelCaseToKebabCase = (word: string) => {
  if (!word) return '';
  const matchList = word.match(/([A-Z]{1}[a-z]*)/g) ?? [];
  matchList.forEach((matchItem) => {
    word = word.replace(
      matchItem,
      `-${firstLetterTransform(matchItem, 'lower')}`,
    );
  });
  return word;
};

// find the nearest scroll parent node
export const findScrollParent = (element: HTMLElement): HTMLElement => {
  const scrollOverflowValues = ['auto', 'scroll'];
  const parentElement = element.parentElement;
  if (!parentElement) return document.body;
  const overflowValue = window.getComputedStyle(parentElement).overflow;
  const overflowXValue = window.getComputedStyle(parentElement).overflowX;
  const overflowYValue = window.getComputedStyle(parentElement).overflowY;
  if (
    overflowValue?.includes('auto') ||
    overflowValue?.includes('scroll') ||
    scrollOverflowValues.includes(overflowXValue) ||
    scrollOverflowValues.includes(overflowYValue)
  )
    return parentElement;
  else return findScrollParent(parentElement);
};

export const resetObjectKeys = (
  obj: NormalObj,
  resetKeys: NormalObj<string>,
) => {
  for (const key in resetKeys) {
    const value = obj[key];
    delete obj[key];
    obj[resetKeys[key]] = value;
  }
  return obj;
};

// is wrapObj contains subObj
export const isSubObject = (subOjb: NormalObj, wrapObj: NormalObj) => {
  const result = Object.keys(subOjb).find(
    (key) =>
      !(isEmpty(subOjb[key]) && isEmpty(wrapObj[key])) &&
      subOjb[key] !== wrapObj[key],
  );
  return !result;
};

export const getDocumentHidden = () => {
  const prefixes = ['webkit', 'moz', 'ms', 'o'];
  for (let i = 0; i < prefixes.length; i++) {
    if (`${prefixes[i]}Hidden` in document) {
      return `${prefixes[i]}Hidden` as keyof Document;
    }
  }
  return 'hidden' as keyof Document;
};

export const isEmpty = (value: any) => {
  return !value && value !== 0;
};

export const replaceCurrentURLAndReflush = (url?: string) => {
  if (!url) return;
  window.history.replaceState(null, '', url);
  window.location.reload();
};

export const addPrefixZero = (value: number, targetLength: number) => {
  if (targetLength < 1) return `${value}`;

  const baseValue = Math.pow(10, targetLength - 1);
  if (value >= baseValue) return `${value}`;
  else
    return `${'0'.repeat(`${baseValue}`.length - `${value}`.length)}${value}`;
};

export const isSearchParamEmpty = (searchParam?: string) => {
  if (!searchParam) return;

  const {
    location: { search },
  } = window;
  return (
    search.includes(`${searchParam}=`) &&
    !search.match(new RegExp(`${searchParam}=[^&]+`))
  );
};

export const emptySearchParamRemove = () => {
  const {
    location: { search },
  } = window;
  return search
    .replace(/^\?/, '')
    .split('&')
    .map((searchParamString) => searchParamString.split('='))
    .filter(
      ([searchParamKey, searchParamValue]) =>
        !isSearchParamEmpty(searchParamKey),
    )
    .map((searchParamList) => searchParamList.join('='))
    .join('&');
};

export const renameObjectKeys = (
  targetObject: NormalObj,
  renameKeyMap: NormalObj<string>,
) => {
  const renameKeys = Object.keys(renameKeyMap);
  const newObject: NormalObj = {};
  Object.keys(targetObject).forEach((key) => {
    newObject[renameKeys.includes(key) ? renameKeyMap[key] : key] =
      targetObject[key];
  });
  return newObject;
};
