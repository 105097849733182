import { Form } from 'antd-mobile';
import classNames from 'classnames';
import Button from 'components/basic/Button';
import Input from 'components/basic/Input';
import { type ILogonButton, type ILogonInput, useLogonData } from 'hooks/logon';
import { getErrorTipElement } from 'pages/Login';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LogonPage: React.FC = () => {
  const { t } = useTranslation();

  const [{ form, countDown, tip, inputs, buttons }] = useLogonData();

  const getInputElement = (input: ILogonInput, index: number) => {
    return (
      <Form.Item
        key={input.name}
        className={classNames({ 'mt-7-x': index >= 1 })}
        name={input.name}
        label={
          <div className="text-black-dark font-lg font-weight-700 line-height-28">
            {t(`logonPage.${input.label ?? ''}`)}
          </div>
        }
        dependencies={input.dependencies}
        rules={[
          () => {
            return {
              required: input.required,
              pattern: input.pattern,
              message: getErrorTipElement(
                t(`errorTips.${input.tips?.generalErrorTip}`),
              ),
            };
          },
          () => {
            return {
              validator: input.validator,
            };
          },
        ]}
      >
        <Input
          inputType={input.inputType}
          type={input.type}
          placeholder={t(`logonPage.${input.placeholder ?? ''}`)}
          onChange={input.onChange}
        />
      </Form.Item>
    );
  };

  const getButtonElement = (
    button: ILogonButton,
    isCountDownButton?: boolean,
  ) => {
    return (
      <Button
        key={button.key}
        className={classNames(
          { countdown: isCountDownButton && countDown },
          'font-weight-700 mt-5-x',
        )}
        background={button.background}
        fill={button.fill}
        loading={button.loading}
        disabled={!!(isCountDownButton && countDown)}
        onClick={() => {
          button.onClick();
        }}
      >
        <div>
          <span>{t(`logonPage.${button.text}`)}</span>
          {isCountDownButton && countDown ? (
            <span className="counting text-orange font-sm font-weight-400 ml-8">
              {countDown}s
            </span>
          ) : (
            <></>
          )}
        </div>
      </Button>
    );
  };

  return (
    <div className="logon-page flex-column flex-align-center p-5-x">
      <div className="logon-container">
        <div
          className="text-black-dark font-lg font-weight-700 line-height-26 mb-7-x"
          dangerouslySetInnerHTML={{
            __html: t(`logonPage.${tip}`),
          }}
        ></div>
        <Form form={form}>
          {inputs.map((input, index) => {
            if (!Array.isArray(input)) {
              return getInputElement(input, index);
            } else {
              return (
                <div
                  className={classNames('input-array logon', {
                    'mt-7-x': index >= 1,
                  })}
                  key={index}
                >
                  {input.map((subInput, i) => {
                    if (Object.keys(subInput).includes('key'))
                      return getButtonElement(subInput as ILogonButton, true);
                    else return getInputElement(subInput as ILogonInput, i);
                  })}
                </div>
              );
            }
          })}
          {buttons.map((button) => {
            return getButtonElement(button);
          })}
        </Form>
      </div>
    </div>
  );
};
export default LogonPage;
