import React from 'react';

export interface FormLabelProps {
  required?: boolean;
  label?: React.ReactNode;
}

const FormLabel: React.FC<FormLabelProps> = ({ required = true, label }) => {
  return (
    <div className="form-label-basic">
      {required && <span className="text-orange-dark mr-2">＊</span>}
      {label}
    </div>
  );
};
export default FormLabel;
