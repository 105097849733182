import IconSearch from 'assets/images/icon-search.svg';
import classNames from 'classnames';
import Button from 'components/basic/Button';
import Input from 'components/basic/Input';
import Select from 'components/basic/Select';
import { useMediaQuery } from 'hooks/hooks';
import { useSearchFilter } from 'hooks/searchFilter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { camelCaseToKebabCase } from 'utils/utils';

export interface SearchFilterProps {
  className?: string;
}
const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const isMobile = useMediaQuery();

  const [
    {
      keyword,
      workLocation,
      occupation,
      salaryGive,
      employmentStatus,
      visa,
      clearButton,
      searchButton,
    },
    { clear, submitFormData },
  ] = useSearchFilter();

  return (
    <div
      className={classNames(
        'search-filter-compound',
        { 'is-mobile mt-7-x': isMobile },
        {
          'is-pc bg-orange flex-justify-center pt-7-x pb-7-x': !isMobile,
        },
        className,
      )}
    >
      <div
        className={classNames(
          'search-filter-content',
          { 'flex-column ': isMobile },
          {
            'flex-justify-center flex-wrap': !isMobile,
          },
        )}
      >
        <Input
          inputType="form"
          name={keyword.name}
          value={keyword.value}
          placeholder={t(`searchFilter.${keyword.placeholder || ''}`)}
          afterAddon={
            <div className="bg-white cursor-pointer" onClick={keyword.onSearch}>
              <IconSearch />
            </div>
          }
          onChange={keyword.onChange}
        ></Input>
        {[workLocation, occupation, salaryGive, employmentStatus, visa].map(
          (filter) => {
            return (
              <div
                key={filter.key}
                className={classNames(
                  'mt-5-x',
                  camelCaseToKebabCase(filter.key),
                  // {
                  //   'flex-1': ['workLocation', 'occupation'].includes(filter.key),
                  // },
                )}
              >
                {isMobile && (
                  <div className="text-white font-md font-weight-700 mb-8">
                    {t(`searchFilter.${filter.text || ''}`)}
                  </div>
                )}
                <Select
                  name={filter.name}
                  value={filter.value}
                  defaultValue={filter.defaultValue}
                  options={filter.options}
                  placeholder={t(`searchFilter.${filter.placeholder || ''}`)}
                  allowClear={filter.allowClear}
                  onSelect={filter.onSelect}
                ></Select>
              </div>
            );
          },
        )}
        <div
          className={classNames(
            'button-group flex-justify-center',
            {
              'mt-12-x': isMobile,
            },
            {
              'mt-7-x': !isMobile,
            },
          )}
        >
          <Button
            background={clearButton.background}
            size={clearButton.size}
            shape={clearButton.shape}
            onClick={() => {
              clear?.();
              clearButton.onClick();
            }}
          >
            {t(`searchFilter.${clearButton.text}`)}
          </Button>
          <Button
            className="ml-5-x"
            background={searchButton.background}
            size={searchButton.size}
            shape={searchButton.shape}
            onClick={() => {
              submitFormData?.();
              searchButton.onClick();
            }}
          >
            {t(`searchFilter.${searchButton.text}`)}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const _SearchFilter = <SearchFilter />;
export default SearchFilter;
