import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-basic wd-full bg-cyan flex-center pr-9-x pl-9-x overflow-hidden">
      <div className="text-white font-xs text-center">
        {t('public.copyRight')}
      </div>
    </div>
  );
};

export default Footer;
