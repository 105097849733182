import { Button, Checkbox, Form, Input, TextArea } from 'antd-mobile';
import FormLabel from 'components/basic/FormLabel';
import RichTextRender from 'components/basic/RichTextRender';
import React from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/index';
import { emailRegExp, phoneNumberRegExp } from 'utils/help';

const { inquiresApi } = api;

const Inquiry: React.FC = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const result = await inquiresApi.userInquires(values);
    const { code, message = t('errorTips.systemError') } = result || {};
    if (+code === 200) {
      window.wowApp.toast.show({ content: message });
      form.resetFields();
    }
  };

  return (
    <div>
      <div className="inquiry-form page-content-width">
        <Form
          name="form"
          form={form}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFinish={onFinish}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              className="inquiry-submit-btn"
              size="large"
            >
              {t('button.submit')}
            </Button>
          }
        >
          <Form.Item
            name="lastName"
            className="mb-5-x mt-8-x"
            label={<FormLabel required label={t('other.lastName')} />}
            rules={[{ required: true, message: t('other.namePlaceholder') }]}
          >
            <Input placeholder={t('other.name')} className="inquiry-input" />
          </Form.Item>

          <Form.Item
            name="firstName"
            className="mb-5-x"
            label={<FormLabel label={t('other.firstName')} />}
            rules={[{ required: true, message: t('other.namePlaceholder') }]}
          >
            <Input placeholder={t('other.name')} className="inquiry-input" />
          </Form.Item>

          <Form.Item
            name="email"
            className="mb-5-x"
            label={<FormLabel label={t('other.emailAddress')} />}
            rules={[
              {
                pattern: emailRegExp,
                warningOnly: true,
                message: t('login.emailValidPlaceholder'),
              },
              { required: true, message: t('login.emailPlaceholder') },
            ]}
          >
            <Input placeholder="abc@gmail.com" className="inquiry-input" />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            className="mb-5-x"
            label={<FormLabel required={false} label={t('other.phone')} />}
            rules={[
              {
                pattern: phoneNumberRegExp,
                warningOnly: true,
                message: t('errorTips.phoneNumberGeneralErrorTip'),
              },
            ]}
          >
            <Input
              placeholder="08012345678"
              className="inquiry-input"
              type="number"
            />
          </Form.Item>

          <Form.Item
            name="message"
            className="inquiry-form-text mb-5-x"
            label={<FormLabel label={t('other.message')} />}
            rules={[{ required: true, message: t('other.contactUs') }]}
          >
            <TextArea
              placeholder={t('other.contactUs')}
              className="inquiry-text-area"
            />
          </Form.Item>

          <Form.Item
            name="agree"
            className="inquiry-form-agree mb-5-x"
            rules={[
              {
                required: true,
                message: t('other.agreePlaceholder'),
              },
            ]}
          >
            <Checkbox className="inquiry-check-box">
              <div
                className="agree-content cursor-pointer flex-align-center"
                onClick={() => {
                  const { origin, search } = window.location;
                  window.location.href = `${origin}/policy?language=${
                    search.match(/language=([^&]*)/)?.[1] || ''
                  }`;
                  // window.open(`${origin}/policy`);
                }}
              >
                <RichTextRender
                  className="font-sm"
                  content={t('other.agree')}
                />
              </div>
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Inquiry;
