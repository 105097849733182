import {
  Empty as AntEmpty,
  type EmptyProps as AntdEmptyProps,
} from 'antd-mobile';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface EmptyProps extends AntdEmptyProps {}
const Empty: React.FC<EmptyProps> = ({
  className,
  description = 'No data',
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('empty-basic pt-15-x pb-15-x wd-full', className)}
    >
      <AntEmpty description={t('public.empty')} {...props} />
    </div>
  );
};
export default Empty;
