import axiosInstance from 'services/fetch';
import { type IHttpResponse } from 'types/common';

export interface applyWorkId {
  workId: number;
}

export default {
  applyWork: (args: applyWorkId) => {
    return axiosInstance<any, IHttpResponse, applyWorkId>({
      url: '/works/applyWork',
      method: 'post',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  cancelAppliedWork: (args: applyWorkId) => {
    return axiosInstance<any, IHttpResponse, applyWorkId>({
      url: '/works/deleteAppliedWork',
      method: 'delete',
      data: args,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
