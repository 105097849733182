import Accordion, { type IAccordionData } from 'components/basic/Accordion';
import FaqItem from 'components/basic/FaqItem';
import useFaqs from 'hooks/faq';
import { useCustomNavigate } from 'hooks/hooks';
import React, { useEffect, useMemo } from 'react';

const FaqList: React.FC = () => {
  const [{ faqs }, { getFaqs }] = useFaqs();
  const navigate = useCustomNavigate();

  useEffect(() => {
    getFaqs();
  }, []);

  const accordionData = useMemo(() => {
    const faqList: IAccordionData[] = [];
    // eslint-disable-next-line array-callback-return
    faqs.map((item) => {
      // eslint-disable-next-line array-callback-return
      item.info.map((items) => {
        const title = items.category_name;
        const content = items.faqs?.map((question) => {
          return (
            <FaqItem
              key={question.id}
              question={question.question}
              onClick={() => {
                navigate(`/faq/${question.id ?? 0}`);
              }}
            />
          );
        });
        const obj = {
          title: title ?? '',
          context: content ?? '',
        };
        faqList.push(obj);
      });
    });
    return faqList || [];
  }, [faqs]);

  return (
    <div className="content-center flex-1">
      <div className="faq-content page-content-width">
        <div className="p-5-x">
          <Accordion accordionData={accordionData} />
        </div>
      </div>
    </div>
  );
};

export default FaqList;
