import { RightOutline } from 'antd-mobile-icons';
import React from 'react';

interface FaqItemProps {
  icon?: string;
  question?: string;
  onClick?: () => void;
}

const FaqItem: React.FC<FaqItemProps> = ({ icon, question, onClick }) => {
  return (
    <div className="faq-item-compound cursor-pointer" onClick={onClick}>
      <div className="faq-item-list overflow-ellipsis">
        <span className="faq-item-q">Q</span>
        <span className="faq-item-title">{question}</span>
      </div>
      <div className="faq-item-arrow">
        <RightOutline />
      </div>
    </div>
  );
};

export default FaqItem;
