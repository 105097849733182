import { GlobalOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import Language from 'components/compound/Language';
import { useGlobal, useLanguage, useMediaQuery } from 'hooks/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { type IOption } from '../basic/Select';

interface TranslationProps {
  flag?: number; // 0: pc 1: phone
}

const Translation: React.FC<TranslationProps> = ({ flag = 1 }) => {
  const { i18n, t } = useTranslation();
  const isMobile = useMediaQuery();
  const { changeLanguage } = useLanguage();
  const option2: IOption[] = [
    {
      label: t('public.ja'),
      value: 'ja',
    },
    {
      label: t('public.en'),
      value: 'en',
    },
    {
      label: t('public.zh_ch'),
      value: 'zh_ch',
    },
    // {
    //   label: t('public.zh_hk'),
    //   value: 'zh_hk',
    // },
    {
      label: t('public.vi_vn'),
      value: 'vi_vn',
    },
    {
      label: t('public.ne_np'),
      value: 'ne_np',
    },
    {
      label: t('public.id_id'),
      value: 'id_id',
    },
  ];

  const [active, setActive] = useState(i18n.language);
  const [option, setOption] = useState<IOption[]>(option2);

  useEffect(() => {
    const opt: IOption[] = option2;
    setOption([...opt]);
  }, [i18n.language, active]);

  const ctx = useGlobal();

  const _changeLanguage = (option: IOption) => {
    if (!option?.value) return;
    changeLanguage(option.value);
    setActive(option.value);
    ctx.setPopup?.({
      show: false,
    });
  };

  return (
    <>
      {flag === 0 && (
        <div id="translation-basic" className="translation-basic">
          <div className="translation-icon flex-center">
            <GlobalOutline className="translation-select-icon" />
            {!isMobile ? (
              <span className="ml-8">{t('public.language')}</span>
            ) : (
              <></>
            )}
          </div>
          <Select
            className={classNames('translation-select font-sm', {
              'wd-full': isMobile,
            })}
            defaultValue={active}
            placeholder={t(`public.${active}`)}
            options={option}
            getOptionsContainer={() =>
              document.querySelector('#translation-basic')
            }
            selectOptionsHeight="266px"
            onSelect={(option) => {
              option && _changeLanguage(option);
            }}
          ></Select>
        </div>
      )}
      {flag === 1 && (
        <div
          className="translation-basic-sp cursor-pointer"
          onClick={() => {
            ctx.setPopup?.({
              show: true,
              title: t('public.language'),
              renderComponent: <Language />,
            });
          }}
        >
          <div className="text-center">
            <GlobalOutline className="translation-select-icon-sp" />
          </div>
          <div className="translation-language-choice">
            {t('public.language')}
          </div>
        </div>
      )}
    </>
  );
};

export default Translation;
