import { type InternalAxiosRequestConfig } from 'axios';
import { type NormalObj } from 'types/index';

type TCacheValue = NormalObj & { wowAppTimestampKey: number };
class MemoryCache {
  static readonly LOCAL_CACHE = 'wow-app-local-cache';
  static readonly TIMESTAMP_KEY = 'wowAppTimestampKey';

  private readonly _cacheExpiration = +(
    process.env.REACT_APP_API_DATA_CACHE_DURATION || 60 * 1000
  );

  private readonly _cacheSet = new Map<string, TCacheValue>();

  constructor() {
    this.cacheInitialize();
  }

  cacheInitialize() {
    const localCache = window.localStorage.getItem(MemoryCache.LOCAL_CACHE);
    (JSON.parse(localCache || '[]') as Array<[string, TCacheValue]>).forEach(
      ([key, value]) => {
        this.set(key, value);
      },
    );
  }

  cacheLocalize() {
    window.localStorage.setItem(
      MemoryCache.LOCAL_CACHE,
      JSON.stringify(Array.from(this._cacheSet.entries()) || []),
    );
  }

  getSerializationKey = (config: InternalAxiosRequestConfig<any>) => {
    const { method, url, params, data } = config;
    return [method, url, JSON.stringify(params), JSON.stringify(data)].join(
      '&',
    );
  };

  cached(key: string) {
    const value = this._cacheSet.get(key);
    if (!value) return false;
    return (
      +value[MemoryCache.TIMESTAMP_KEY] + this._cacheExpiration > Date.now()
    );
  }

  get(key: string) {
    if (this.cached(key)) return this._cacheSet.get(key);
    this.delete(key);
    this.cacheLocalize();
  }

  set(key: string, value: NormalObj) {
    this._cacheSet.set(key, {
      [MemoryCache.TIMESTAMP_KEY]: Date.now(),
      ...value,
    });
    this.cacheLocalize();
  }

  delete(key: string) {
    this._cacheSet.delete(key);
  }
}
const memoryCache = new MemoryCache();
export default memoryCache;
